// SearchComponent.js

import React from 'react';
import { addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, query, where, onSnapshot, deleteDoc, 
  limit, orderBy, startAfter, startAt, arrayUnion } 
from '@firebase/firestore';
import { db } from '../firebase'; // <-- Ensure db is imported

import './SearchComponent.css';
import deleteIcon from '../icons/deleteIcon.png';
import searchIcon from '../icons/searchIcon.png';
import algoliasearch from 'algoliasearch';

// Initialize Algolia client once, outside the component
const client = algoliasearch('7FYUJ9ESUV', '09735055cb98e3e8b3e653dc344bee7a');
const index = client.initIndex('products');

const SearchComponent = (props) => {

  const search = async () => {
    const trimmedTerm = props.inputTerm.trim();

    if (trimmedTerm === '') {
      // Optionally handle empty search input
      props.setSearchTerm('');
      props.setProductData([]);
      props.setNoProducts(false);
      props.setNoStores(false);
      props.setProductNotFound(false);
      return;
    }

    props.setSearchTerm(trimmedTerm);
    props.setIsLoading(true);
    props.setActiveCategory("All");
    props.setNoProducts(false);
    props.setNoStores(false);

    try {
      const searchOptions = {
        hitsPerPage: 20,
        queryType: 'prefixAll', // Enables prefix matching
        filters: 'status:published AND productSold:false', 
        // Optional: Specify which attributes to search
        // searchableAttributes: ['title', 'description', 'keywords'],
        // Optional: Add typo tolerance settings
        // typoTolerance: 'min',
      };

      const { hits } = await index.search(trimmedTerm, searchOptions);
      props.setProductData(hits);

      if (hits.length < 20) { // Adjust based on hitsPerPage
        props.setHasMore(false);
      } else {
        props.setHasMore(true);
      }

      if (hits.length === 0) {
        props.setProductNotFound(true);
        console.log("Product not found");
      } else {
        props.setProductNotFound(false);
      }

      // Save search term to Firebase if user is logged in
      if (props.userID) {
        try {
          const userDocRef = doc(db, "users", props.userID);
          await updateDoc(userDocRef, {
            searchHistory: arrayUnion(trimmedTerm),
          });
          console.log(`Search term "${trimmedTerm}" added to searchHistory for user ${props.userID}`);
        } catch (error) {
          console.error("Error updating searchHistory:", error);
        }
      }

    } catch (error) {
      console.error("Error during search:", error);
      // Optionally, set an error state to inform the user
    } finally {
      props.setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    props.setHasSearchText(value !== '');
    props.setInputTerm(value);
  };

  let searchComponentClassName;

  if (props.parentComponent === 'market') {
    searchComponentClassName = 'marketSearchDiv';
  } else if (props.parentComponent === 'scout') {
    searchComponentClassName = 'scoutSearchDiv';
  }

  return (
    <div 
      style={{ 
        width: (props.parentComponent === 'scout') ? 'calc(100% - 50px)' : '100%'
      }}
    >
      <div className={searchComponentClassName}>
        <input 
          type="text" 
          placeholder="search" 
          className="marketSearch" 
          value={props.inputTerm} 
          onChange={handleSearchChange} 
          onKeyDown={e => {
            if (e.key === 'Enter') {
              search();
            }
          }}
        />
        <img 
          className={`marketSearchIcon${props.hasSearchText ? 'Active' : ''}`} 
          src={searchIcon} 
          alt="Search Icon"
          onClick={search}
          style={{ cursor: 'pointer' }} // Ensure cursor indicates clickability
        />
        {props.hasSearchText && 
          <img 
            src={deleteIcon} 
            alt="Clear Text" 
            className="marketClearTextIcon" 
            onClick={props.clearSearch}
            style={{ cursor: 'pointer' }} // Ensure cursor indicates clickability
          />
        }
      </div>
    </div>
  );
}

export default SearchComponent;
