import React, { useState } from 'react';
import './PaymentPending.css';
import blankImage from '../../images/blankImage.png';
import dropdownIcon from '../../icons/popupIcon.png';
import checkIcon from '../../icons/checkIcon.png';
import deleteIcon from '../../icons/deleteIcon.png';
// 1) Import your info icon here:
import infoIcon from '../../icons/infoIcon.png';

function PaymentPending({ payments }) {
  // 2) Create a piece of state to control whether the info box is shown
  const [showWithdrawInfo, setShowWithdrawInfo] = useState(false);

  return (
    <div className="pendingPaymentsSection">
      {/* A container for the header and info icon in the top-right corner */}
      <div className="pendingPaymentsHeaderContainer">
        <h2 className="pendingPaymentsSectionHeader">Pending Payments</h2>

        {/* 3) The info icon that toggles the additional info */}
        <img
          src={infoIcon}
          className="paymentInfoIcon"
          alt="More Info"
          onClick={() => setShowWithdrawInfo(!showWithdrawInfo)}
        />

        {/* 4) Conditionally render the info box */}
        {showWithdrawInfo && (
          <div className="paymentInfoDiv">
            <p className="paymentInfoText">
              You will be able to withdraw funds from your account
              once the product(s) has been sent and the customer has indicated that 
              they have received the product(s).
            </p>
            <div 
              className="paymentInfoBtn" 
              onClick={() => setShowWithdrawInfo(false)}
            >
              got it
            </div>
          </div>
        )}
      </div>

      {payments.length === 0 ? (
        <p>No pending payments.</p>
      ) : (
        payments.map(payment => (
          <SinglePayment key={payment.id} payment={payment} />
        ))
      )}
    </div>
  );
}

/**
 * SinglePayment
 * Renders a single payment as a visual item with product images and a dropdown for more details.
 */
function SinglePayment({ payment }) {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const toggleDetails = () => setIsDetailsVisible(!isDetailsVisible);

  const { products = [] } = payment;
  const productCount = products.length;
  const firstProduct = products[0] || {};

  const allImages = products.flatMap(prod => prod.imageThumbnails || []);
  const displayedImages = allImages.slice(0, 3);

  let paymentDate = 'N/A';
  if (payment.paymentDate?.seconds) {
    paymentDate = new Date(payment.paymentDate.seconds * 1000).toLocaleDateString();
  }

  return (
    <div className="singlePaymentContainer">
      <div 
        className="singlePaymentTopRow" 
        onClick={toggleDetails} 
        style={{ cursor: 'pointer' }}
      >
        <div className="singlePaymentImagesWrapper">
          {displayedImages.map((imgUrl, idx) => (
            <img 
              key={idx}
              className="singlePaymentImage" 
              src={imgUrl || blankImage} 
              alt="Product" 
            />
          ))}
          {productCount > 3 && (
            <div className="extraProductsOverlay">
              +{productCount - 3} More
            </div>
          )}
        </div>

        {productCount === 1 ? (
          <div className="singlePaymentHeading">
            {firstProduct.productName || 'Untitled Product'}
          </div>
        ) : (
          <div className="singlePaymentHeading">
            Bundle of {productCount} products
          </div>
        )}
        <img
          src={dropdownIcon}
          alt="Toggle Details"
          className={`dropdownIcon ${isDetailsVisible ? 'rotate' : ''}`}
          onClick={toggleDetails}
        />
      </div>

      {isDetailsVisible && (
        <div className="singlePaymentDetails">
          <div className="sentReceivedRow">
            <div 
              className={`statusBox ${payment.productSent ? 'statusTrue' : 'statusFalse'}`}
            >
              product sent
              <img 
                src={payment.productSent ? checkIcon : deleteIcon} 
                alt={payment.productSent ? 'Sent' : 'Not Sent'} 
                className="statusIcon"
              />
            </div>
            <div 
              className={`statusBox ${payment.productReceived ? 'statusTrue' : 'statusFalse'}`}
            >
              product received
              <img 
                src={payment.productReceived ? checkIcon : deleteIcon} 
                alt={payment.productReceived ? 'Received' : 'Not Received'} 
                className="statusIcon"
              />
            </div>
          </div>

          {typeof payment.sellerFees !== 'undefined' && (
            <div className="singlePaymentDetailRow">
              <strong>Seller Fees:</strong> R{parseFloat(payment.sellerFees).toFixed(2)}
            </div>
          )}

          <div className="singlePaymentDetailRow">
            <strong>Payment Date:</strong> {paymentDate}
          </div>

          <div className="singlePaymentDetailRow">
            <strong>Payment ID:</strong> {payment.id}
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentPending;
