// src/components/ColorPicker.jsx
import React, { useState, useRef, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import dropdownIcon from '../../icons/popupIcon.png';
import tinycolor from 'tinycolor2';
import './ColorPicker.css'; // Import corresponding CSS

const ColorPicker = ({ useCase, color, onChangeComplete, objectColor, pickerRef, onDone, onCancel, onOpen, displayPicker, setDisplayPicker, setColorPickerOpen }) => {
  const [hasChanged, setHasChanged] = useState(false); // Track if color has changed

  useEffect(() => {
    if (!objectColor) {
      console.error('objectColor is null or undefined');
    }
  }, [objectColor]);

  const handleTogglePicker = () => {
    setDisplayPicker(!displayPicker);
    if (useCase !== 'websiteColorChange') {
      setColorPickerOpen(!displayPicker)
    }
    if (!displayPicker && onOpen) {
      onOpen(); // Notify parent that picker is opened
    }
  };

  const handleChangeComplete = (color) => {
    onChangeComplete(color);
    if (color.hex !== objectColor) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  };

  const chooseSpecificColourContainerColor = tinycolor(objectColor).isLight()
    ? tinycolor(objectColor).darken(10).toString()
    : tinycolor(objectColor).lighten(40).toString();

  // Determine the text color based on the lightness of the container color
  const textColor = tinycolor(chooseSpecificColourContainerColor).isLight()
    ? 'rgba(0, 0, 0, 0.7)'
    : 'rgba(255, 255, 255, 0.7)';

  const handleClose = () => {
    setDisplayPicker(false);
    if (useCase !== 'websiteColorChange') {
      setColorPickerOpen(false)
    }
    setHasChanged(false); // Reset hasChanged when closing
    if (onCancel) {
      onCancel(); // Invoke cancel handler
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // If pickerRef is defined and the click is inside picker, do nothing
      if (pickerRef.current && pickerRef.current.contains(event.target)) {
        return;
      }
      // If click is outside the popup, trigger onClickOutside
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (displayPicker) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside); // For touch devices
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [displayPicker, pickerRef]);

  const colorPickerCustomStyles = {
    default: {
      picker: {
        borderRadius: '12px', // Rounded edges for the overall picker
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '10px',
        width: 'calc(100% - 20px)',
        fontFamily: '"Poppins"'
      },
      saturation: {
        borderRadius: '12px', 
        fontFamily: '"Poppins"'
      },
      hue: {
        height: '20px',
        borderRadius: '5px',  
      },
      alpha: {
        display: 'none',
      },
      controls: {
        fontFamily: '"Poppins", sans-serif',
      },
      sliders: {
        display: 'flex',
        flexDirection: 'column',
      },
      input: {
        borderRadius: '8px',
        padding: '4px 8px',
        border: '1px solid #ccc',
        fontFamily: '"Poppins"',
      },
      label: {
        fontFamily: '"Poppins"',
      },
      clear: {
        // Example: if you have a clear button
        fontFamily: '"Poppins", sans-serif',
        borderRadius: '8px',
        padding: '6px 12px',
      },
    }
  };

  const iridescenceColor = tinycolor(objectColor).setAlpha(0.2).toRgbString();
  const lightenedIridescenceColors = [
    tinycolor(objectColor).lighten(10).setAlpha(0.1).toRgbString(),
    tinycolor(objectColor).lighten(20).setAlpha(0.2).toRgbString(),
    tinycolor(objectColor).lighten(30).setAlpha(0.4).toRgbString(),
  ];

  return (
    <div 
      className="chooseSpecificColourContainer" 
      ref={pickerRef}
    >
      <div 
        className="colorPickerTrigger" 
        style={{ backgroundColor: chooseSpecificColourContainerColor }}
        onClick={handleTogglePicker}
      >
        <div className="colorPickerTriggerSection1" >
          <div
            className="selectedColorSquare"
            style={{ backgroundColor: objectColor }}
          />
          <h1 
            className="selectedColorHeading"
            style={{ color: textColor }}
          >
            select specific colour
          </h1>
        </div>
        <img src={dropdownIcon} alt="Dropdown Arrow" className="colorPickerDropdownArrow" />
      </div>
      {displayPicker && (
        <div 
          className="chromePickerWrapper" 
          onClick={(e) => e.stopPropagation()}
          style={{
            background: `
              radial-gradient(circle at 20% 30%, ${lightenedIridescenceColors[0]}, transparent),
              radial-gradient(circle at 70% 80%, ${lightenedIridescenceColors[1]}, transparent),
              radial-gradient(circle at 50% 50%, ${lightenedIridescenceColors[2]}, transparent),
              linear-gradient(rgba(235, 236, 236, 0.5), ${iridescenceColor}, rgba(208, 215, 215))
            `,
            backdropFilter: 'blur(50px)'
          }}        
        >
          <ChromePicker
            color={color}
            onChangeComplete={handleChangeComplete} // Use the new handler
            styles={colorPickerCustomStyles}
          />
          <div className="chromePickerHeader">
            <button 
              className="colorDoneBtn" 
              onClick={() => {
                onDone(objectColor)
                setDisplayPicker(false)
              }} // Pass the selected color
              disabled={!hasChanged} // Disable if no change
              style={{
                opacity: hasChanged ? 1 : 0.5,
                cursor: hasChanged ? 'pointer' : 'not-allowed'
              }}
            >
              done
            </button>
            <button className="colorCancelBtn" onClick={handleClose}>cancel</button>
          </div>
        </div>
      )}
    </div> 
  );
};

export default ColorPicker;
