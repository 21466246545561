// src/admin/components/AdminOrderHistory.jsx
import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import path as necessary
import './AdminOrderHistory.css'; // Ensure you have appropriate styles

const AdminOrderHistory = () => {
  const [orderHistories, setOrderHistories] = useState({});
  const [expandedOrders, setExpandedOrders] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchOrderHistories = async () => {
      try {
        const adminOrderHistoryCol = collection(db, 'adminOrderHistory');
        const snapshot = await getDocs(adminOrderHistoryCol);
        const groupedData = {};

        snapshot.forEach(doc => {
          const data = doc.data();
          const storeID = data.storeID;
          const storeName = data.storeName || 'Unnamed Store'; // Assuming storeName exists
          
          if (!groupedData[storeID]) {
            groupedData[storeID] = {
              storeName,
              orders: [],
            };
          }

          // Ensure adminOrderHistory is an array
          if (Array.isArray(data.adminOrderHistory)) {
            groupedData[storeID].orders.push(...data.adminOrderHistory);
          } else {
            console.warn(`adminOrderHistory is not an array for storeID: ${storeID}`);
          }
        });

        setOrderHistories(groupedData);
      } catch (error) {
        console.error('Error fetching admin order histories:', error);
        setError('Failed to fetch order histories. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrderHistories();
  }, []);

  const toggleOrderDetails = (storeID, orderIndex) => {
    const key = `${storeID}-${orderIndex}`;
    setExpandedOrders(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const renderOrderDetails = (order) => {
    // Safeguard against undefined properties
    const saleDate = order.saleDate ? new Date(order.saleDate.seconds * 1000).toLocaleString() : 'N/A';
    const cartTotal = typeof order.cartTotal === 'number' ? `R${order.cartTotal.toFixed(2)}` : 'R0.00';
    const buyerInfo = order.buyerInfo || {};
    const shippingTotal = typeof order.cartShippingTotal === 'number' ? `R${order.cartShippingTotal.toFixed(2)}` : 'R0.00';
    const products = Array.isArray(order.products) ? order.products : [];

    return (
      <div className="order-details">
        <h4>Buyer Information:</h4>
        <p>Name: {buyerInfo.firstName || 'N/A'}</p>
        <p>Email: {buyerInfo.email || 'N/A'}</p>
        <h4>Products:</h4>
        <ul className="products-list">
          {products.length === 0 ? (
            <li>No products in this order.</li>
          ) : (
            products.map(product => {
              const productName = product.productName || 'Unnamed Product';
              const sellerPrice = typeof product.seller_price === 'number' ? `R${product.seller_price.toFixed(2)}` : 'R0.00';
              const quantity = product.quantity || 1;
              const description = product.description || 'N/A';

              return (
                <li key={product.productID || Math.random()} className="product-item">
                  <div className="product-summary">
                    <span>{productName} (x{quantity})</span>
                    <span>Price: {sellerPrice}</span>
                    <button
                      className="view-details-button"
                      onClick={() => alert(`Product Details:\n\nName: ${productName}\nPrice: ${sellerPrice}\nQuantity: ${quantity}\nDescription: ${description}`)}
                    >
                      View Details
                    </button>
                  </div>
                </li>
              );
            })
          )}
        </ul>
        <h4>Shipping:</h4>
        <p>Address: {buyerInfo.address || 'N/A'}</p>
        <p>Shipping Total: {shippingTotal}</p>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="admin-order-history">
        <h2>Admin Order History</h2>
        <p>Loading order histories...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-order-history">
        <h2>Admin Order History</h2>
        <p className="error-message">{error}</p>
      </div>
    );
  }

  return (
    <div className="admin-order-history">
      <h2>Admin Order History</h2>
      {Object.keys(orderHistories).length === 0 ? (
        <p>No order histories available.</p>
      ) : (
        Object.entries(orderHistories).map(([storeID, storeData]) => (
          <div key={storeID} className="store-section">
            <h3>{storeData.storeName} (ID: {storeID})</h3>
            {storeData.orders.length === 0 ? (
              <p>No orders for this store.</p>
            ) : (
              <ul className="orders-list">
                {storeData.orders.map((order, index) => {
                  const key = `${storeID}-${index}`;
                  const saleDate = order.saleDate ? new Date(order.saleDate.seconds * 1000).toLocaleString() : 'N/A';
                  const cartTotal = typeof order.cartTotal === 'number' ? `R${order.cartTotal.toFixed(2)}` : 'R0.00';

                  return (
                    <li key={key} className="order-item">
                      <div className="order-summary" onClick={() => toggleOrderDetails(storeID, index)}>
                        <span>Order Date: {saleDate}</span>
                        <span>Total: {cartTotal}</span>
                        <button className="toggle-button">
                          {expandedOrders[key] ? 'Hide Details' : 'Show Details'}
                        </button>
                      </div>
                      {expandedOrders[key] && renderOrderDetails(order)}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default AdminOrderHistory;
