import React, { useState, useEffect } from "react";

const generateCoordinates = (width, height) => {
  const coordinates = {
    x: Math.random() * width,
    y: Math.random() * height,
  };
  return coordinates;
};

const generateSize = (minSize, maxSize) => {
  const size = Math.random() * (maxSize - minSize) + minSize;
  return size;
};

const generateColor = () => {
  const colors = ["orange", "black", "white", "brown", "yellow", "gray", "#FF2700"];
  const color = colors[Math.floor(Math.random() * colors.length)];
  
  let opacity;
  if (Math.random() < 0.05) { 
    opacity = 0.6; // 5% chance for the opacity to be 0.8
  } else {
    opacity = Math.random() * 0.3 + 0.1; // 95% chance for the opacity to be between 0.2 and 0.7
  }

  return { color, opacity };
};


const generateDustOrScratch = (width, height, maxDustOrScratch, minSize, maxSize, minLength, maxLength, isDust = true) => {
  const newDustOrScratch = [];
  for (let i = 0; i < maxDustOrScratch; i++) {
    const coordinates = generateCoordinates(width, height);
    const size = generateSize(minSize, maxSize);
    const color = generateColor();
    const opacity = Math.random() * 0.7 + 0.3;
    const curved = Math.random() > 0.05;
    const controlPoint = curved && !isDust ? generateCoordinates(width, height) : null;

    const dustOrScratch = {
      id: `${isDust ? 'dust' : 'scratch'}-${i}`,
      x: coordinates.x,
      y: coordinates.y,
      size: isDust ? size : generateSize(minSize, maxSize),
      length: isDust ? null : generateSize(minLength, maxLength),
      color: color.color,
      opacity: color.opacity,
      angle: isDust ? null : Math.random() * 360,
      curved,
      controlPoint,
    };
    newDustOrScratch.push(dustOrScratch);
  }
  return newDustOrScratch;
};

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width, height };
};

const DustEffect = () => {
  const { width: dustEffectWidth, height: dustEffectHeight } = useViewport();
  const [effects, setEffects] = useState({dust: [], scratches: []});
  const maxDust = 12;
  const maxScratches = 12;

  const minDustSize = 1;
  const maxDustSize = 6;
  const minScratchWidth = 2; 
  const maxScratchWidth = 5; 
  const minScratchLength = 50; 
  const maxScratchLength = 150; 

  const updateEffect = (effectType, maxEffect, minSize, maxSize, isDust) => {
    return prevEffect => {
      const effectCopy = [...prevEffect];
      const randomIndex = Math.floor(Math.random() * maxEffect);
      const newEffect = generateDustOrScratch(dustEffectWidth, dustEffectHeight, 1, minSize, maxSize, isDust)[0];
      newEffect.id = `${isDust ? 'dust' : 'scratch'}-${randomIndex}`; // Update the id with the randomIndex
      effectCopy[randomIndex] = newEffect;
      return effectCopy;
    }
  }
  
  const [dustVisibility, setDustVisibility] = useState(true);

  useEffect(() => {
    setEffects({
      dust: generateDustOrScratch(dustEffectWidth, dustEffectHeight, maxDust, minDustSize, maxDustSize, null, null, true),
      scratches: generateDustOrScratch(dustEffectWidth, dustEffectHeight, maxScratches, minScratchWidth, maxScratchWidth, minScratchLength, maxScratchLength, false),
    });
    
    const interval = setInterval(() => {
      setDustVisibility(prev => !prev);
      setEffects(prevEffects => {
        return {
          ...prevEffects,
          dust: dustVisibility ? generateDustOrScratch(dustEffectWidth, dustEffectHeight, 0, minDustSize, maxDustSize, null, null, true)
                               : generateDustOrScratch(dustEffectWidth, dustEffectHeight, maxDust, minDustSize, maxDustSize, null, null, true),
          scratches: generateDustOrScratch(dustEffectWidth, dustEffectHeight, maxScratches, minScratchWidth, maxScratchWidth, minScratchLength, maxScratchLength, false)
        };
      });
    }, 84)

    return () => clearInterval(interval);
  }, [dustEffectWidth, dustEffectHeight, dustVisibility]);

  return (
    <div
      className="dustEffectDiv"
      style={{
        overflow: 'hidden',
        width: dustEffectWidth,
        height: dustEffectHeight,
        left: 0,
        top: 0,
        pointerEvents: 'none',
      }}
    >
      {effects.dust.map(d => (
        <div
          key={d.id}
          style={{
            position: "absolute",
            top: d.y,
            left: d.x,
            width: d.size,
            height: d.size,
            backgroundColor: d.color,
            borderRadius: "50%",
            opacity: d.opacity,
          }}
        />
      ))}
      {effects.scratches.filter(s => s.curved).map(s => (
      <svg 
        key={s.id} 
        style={{
          position: "absolute",
          top: s.y,
          left: s.x,
        }}
      >
        <path
          d={`M0,0 Q${s.controlPoint.x},${s.controlPoint.y} ${s.length},0`}
          stroke={s.color}
          fill="transparent"
          strokeWidth={s.size / 10}
          opacity={s.opacity}
          transform={`rotate(${s.angle})`}
        />
      </svg>
    ))}
    {effects.scratches.filter(s => !s.curved).map(s => (
      <div
        key={s.id}
        style={{
          position: "fixed",
          top: s.y,
          left: s.x,
          width: s.width,
          height: s.length,
          backgroundColor: s.color,
          opacity: s.opacity,
          transform: `rotate(${s.angle}deg)`,
        }}
      />
    ))}

    </div>
  );
};

export default DustEffect;
