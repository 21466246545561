// ScoutCard.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, arrayUnion, arrayRemove, query, 
  where, onSnapshot, increment 
} from '@firebase/firestore';
import { auth, db, storage } from '../firebase';
import { animate, motion, useMotionValue } from "framer-motion";
import LongPress from '../components/LongPress';
import './ScoutCard.css';

import { extractColors } from '../components/ColorExtractor';
import ColorThief from 'colorthief';
import heartIcon from '../icons/heartIcon.png';
import ethicalSticker from '../images/ethicalSticker1.png';
import redHeartIcon from '../icons/redHeartIcon.png';
import bundlingIcon from '../icons/bundlingIcon.png';
import shareIcon from '../icons/shareIcon.png';
import { useNavigate } from 'react-router-dom';
import ImageGrain from './ImageGrain';
import ScoutCardImage from './ScoutCardImage'; // Import the new component

const ScoutCard = React.forwardRef((props, ref) => {
  const cardRef = useRef();
  const heartRef = useRef();

  const [userID, setUserID] = useState();
  const [data, setData] = useState();
  const [isOwnStore, setIsOwnStore] = useState(false); // New state
  const [isAdminStore, setIsAdminStore] = useState(false); // New state for admin
  const [liked, setLiked] = useState(false);
  const xVal = useMotionValue(0);
  const navigate = useNavigate();

  const [storeNameClicked, setStoreNameClicked] = useState(false)
  const [dominantColor, setDominantColor] = useState();
  const [lightColor, setLightColor] = useState();
  const [isBundling, setBundling] = useState(false);
  const [buyBtnText, setBuyBtnText] = useState('buy');
  const [isInCart, setIsInCart] = useState(false);
  const [showEthicalCheck, setShowEthicalCheck] = useState();
  const [ethicalCheck, setEthicalCheck] = useState();
  const [ethicalCheckText, setEthicalCheckText] = useState();

  const [hasWhiteColor, setHasWhiteColor] = useState(false);

  // Prevent default drag behavior
  const preventDrag = (event) => {
    event.preventDefault();
  };

  const handleBuyClick = () => {
    props.setAlertMessage('Buying is disabled for admin stores.');
  };

  // Optionally, you can add a state to control the button's disabled status
  const [isBuyingDisabled, setIsBuyingDisabled] = useState(true); // Set to true to disable

  const storeNameClick = (e) => {
    e.stopPropagation(); // Prevent the event from bubbling up
    e.preventDefault();  // Prevent any default behavior
    setStoreNameClicked(true);
  }

  // Effect to handle bundling prop
  useEffect(() => {
    if (props.bundling) {
      if (props.bundling === 'yes') {
        setBundling(true);
      } else { 
        setBundling(false);
      }
    }
  }, [props.bundling]);

  // Effect to handle image loading and color analysis
  useEffect(() => {
    const img = new Image();
    const colorThief = new ColorThief();

    img.crossOrigin = "Anonymous"; // This line handles issues with CORS
    img.src = props.image[0]; 

    img.onload = () => {
      if (img.complete && img.naturalHeight !== 0) {
        try {
          const colorPalette = colorThief.getPalette(img, 5);
          const colorPaletteRGBStrings = colorPalette.map(color => `rgb(${color[0]}, ${color[1]}, ${color[2]})`);

          // Convert RGB colors to HSL and find the color with the highest lightness value
          let lightestColor = colorPalette[0];
          let maxLightness = rgbToHsl(...colorPalette[0])[2];

          for (let i = 1; i < colorPalette.length; i++) {
            const currentLightness = rgbToHsl(...colorPalette[i])[2];
            if (currentLightness > maxLightness) {
              maxLightness = currentLightness;
              lightestColor = colorPalette[i];
            }
          }

          // Check the contrast ratio with black color. If it's less than 4.5, increase the lightness
          let black = [0, 0, 0];
          while (contrastRatio(lightestColor, black) < 4.5) {
            console.log(lightestColor);
            let hsl = rgbToHsl(...lightestColor);
            console.log(hsl);
            hsl[2] += 0.05; // Increase lightness by 5%
            if (hsl[2] > 1) hsl[2] = 1; // Ensure lightness doesn't exceed 100%
            console.log(hsl);
            lightestColor = hslToRgb(...hsl);
            console.log(lightestColor);
          }

          // Set the lightColor and dominantColor
          setLightColor(`rgb(${lightestColor[0]}, ${lightestColor[1]}, ${lightestColor[2]})`);
          setDominantColor(colorPaletteRGBStrings);
          props.onDominantColor(colorPaletteRGBStrings, props.index);

          // Determine if any of the dominant colors are light (white or very light)
          const isLightColor = (color) => {
            const [r, g, b] = color;
            const hsl = rgbToHsl(r, g, b);
            return hsl[2] > 0.8; // Threshold for lightness can be adjusted
          };

          const hasLightColor = colorPalette.some(color => isLightColor(color));
          setHasWhiteColor(hasLightColor);
          console.log("Has white or very light color:", hasLightColor);

        } catch (err) {
          console.error('Error getting color palette', err);
        }
      } else {
        console.log('Image not fully loaded');
      }
    };

    img.onerror = () => {
      console.log('Error loading image');  // log if there's an error loading the image
    };
  }, [props.image]);

  // Shared color utility functions
  const contrastRatio = (color1, color2) => {
    const luminance = (rgb) => {
      rgb = rgb.map((v) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
      });
      return rgb[0] * 0.2126 + rgb[1] * 0.7152 + rgb[2] * 0.0722;
    };
    
    const lum1 = luminance(color1);
    const lum2 = luminance(color2);
    const [light, dark] = lum1 > lum2 ? [lum1, lum2] : [lum2, lum1];
    return (light + 0.05) / (dark + 0.05);
  };

  const rgbToHsl = (r, g, b) => {
    r /= 255; g /= 255; b /= 255;
    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        default: h = 0;
      }
      h /= 6;
    }
    return [h, s, l];
  };

  const hslToRgb = (h, s, l) => {
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      }
      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  const handleSwipeLeft = useCallback(() => {
    // Animate the card off-screen to the left
    animate(xVal, -window.innerWidth * 1.5, { duration: 0.5 });

    // Navigate to the store after the animation completes
    setTimeout(() => {
      navigate(`/${props.store}`, { 
        state: { 
          store: props.store, 
          storeID: props.storeID,
          colorArray: props.colorArray,
          visibleCardIndex: props.visibleCardIndex,
          isWideScreen: props.isWideScreen,
          showGradient: props.showGradient,
          animationsActive: props.animationsActive, 
          websiteBackground: props.websiteBackground
        } 
      });
    }, 500);
  }, [animate, xVal, navigate, props.store, props.storeID, props.colorArray, props.visibleCardIndex, props.isWideScreen, props.showGradient, props.animationsActive, props.websiteBackground]);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        // Prevent default behavior if necessary
        event.preventDefault();
        handleSwipeLeft();
      }
    };
  
    // Attach the event listener to the window object
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSwipeLeft])

  // Handler for heart icon click
  const onHeartIconClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!props.isLoggedIn && (props.page !== 'dashboard')) {
      props.showLogin(); 
      return; 
    }

    if (liked) {
      onUncheck();     
    } else {
      addToFavourites();
    }
  };

  const createFavouritesDoc = async (userId) => {
    const favouritesRef = doc(db, 'favourites', userId);
    try {
      await setDoc(favouritesRef, {
        liked_products: [],
        userID: userId,
      });
      console.log('Favourites document created.');
    } catch (error) {
      console.error('Error creating favourites document:', error);
    }
  };
  
  useEffect(() => {
    if (userID) {
      createFavouritesDoc(userID);
    }
  }, [userID]);

  // Handler to unlike a product
  const onUncheck = () => {
    setLiked(false);
  
    const dbRef = doc(db, 'favourites', userID);
    getDoc(doc(db, 'products', props.productID)).then(productSnap => {
      if (productSnap.exists()) {
        const data = productSnap.data();
        getDoc(dbRef).then(docSnap => {
          if (docSnap.exists()) {
            try {
              // Retrieve the current value of the liked_products array
              const likedProducts = docSnap.data().liked_products;
              const newLikedProducts = likedProducts.filter(product => product.productID !== data.productID);

              // Update the liked_products array in the favourites document with the new array
              updateDoc(dbRef, {
                liked_products: newLikedProducts,
              });
            } catch(err) {
              console.log(err);
            }
            console.log("Product unchecked and removed from favourites document");
          }
        });
      }
    });

    // Decrease like 
    const productRef = doc(db, "products", props.productID);
    getDoc(productRef).then(docSnap => {
      const likes = (docSnap.data().likes);
      if(isNaN(likes) || !likes) {
        updateDoc(productRef, { likes: 0 });
      } else if (likes > 0) {
        updateDoc(productRef, { likes: (likes - 1) });
      }
    });
  };

  // Handler to like a product
  const addToFavourites = async () => {
    setLiked(true);
    const productRef = doc(db, 'products', props.productID);

    getDoc(productRef).then(docSnap => {
      const likes = (docSnap.data().likes);
      if(isNaN(likes) || !likes) {
        updateDoc(productRef, { likes: 0 });
      }

      console.log(likes);
      updateDoc(productRef, { likes: (likes + 1) });
    });
    
    const dbRef = doc(db, 'favourites', userID);
    getDoc(doc(db, 'products', props.productID)).then(productSnap => {
      if (productSnap.exists()) {
        const data = productSnap.data();

        getDoc(dbRef).then(docSnap => {
          if (docSnap.exists()) {
            const likedProducts = docSnap.data().liked_products;

            const newData = {
              ...data,
              date_liked: new Date()
            };
            // If there is a favourites document, update the doc
            try {
              updateDoc(dbRef, {
                liked_products: arrayUnion(newData),
                userID: userID,
              });
            } catch(err) {
              console.log(err);
            }
            console.log("Product liked and added to existing favourites document");
  
          } else {
            // If there is not a favourites document, create one
            const newData = {
              ...data,
              date_liked: new Date()
            };

            try {
              setDoc(dbRef, {
                liked_products: [newData],
                userID: userID,
            });
            } catch(err) {
              console.log(err);
            }
            console.log("Product liked and new favourites document created");
          }
        });
      }
    });
  };

  // State and handlers for expanding info
  const [expandInfo, setExpandInfo] = useState(false);
  const [animationToggle, setAnimationToggle] = useState(false); // Renamed to avoid conflict

  // Effect to set user ID from Firebase Auth
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid);
      } else {
        setUserID(null);
      }
    });
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [])

  // Effect to fetch product data and check liked/cart status
  useEffect(() => {
    const fetchProductAndUserData = async () => {
      try {
        const productSnap = await getDoc(doc(db, 'products', props.productID));
        if (productSnap.exists()) {
          const productData = productSnap.data();
          setData(productData);

          // If there's backgroundColors in firebase, use that
          if (productData.backgroundColors) {
            setDominantColor(productData.backgroundColors);
            // Also call the parent's onDominantColor
            props.onDominantColor(productData.backgroundColors, props.index);
          } else {
            // Otherwise, do a fresh extraction
            const firstImage = productData.images?.[0] || props.image?.[0];
            if (firstImage) {
              const newColors = await extractColors(firstImage);
              setDominantColor(newColors);
              props.onDominantColor(newColors, props.index);

              // Save them to Firestore so we don't need to do this again
              await updateDoc(doc(db, 'products', props.productID), {
                backgroundColors: newColors,
              });
            }
          }

          // Ethical checks
          if (productData.ethicalChecks || productData.ethicalChecksText) {
            setShowEthicalCheck(true);
            setEthicalCheck(productData.ethicalChecks);
            setEthicalCheckText(productData.ethicalChecksText);
          }

          // Check store user role
          if (productData.userID) {
            const userSnap = await getDoc(doc(db, 'users', productData.userID));
            if (userSnap.exists()) {
              const userData = userSnap.data();
              if (userData.role === 'admin') {
                setIsAdminStore(true);
              } else {
                setIsAdminStore(false);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching product or user data:', error);
      }

      // Check likes + cart status
      checkIfLiked();
      checkIfInCart();
    };

    fetchProductAndUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.productID, userID]);

  // Set isOwnStore based on userID and data.userID
  useEffect(() => {
    if (userID && data && userID === data.userID) { // Ensure 'userID' is the correct field
      setIsOwnStore(true);
    } else {
      setIsOwnStore(false);
    }
  }, [userID, data]);

  // Long press event handler
  const longPressEvent = LongPress(
    () => {
      console.log('longpress triggered');
      setExpandInfo(prevExpandInfo => {
        const newExpandInfo = !prevExpandInfo;
        props.setInfoDivExpanded(newExpandInfo);
        return newExpandInfo;
      });
      setAnimationToggle(prevAnimate => !prevAnimate); // Toggle animate state
      console.log('Toggle expandInfo to:', !expandInfo);
    },
    () => {
      console.log('click triggered');
      props.setShowTextDivMessage(true);
    },
    {
      delay: 500,
      shouldPreventDefault: true,
    }
  );
  
  // Function to check if the product is already liked
  const checkIfLiked = async () => {
    if (userID && props.productID) {
      const favouritesRef = doc(db, 'favourites', userID);
      try {
        const docSnap = await getDoc(favouritesRef);
        if (docSnap.exists()) {
          const likedProducts = docSnap.data().liked_products;
          const likedProduct = likedProducts.find(product => product.productID === props.productID);
          if (likedProduct) {
            setLiked(true);
          }
        }
      } catch (error) {
        console.error('Error fetching favourites:', error);
        // Optionally, set an error state to inform the user
      }
    }
  };

  // Function to check if the product is already in the cart
  const checkIfInCart = async () => {
    if (userID && props.productID) {
      const cartRef = doc(db, 'cart', userID);

      getDoc(cartRef).then(docSnap => {
        if (docSnap.exists()) {
          const cartProducts = docSnap.data().cart;
          
          if (Array.isArray(cartProducts) && cartProducts.length > 0) {
            const cartProduct = cartProducts.find(product => product.productID === props.productID);
            if (cartProduct) {
              setBuyBtnText('in cart');
            } else {
              console.log("Product not found in cart");
            }
          } else {
            // Handle empty or non-array cartProducts
            console.log("The cart is empty or not formatted as an array");
          }
        } 
      });
    }
  };

  // Function to add product to cart
  const addToCart = async () => {
    if (isOwnStore || isAdminStore) { // Include isAdminStore in the check
      if (isOwnStore) {
        props.setAlertMessage("Sellers can't buy from their own stores.");
      } else if (isAdminStore) {
        props.setAlertMessage("Buying is disabled for admin stores.");
      }
      props.setAlertType('error');
      return; // Prevent further execution
    }

    // Check if the user's country is South Africa
    if (props.country !== 'South Africa') {
      // Use custom alert system if available
      if (props.setAlertMessage && props.setAlertType) {
        props.setAlertMessage('Buying is not available in your country yet');
        props.setAlertType('error');
      } else {
        // Fallback to native alert
        alert('Buying is not available in your country yet');
      }
      return; // Prevent further execution
    }
    // If the user is not logged in, show the login popup and return
    if (!props.isLoggedIn && (props.page !== 'dashboard')) {
      props.showLogin(); 
      return; 
    }
  
    // Check if product is already in cart
    const cartRef = doc(db, 'cart', userID);
    const cartSnap = await getDoc(cartRef);
    if (cartSnap.exists()) {
      const cartProducts = cartSnap.data().cart;

      if (Array.isArray(cartProducts)) {
        const cartProduct = cartProducts.find(product => product.productID === props.data.productID);
        if (cartProduct && (props.page !== 'dashboard')) {
          console.log("already in cart, can't purchase again");
          props.alreadyInCart(true); // Show the "already in cart" message
          return;
        }
      } else {
        console.log("The user has an empty cart or cart is not an array");
      }
    }
    const productRef = doc(db, 'products', props.productID);

    // Ensure the product document has the users_in_cart and users_in_cart_count fields
    // Add the current user to the users_in_cart array and increment the count
    await updateDoc(productRef, {
      usersInCart: arrayUnion(userID),
      usersInCartNum: increment(1)
    })

    // Get product data and cart data
    getDoc(doc(db, 'products', props.productID)).then(docSnap => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        getDoc(doc(db, 'cart', userID)).then(docSnap => {
          if (docSnap.exists()) {
            const cartProducts = docSnap.data().cart;
          
            if (Array.isArray(cartProducts)) {
              const cartProduct = cartProducts.find(product => product.productID === props.data.productID);
              console.log(data.listed_price);
              if (cartProduct && (props.page !== 'dashboard')) {
                console.log("already in cart, can't purchase again");
                props.alreadyInCart(true); // Show the "already in cart" message
                return;
              } else {
                // If product is not already in cart, add it to the cart and 
                try {
                  setBuyBtnText('in cart');
                  updateDoc(doc(db, 'cart', userID), {
                    cart: arrayUnion(data),
                    userID: userID,
                  });
                } catch(err) {
                  console.log(err);
                }
                console.log("Product added to existing cart document");
              }
            } else {
              // When there is a cart field, but no products in the cart
              try {
                setBuyBtnText('in cart');
                updateDoc(doc(db, 'cart', userID), {
                  cart: [data],
                  userID: userID,
                });
              } catch(err) {
                console.log(err);
              }
              console.log("Product added to existing cart document");
            }
          } else {
            // If there is no cart document, create a new one and add the product to it
            try {
              setBuyBtnText('in cart');
              setDoc(doc(db, 'cart', userID), {
                cart: arrayUnion(data),
                cartTotal: data.listed_price,
                userID: userID,
              });
            } catch(err) {
              console.log(err);
            }
            console.log("New cart document created and product added to it");
          }
        });
      }
    });
  };

  // Animation variants
  const imageDivVariants = {
    expanded: { height: "24%"},
    collapsed: { height: "77%", width: '100%'}
  };

  const imageVariants = {
    expanded: { width: "35%"},
    collapsed: { width: '100%' }
  };

  const wideImageDivVariants = {
    expanded: { height: "24%", width: '50%'},
    collapsed: { height: "100%", width: '50%'}
  };

  const wideImageVariants = {
    expanded: { width: "35%"},
    collapsed: { width: "100%"}
  };

  const infoDivVariants = {
    expanded: { height: "calc(76% - 8px)" },
    collapsed: { height: "22%" }
  };

  const wideInfoDivVariants = {
    expanded: { height: "calc(76% - 8px)" },
    collapsed: { height: "99.6%" }
  };

  return (
      <motion.div 
        className={`scoutCard ${isOwnStore ? 'ownStore' : ''}`} // Conditional class
        ref={ref}
        style={{ x: xVal, zIndex: 1000 }}
        key={props.index}
        drag="x"
        dragDirectionLock={true}
        initial={{ opacity: 1, y: 0 }}
        transition={storeNameClicked ? {duration: 1.5} : { duration: 0.2 }}
        dragMomentum={true}
        dragElastic={0}
        onDragEnd={(event, info) => {
          const threshold = 150;
          const xOffset = info.offset.x;
          const yOffset = info.offset.y;
      
          // Only trigger swipe if horizontal movement is greater than vertical movement
          if (Math.abs(xOffset) > Math.abs(yOffset) && xOffset < -threshold) {
              handleSwipeLeft();
          } else {
              // Animate the card back to its original position
              animate(xVal, 0, { type: "spring", stiffness: 50 });
          }
        }}
        
        animate={storeNameClicked ? { opacity: 0, y: 50 } : { opacity: 1, y: 0 }}
        onAnimationComplete={() => {
          if (storeNameClicked) {
            if (props.page !== 'dashboard') {
              props.setTransitionStarted(true)
            }
            console.log("Transition started")

            setTimeout(() => {
              navigate(`/${props.store}`, { 
                state: { 
                  fromScout: true,
                  store: props.store, 
                  storeID: props.storeID,
                  colorArray: props.colorArray,
                  visibleCardIndex: props.visibleCardIndex,
                  isWideScreen: props.isWideScreen,
                  showGradient: props.showGradient,
                  animationsActive: props.animationsActive, 
                  websiteBackground: props.websiteBackground
                } 
              });
              if (props.page !== 'dashboard') {
                props.setTransitionStarted(false)
              }
            }, 3000)
          }
        }}
      >
        <motion.div 
          className='scoutCardContainer'  
          style={{ zIndex: 100 }}
        >
          <ScoutCardImage
            page={props.page}
            imageSrc={props.image[0]}
            isWideScreen={props.isWideScreen}
            expandInfo={expandInfo}
            animate={animationToggle}
            hasWhiteColor={hasWhiteColor}
            onHeartIconClick={onHeartIconClick}
            liked={liked}
            isBundling={isBundling}
            preventDrag={preventDrag}
            productID={props.productID}
            imageVariants={props.isWideScreen ? wideImageVariants : imageVariants}
            wideImageVariants={wideImageVariants}
            imageDivVariants={imageDivVariants}
            wideImageDivVariants={wideImageDivVariants}
            buyBtnText={buyBtnText}
            onBuyButtonClick={addToCart} 
            hideBuyButton={isOwnStore || isAdminStore} // Pass the new prop
            buyDisabled={isBuyingDisabled} 
            price={props.listed_price}
            openGallery={props.openGallery}
            images={props.image}
            imageThumbnail={props.imageThumbnail}
            setAlertMessage={props.setAlertMessage}
            setAlertType={props.setAlertType}
            country={props.country}
          />
          
          <motion.div 
            className={`infoDivWrapper${animationToggle ? (expandInfo ? 'Expanded' : 'Collapsed') : ''}`}
            variants={props.isWideScreen ? wideInfoDivVariants : infoDivVariants}
            initial={false}
            animate={expandInfo ? "expanded" : "collapsed"}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent the event from bubbling up
            }}
          >
            <div 
              className={`infoDiv${animationToggle ? (expandInfo ? 'Expanded' : 'Collapsed') : ''}`} 
              {...(props.isWideScreen ? {} : longPressEvent)} 
            >
              <div 
                className={`textDiv${animationToggle ? (expandInfo ? 'Expanded' : 'Collapsed') : ''}`}
              >
                <h1 className={`productName${animationToggle ? (expandInfo ? ' true' : ' false') : ''}`}>{props.title}</h1> 
                <h2 
                  className={`productStoreName${animationToggle ? (expandInfo ? ' true' : ' false') : ''}`}
                  data-interactive="true"
                  onClick={(e) => {
                    storeNameClick(e)
                  }}
                >
                  {props.store}
                </h2>
                <p className={`productDescription${expandInfo ? 'true' : 'false'}`}>{props.description}</p>
                {/* 
                <div className={`productTags${animate ? (expandInfo ? ' true' : ' false') : ''}`}>
                  <button className="categoryTag">{props.category}</button>
                </div> 
                */}
              </div> 
              {(!expandInfo && (props.page !== 'dashboard')) && (
                <div className={`iconsDivfalse`}>
                  <div 
                    className="scoutIcons" 
                    alt="heart icon" 
                    ref={heartRef} 
                    data-interactive="true"
                    onClick={(e) => onHeartIconClick(e)} 
                    onDragStart={preventDrag}
                  >
                    {liked ? 
                      <img id="redHeartIconfalse" src={redHeartIcon} alt="Liked" onDragStart={preventDrag}/> 
                      : 
                      <img id="heartIconfalse" src={heartIcon} alt="Like" onDragStart={preventDrag}/>
                    }
                  </div>
                  {isBundling && 
                    <img 
                      src={bundlingIcon} 
                      alt="bundling icon" 
                      className="scoutIcons" 
                      id="bundlingIconfalse" 
                      onDragStart={preventDrag}
                    />
                  }
                  <img 
                    src={shareIcon} 
                    alt="share icon" 
                    className="scoutIcons" 
                    id="shareIconfalse" 
                    onDragStart={preventDrag}
                  />
                </div>
              )}
            </div>
          </motion.div>
        </motion.div>
      </motion.div>

  );
});

export default ScoutCard;
