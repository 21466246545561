import React, { useRef, useState, useEffect } from 'react';
import { Link, Outlet } from "react-router-dom";
import './Wallet.css';
import { 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  query, 
  where, 
  updateDoc, 
  addDoc, 
  serverTimestamp, 
  setDoc 
} from 'firebase/firestore';
import { db } from '../../firebase';

import PaymentPending from './PaymentPending';

import MarketGradient from '../../market/MarketGradient';
import backArrow from '../../icons/backArrow.png';
import notificationIcon from '../../icons/notificationIcon.png';
import historyIcon from '../../icons/historyIcon.png';

const Wallet = (props) => {
  const walletFormRef = useRef();
  const [storeID, setStoreID] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [accountHolder, setAccountHolder] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [bankName, setBankName] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  
  // New state variables for payments and wallet
  const [pendingPayments, setPendingPayments] = useState([]);
  const [walletAmount, setWalletAmount] = useState(0);
  const [showWithdrawalPopup, setShowWithdrawalPopup] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isWithdrawDisabled, setIsWithdrawDisabled] = useState(true);
  const [bankingDetails, setBankingDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // New state to track if editing
  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);
  const [savedDominantColors, setSavedDominantColors] = useState([
    'rgb(197, 201, 198)',
    'rgb(91, 106, 125)',
    'rgb(215, 87, 89)',
    'rgb(234, 86, 1)',
    'rgb(114, 126, 132)'
  ]);
  useEffect(() => {
    document.title = 'Wallet | taizte™';
  }, []);

  // Enable or disable the submit button based on input values
  useEffect(() => {
    setIsSubmitDisabled(!accountHolder || !accountNumber || !branchCode || !bankName);
  }, [accountHolder, accountNumber, branchCode, bankName]);

  // Fetch user data and set storeID
  useEffect(() => {
    const fetchUserData = async () => {
      if (!props.userID) return;

      try {
        const userDocRef = doc(db, 'users', props.userID);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          if (userData.storeID) {
            setStoreID(userData.storeID);
            // Fetch banking details from bankingDetails collection
            await fetchBankingDetails(userData.storeID);
          } else {
            console.log('storeID not found in user document.');
          }
        } else {
          console.error('No such user document!');
        }
      } catch (error) {
        console.error('Error fetching user document:', error);
      }
    };

    fetchUserData();
  }, [props.userID]);

  // Fetch banking details from bankingDetails collection
  const fetchBankingDetails = async (storeID) => {
    try {
      const bankingDetailsRef = doc(db, 'bankingDetails', storeID);
      const bankingDetailsSnap = await getDoc(bankingDetailsRef);

      if (bankingDetailsSnap.exists()) {
        const details = bankingDetailsSnap.data();
        setBankingDetails(details);
        setAccountHolder(details.accountHolder || '');
        setAccountNumber(details.accountNumber || '');
        setBranchCode(details.branchCode || '');
        setBankName(details.bankName || '');
        setIsEditing(true); // If banking details exist, set editing to true
      } else {
        setBankingDetails(null);
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error fetching banking details:', error);
    }
  };

  // Fetch payments and calculate wallet amount when storeID changes
  useEffect(() => {
    const fetchPayments = async () => {
      if (!storeID) return;

      try {
        const paymentsRef = collection(db, 'payments');
        const pendingQuery = query(
          paymentsRef, 
          where('storeID', '==', storeID), 
          where('productSent', '==', false)
        );

        const receivedPendingQuery = query(
          paymentsRef, 
          where('storeID', '==', storeID), 
          where('productReceived', '==', false)
        );

        const [pendingSnap, receivedPendingSnap] = await Promise.all([
          getDocs(pendingQuery),
          getDocs(receivedPendingQuery)
        ]);

        const pending = [];
        pendingSnap.forEach(doc => {
          pending.push({ id: doc.id, ...doc.data() });
        });
        receivedPendingSnap.forEach(doc => {
          pending.push({ id: doc.id, ...doc.data() });
        });

        console.log(pending)

        setPendingPayments(pending);

        // Now fetch payments where both productSent and productReceived are true
        const completedQuery = query(
          paymentsRef,
          where('storeID', '==', storeID),
          where('productSent', '==', true),
          where('productReceived', '==', true)
        );

        const completedSnap = await getDocs(completedQuery);

        let totalWallet = 0;
        completedSnap.forEach(doc => {
          const data = doc.data();
          totalWallet += parseFloat(data.sellerFees || 0);
        });

        setWalletAmount(totalWallet);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };

    fetchPayments();
  }, [storeID]);

  // Fetch and log orderHistory when storeID changes
  useEffect(() => {
    const fetchOrderHistory = async () => {
      if (!storeID) return;

      try {
        const orderHistoryDocRef = doc(db, 'orderHistory', storeID);
        const orderHistorySnap = await getDoc(orderHistoryDocRef);

        if (orderHistorySnap.exists()) {
          const orderHistoryData = orderHistorySnap.data();
          console.log('Order History:', orderHistoryData.orderHistory);
        } else {
          console.warn('No orderHistory document found for storeID:', storeID);
        }
      } catch (error) {
        console.error('Error fetching orderHistory:', error);
      }
    };

    fetchOrderHistory();
  }, [storeID]);

  // Handle withdrawal input validation
  useEffect(() => {
    const amount = parseFloat(withdrawAmount);
    if (!isNaN(amount) && amount > 0 && amount <= walletAmount) {
      setIsWithdrawDisabled(false);
    } else {
      setIsWithdrawDisabled(true);
    }
  }, [withdrawAmount, walletAmount]);

  // Save or Update Banking Information
  async function saveWithdrawlInformation(e) {
    e.preventDefault();

    // Ensure props.userID exists before proceeding
    if (!props.userID) return;

    try {
      const userDocRef = doc(db, 'users', props.userID);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();

        // Update the user's banking details in users collection
        await updateDoc(userDocRef, {
          bankingDetails: {
            accountHolder,
            accountNumber,
            branchCode,
            bankName
          }
        });

        // Check if storeID exists, and if so, fetch and update the corresponding store document
        if (userData.storeID) {
          const storeDocRef = doc(db, 'stores', userData.storeID);
          const storeDocSnap = await getDoc(storeDocRef);

          if (storeDocSnap.exists()) {
            const storeData = storeDocSnap.data();

            // Update banking details in the store's bankingDetails array
            const updatedBankingDetails = [
              ...(storeData.bankingDetails || []),
              { accountHolder, accountNumber, branchCode, bankName }
            ];

            await updateDoc(storeDocRef, {
              bankingDetails: updatedBankingDetails
            });

            console.log('Banking details updated in both user and store documents.');
          } else {
            console.error('No such store document!');
          }
        } else {
          console.log('storeID not found in user document.');
        }

        // Additionally, save banking details to the bankingDetails collection
        const bankingDetailsRef = doc(db, 'bankingDetails', userData.storeID);
        await setDoc(bankingDetailsRef, {
          accountHolder,
          accountNumber,
          branchCode,
          bankName
        }, { merge: true });

        console.log('Banking details saved to bankingDetails collection.');

        // If editing, you might want to update the state accordingly
        setIsEditing(true);

        alert('Banking details updated successfully.');
      } else {
        console.error('No such user document!');
      }
    } catch (error) {
      console.error('Error updating document: ', error);
      alert('There was an error updating your banking details. Please try again.');
    }
  }

  // Handle Withdrawal
  const handleWithdrawClick = () => {
    setShowWithdrawalPopup(true);
  };

  const handleWithdrawSubmit = async () => {
    if (!withdrawAmount || parseFloat(withdrawAmount) <= 0 || parseFloat(withdrawAmount) > walletAmount) {
      alert('Please enter a valid withdrawal amount.');
      return;
    }

    if (!bankingDetails) {
      alert('Banking details are missing. Please update your banking information.');
      return;
    }

    try {
      // Create a new transaction document
      const transactionsRef = collection(db, 'transactions');
      await addDoc(transactionsRef, {
        withdrawalAmount: parseFloat(withdrawAmount),
        bankingDetails: bankingDetails,
        transactionDate: serverTimestamp(),
        userID: props.userID,
        storeID: storeID,
        sellerPaid: false,
        // Additional fields can be added here
      });

      // Update the store's walletAmount
      const storeDocRef = doc(db, 'stores', storeID);
      const newWalletAmount = walletAmount - parseFloat(withdrawAmount);
      await updateDoc(storeDocRef, {
        walletAmount: newWalletAmount
      });

      // Update local state
      setWalletAmount(newWalletAmount);
      setWithdrawAmount('');
      setShowWithdrawalPopup(false);

      alert('Withdrawal successful!');
    } catch (error) {
      console.error('Error processing withdrawal:', error);
      alert('There was an error processing your withdrawal. Please try again.');
    }
  };

  return (
    <div className='walletBody'>
      <style>{'body { background-color: #ECD3A1; }'}</style>
      <div className="walletHeader">
        <div className="walletHeaderContainer1">
          <Link to="/vendor">
            <div className="walletBack">
              <img src={backArrow} className="walletBackIcon" alt="Back" />
            </div>
          </Link>
          <div className='walletHeadingDiv'>
            <h1 className='walletHeading'>wallet</h1>
          </div>
        </div>
        <img src={historyIcon} className="walletHistoryIcon" alt="History" />
        <img 
          src={notificationIcon} 
          className="walletNotificationIcon" 
          onClick={() => setShowNotifications(!showNotifications)} 
          alt="Notifications"
        />
      </div>
      {props.country && props.country !== 'South Africa' && (
        <div className="walletCountryUploadNote">
          Note that <strong>taizte is not in {props.country} yet, </strong> 
          hence we do not have your country's banks yet.
        </div>
      )}
      {/* Wallet Amount Section */}
      <div className="walletAmountSection">
        <canvas className="walletGradientCanvas">
          <MarketGradient 
            page={"market"} 
            dominantColors={savedDominantColors} 
            isWideScreen={isWideScreen}
          />
        </canvas>
        <h2 className="walletAmountSectionHeader">wallet amount</h2>
        <div className="walletAmount">
          R{walletAmount.toFixed(2)}
        </div>
        {walletAmount > 0 && (
          <button className="withdrawButton" onClick={handleWithdrawClick}>
            Withdraw from Wallet
          </button>
        )}
      </div>
      <PaymentPending payments={pendingPayments} />
      <form ref={walletFormRef} className="walletForm" onSubmit={saveWithdrawlInformation}>
        <h1 className='bankDetailsHeader'>Banking Details</h1>
        <input 
          type="text" 
          placeholder='Account Holder' 
          className='bankHolderNameInput'
          value={accountHolder}
          onChange={(e) => setAccountHolder(e.target.value)}
          required
        />
        <div className="bankAccountNumberDiv">
          <input 
            placeholder='Account Number' 
            type="number" 
            className='accountNumberInput'
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            required
          />
          <input 
            placeholder='Branch Code' 
            type="number" 
            className='branchCodeInput'
            value={branchCode}
            onChange={(e) => setBranchCode(e.target.value)}
            required
          />
        </div>
        <select 
          name="Bank Name" 
          className='bankNameOptions'
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
          required
        >
          <option value="">Bank Name</option>
          <option value="ABSA">ABSA</option>
          <option value="African Bank">African Bank</option>
          <option value="Albaraka Bank">Albaraka Bank</option>
          <option value="Bank Zero">Bank Zero</option>
          <option value="Bidvest Bank">Bidvest Bank</option>
          <option value="Capitec">Capitec</option>
          <option value="Discovery Bank">Discovery Bank</option>
          <option value="First National Bank (FNB)">First National Bank (FNB)</option>
          <option value="FirstRand Bank">FirstRand Bank</option>
          <option value="Grindrod Bank">Grindrod Bank</option>
          <option value="Investec Bank Ltd">Investec Bank Ltd</option>
          <option value="Mercantile Bank Limited">Mercantile Bank Limited</option>
          <option value="Nedbank Limited">Nedbank Limited</option>
          <option value="Sasfin Bank Limited">Sasfin Bank Limited</option>
          <option value="Standard Bank">Standard Bank</option>
          <option value="TymeBank">TymeBank</option>
          <option value="Ubank Limited">Ubank Limited</option>
        </select>
        <button 
          type='submit' 
          className='saveBankDetailsButton'
          disabled={isSubmitDisabled} // Disable the button if fields are not filled
        >
          {isEditing ? 'Edit Details' : 'Save Details'}
        </button>
      </form>
      <div className="withdrawalWarning">
        <h1 className='withdrawalWarningHeader'>*PLEASE NOTE</h1>
        <ol className="withdrawalWarningText">
          <li>After each sale, your money may take up to 3 business days to reflect in your account.</li>
          <li>Transactions cannot be reversed or cancelled.</li>
        </ol>
      </div>

      {/* Withdrawal Popup */}
      {showWithdrawalPopup && (
        <div className="withdrawalPopup">
          <div className="withdrawalPopupContent">
            <span className="closePopup" onClick={() => setShowWithdrawalPopup(false)}>&times;</span>
            <h2>Withdraw Funds</h2>
            <p>Available Wallet Amount: R{walletAmount.toFixed(2)}</p>
            <input 
              type="number" 
              placeholder="Enter amount to withdraw" 
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
              min="0.01"
              step="0.01"
            />
            <button 
              onClick={handleWithdrawSubmit} 
              disabled={isWithdrawDisabled}
              className="confirmWithdrawButton"
            >
              Confirm Withdrawal
            </button>
          </div>
        </div>
      )}

      <Outlet />
    </div>
  );
}

export default Wallet;
