import React, { useContext, useEffect, useRef, useState } from 'react';
import { VendorFormContext } from '../context/VendorFormContext.jsx';

import { auth, db, storage } from '../firebase';
import {
  addDoc, collection, doc, updateDoc, setDoc,
  arrayUnion, getDoc, getDocs, query, where,
  onSnapshot, serverTimestamp, deleteDoc, runTransaction
} from '@firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

import BusinessLogoDiv from './BusinessLogoDiv.jsx'; 
import deleteIcon from '../icons/deleteIcon.png';
import plusIcon from '../icons/plusIcon.png';

const VendorFormStep3 = ({ next, prev, setAlertMessage, setAlertType }) => {
  const { formData, updateFormData } = useContext(VendorFormContext);

  // Refs
  const uploadDivRef2 = useRef();
  const businessLogoDiv2 = useRef();

  // States
  const [file, setFile] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [preview, setPreview] = useState(formData.store_logo || '');
  const [fileName, setFileName] = useState(formData.fileName || '');
  const [progressCompleted, setProgressCompleted] = useState(false);
  const [data, setData] = useState({});
  const [percentage, setPercentage] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState('');

  // For handling the input field (typed link)
  const [socialMediaInput, setSocialMediaInput] = useState('');

  // A list of social media platform names to detect
  const socialPlatforms = [
    'instagram',
    'facebook',
    'tiktok',
    'linkedin',
    'twitter',
    'snapchat',
    'youtube',
    'pinterest'
    // Add or remove as needed
  ];

  // Read the array directly from our context.
  // Make sure `socialMediaLinks` is an array in the context (VendorFormContext),
  // e.g. socialMediaLinks: [] by default
  const socialMediaArray = formData.socialMediaLinks || [];

  // General handleChange for other fields if needed
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  // *** IMPORTANT: Remove or comment out the snippet that resets socialMediaLinks. ***
  // Otherwise, if `socialMediaIntegration` is never "Yes", it overwrites your array with [].
  /*
  useEffect(() => {
    if (formData.socialMediaIntegration !== 'Yes') {
      updateFormData({ socialMediaLinks: [] });
    }
  }, [formData.socialMediaIntegration, updateFormData]);
  */

  // Keep the progress bar completed if a store_logo is already present
  useEffect(() => {
    if (formData.store_logo) {
      setProgressCompleted(true);
    }
  }, [formData.store_logo]);

  // Keep local fileName in sync with formData
  useEffect(() => {
    if (formData.fileName && !fileName) {
      setFileName(formData.fileName);
    }
  }, [formData.fileName, fileName]);

  // Process and upload the image file
  useEffect(() => {
    if (!file) return;

    const processImage = (file) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';

        img.onload = () => {
          // Create a canvas to draw the image onto
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');

          // Fill the canvas with white background
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0);

          // Convert canvas to Blob
          canvas.toBlob(
            (blob) => resolve(blob),
            file.type,
            1 // highest quality
          );
        };

        img.onerror = (error) => reject(error);

        // Read the file as a Data URL
        const reader = new FileReader();
        reader.onload = (e) => {
          img.src = e.target.result;
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    const uploadFile2 = async () => {
      try {
        setIsUploading(true);

        // Process the image
        const processedFile = await processImage(file);
        const name = file.name + uuidv4();
        setFileName(name);

        const storageRef = ref(storage, `store logo/${name}`);
        const uploadTask = uploadBytesResumable(storageRef, processedFile);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            setPercentage(progress);
          },
          (error) => {
            console.error('Upload error:', error);
            setIsUploading(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setData((prev) => ({ ...prev, img: downloadURL }));
              updateFormData({
                store_logo: downloadURL,
                nonResizedLogoURL: downloadURL,
                fileName: name,
              });
              console.log('Image URL:', downloadURL);

              setPreview(downloadURL);
              setIsUploading(false);
              setFile(null);
              setError('');
            });
          }
        );
      } catch (error) {
        console.error('Error processing image:', error);
        setIsUploading(false);
        setError('Failed to upload image. Please try again.');
      }
    };

    uploadFile2();
  }, [file, updateFormData]);

  // Hide the progress bar when it hits 100%
  useEffect(() => {
    if (percentage === 100) {
      setPercentage(null);
      setProgressCompleted(true);
    }
  }, [percentage]);

  // Delete the uploaded logo
  const handleDelete2 = () => {
    if (!fileName) return;

    console.log('fileName:', fileName);
    const suffix = '_1000x1000';
    const deleteRefResized = ref(storage, `store logo/${fileName}${suffix}`);
    const deleteRefOriginal = ref(storage, `store logo/${fileName}`);

    // Delete Resized Image
    deleteObject(deleteRefResized).catch((error) => {
      console.error('Error deleting resized logo:', error);
    });

    // Delete Original Image
    deleteObject(deleteRefOriginal)
      .then(() => {
        updateFormData({ store_logo: '', nonResizedLogoURL: '', fileName: '' });
        setFile('');
        setData({});
        setFileName('');
        setPreview('');
        setProgressCompleted(false);
        setError('');
        console.log('Original logo successfully deleted');
      })
      .catch((error) => {
        console.error('Error deleting original logo:', error);
        setError('Failed to delete the logo. Please try again.');
      });
  };

  // Handle typed link
  const handleSocialMediaInput = (e) => {
    setSocialMediaInput(e.target.value);
  };

  // Add new social link
  const addSocialMediaLink = () => {
    const trimmed = socialMediaInput.trim();
    if (!trimmed) return;

    const newLinkObj = { url: trimmed };
    const updatedLinks = [...socialMediaArray, newLinkObj];

    // Update the context
    updateFormData({ socialMediaLinks: updatedLinks });
    setSocialMediaInput('');
  };

  // Remove link by index
  const removeSocialMediaLink = (index) => {
    const updatedLinks = socialMediaArray.filter((_, i) => i !== index);
    updateFormData({ socialMediaLinks: updatedLinks });
  };

  // Navigation
  const handleNext = (e) => {
    e.preventDefault();
    next();
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    prev();
  };

  // Clean up any blob object URL when unmounting
  useEffect(() => {
    return () => {
      if (preview && preview.startsWith('blob:')) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [preview]);

  // Validation
  useEffect(() => {
    const validateForm = () => {
      const { store_logo, style } = formData;
      const isLogoPresent = store_logo && store_logo.trim() !== '';
      const isStyleSelected = style && style.trim() !== '';
      return isLogoPresent && isStyleSelected;
    };
    setIsFormValid(validateForm());
  }, [formData]);

  return (
    <form onSubmit={handleNext}>
      {/* The BusinessLogoDiv is unchanged */}
      <BusinessLogoDiv
        businessLogoDivRef={businessLogoDiv2}
        uploadDivRef={uploadDivRef2}
        formData={formData}
        preview={preview}
        isUploading={isUploading}
        percentage={percentage}
        error={error}
        setFile={setFile}
        setPreview={setPreview}
        setError={setError}
        handleDelete={handleDelete2}
      />

      <div className="websiteAestheticsDiv">
        {/* In future, ask about colour scheme - have users select a few colours */}
        <div className="brandAestheticsDiv">
          <label htmlFor="brandAesthetics">
            What word describes your brand aesthetic:
          </label>
          <select
            name="style"
            value={formData.style}
            id="brandAesthetics"
            onChange={handleChange}
            required
          >
            <option value="">Select Style</option>
            <option value="Modern">Modern</option>
            <option value="Minimalist">Minimalist</option>
            <option value="Classic">Classic</option>
            <option value="Bold">Bold</option>
            <option value="Elegant">Elegant</option>
            <option value="Playful">Playful</option>
          </select>
        </div>

        {/* Social Media Input & Display */}
        <div className="socialMediaLinkDiv">
          <label htmlFor="socialMediaInput">Enter your social media link:</label>
          <div className="socialMediaLinkInput">
            <input
              type="text"
              name="socialMediaInput"
              id="socialMediaInput"
              value={socialMediaInput}
              onChange={handleSocialMediaInput}
              placeholder="e.g., https://instagram.com/yourprofile"
            />
            <img
              onClick={addSocialMediaLink}
              src={plusIcon}
              alt="Add social link"
              className="addSocialMediaLinkIcon"
            />
          </div>

          <div className="socialMediaLinksList">
            {socialMediaArray.map((linkObj, index) => {
              const link = linkObj.url;
              const lowerCaseLink = link.toLowerCase();
              const matchedPlatform = socialPlatforms.find(platform =>
                lowerCaseLink.includes(platform)
              );

              // If matched, display just the platform name
              let displayName = link;
              if (matchedPlatform) {
                displayName =
                  matchedPlatform.charAt(0).toUpperCase() +
                  matchedPlatform.slice(1);
              }

              return (
                <div key={index} className="socialMediaLinkItem">
                  <div
                    onClick={() => window.open(link, '_blank')}
                    className="socialMediaLinkButton"
                  >
                    {displayName}
                    <span
                      className="deleteTag"
                      onClick={() => removeSocialMediaLink(index)}
                    >
                      <img
                        src={deleteIcon}
                        alt="Delete link"
                        className="deleteTagIcon"
                      />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="buttons">
        <button
          type="button"
          onClick={handlePrevClick}
          className="backButton"
        >
          back
        </button>
        <button
          type="submit"
          disabled={!isFormValid}
          className={`nextButton ${!isFormValid ? 'disabled' : ''}`}
        >
          next
        </button>
      </div>
    </form>
  );
};

export default VendorFormStep3;
