// Login.js
import React, { useState, useContext, useRef, useEffect } from "react";
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword, 
  onAuthStateChanged, 
  GoogleAuthProvider, 
  signInWithPopup,
  signOut, 
  updateProfile, 
  getRedirectResult, 
  sendPasswordResetEmail, 
  AuthErrorCodes
} from "firebase/auth";
import { AuthContext } from "../context/AuthContext";
import { auth, db } from "../firebase";
import { 
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
  arrayUnion,
  updateDoc
} from "firebase/firestore";
import Alert from "./Alert"; // Import the Alert component

import "./Login.css";
import "./Alert.css"; 
import { OfflineErrorContext } from '../OfflineErrorContext'
import googleLogo from '../icons/googleLogo.png';
import deleteIcon from '../icons/deleteIcon.png';
import eyeIcon from '../icons/eyeIcon.png';

// Utility function to fetch the user's IP address
const getUserIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return "Unknown";
  }
};

// Utility to gather browser and device info (Suggestion 1 & 10 combined)
const getBrowserAndDeviceInfo = () => {
  return {
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    language: navigator.language,
    deviceMemory: navigator.deviceMemory || "unknown",
    hardwareConcurrency: navigator.hardwareConcurrency || "unknown",
    cookieEnabled: navigator.cookieEnabled,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    colorDepth: window.screen.colorDepth,
    pixelDepth: window.screen.pixelDepth,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone // Environmental data
  };
};

// Utility for Network Information (Suggestion 6)
const getNetworkInfo = () => {
  let netInfo = {};
  if ('connection' in navigator) {
    const connection = navigator.connection;
    netInfo.effectiveType = connection.effectiveType;
    netInfo.downlink = connection.downlink;
    netInfo.rtt = connection.rtt;
    netInfo.saveData = connection.saveData;
  } else {
    netInfo = {
      effectiveType: "unknown",
      downlink: "unknown",
      rtt: "unknown",
      saveData: false
    };
  }
  return netInfo;
};

function Login (props) {
  const loginRef = useRef();
  const googleLoginRef = useRef();
  const alreadyLoggedInRef = useRef();

  // Updated state variables for first name and surname
  const [createFirstName, setCreateFirstName] = useState("");
  const [createSurname, setCreateSurname] = useState("");
  const [createEmail, setCreateEmail] = useState("");
  const [createPassword, setCreatePassword] = useState("");

  // Separate state variables for Login
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showAlreadyLoggedIn, setShowAlreadyLoggedIn] = useState(false);

  const { dispatch } = useContext(AuthContext);
  const { setError } = useContext(OfflineErrorContext);

  // Alert state
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success"); // "success" or "error"

  // Password Reset Popup state
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  // Utility function to validate first name and surname
  const isNameValid = (firstName, surname) => {
    return (
      firstName.trim().length > 0 &&
      surname.trim().length > 0 &&
      /^[A-Za-z]+$/.test(firstName) &&
      /^[A-Za-z]+$/.test(surname)
    );
  };

  // Utility function to validate password strength
  const isPasswordStrong = (pwd) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return regex.test(pwd);
  };

  // Utility function to validate email format
  const isEmailValid = (em) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(em);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const togglePassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setShowLogin(false);
        setShowAlreadyLoggedIn(true);
  
        // Check Firestore
        const dbRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(dbRef);

        if (!docSnap.exists()) {
          // Create with setDoc
          const ipAddress = await getUserIP();
          const browserAndDevice = getBrowserAndDeviceInfo();
          const networkInfo = getNetworkInfo();

          const userInfo = {
            firstName: createFirstName,
            surname: createSurname,
            userID: user.uid,
            email: user.email,
            displayName: `${createFirstName} ${createSurname}`,
            user: `${createFirstName} ${createSurname}`,
            dateJoined: serverTimestamp(),
            ipAddress: ipAddress, 
            role: "user",
            ipAddresses: [ipAddress],
            browserAndDeviceInfo: browserAndDevice,
            networkInfo: networkInfo,
            usageHistory: [
              {
                action: "login",
                // OK in setDoc
                timestamp: new Date()
              }
            ]
          };

          await setDoc(dbRef, userInfo);
          console.log("New user doc created in Firestore with serverTimestamp at top-level and usage history.");
        } else {
          // If doc exists => updateDoc
          const ipAddress = await getUserIP();
          const browserAndDevice = getBrowserAndDeviceInfo();
          const networkInfo = getNetworkInfo();

          await updateDoc(dbRef, {
            // Cannot put serverTimestamp() inside arrayUnion() nested fields
            usageHistory: arrayUnion({
              action: "login",
              // Replace serverTimestamp() with new Date() to avoid the error
              timestamp: new Date() 
            }),
            lastLoginIP: ipAddress,
            ipAddresses: arrayUnion(ipAddress),
            browserAndDeviceInfo: browserAndDevice,
            networkInfo: networkInfo,
            // You can still safely set top-level serverTimestamp() like this:
            lastLogin: serverTimestamp()
          });
          console.log("User doc updated with usageHistory, lastLogin, etc.");
        }
  
        // Dispatch the login action
        dispatch({ type: "LOGIN", payload: user });
  
        // Notify the user
        setAlertMessage("You have successfully logged in.");
        setAlertType("success");
  
        // Clear login inputs if any
        setLoginEmail("");
        setLoginPassword("");
      } else {
        setShowLogin(true);
        setShowAlreadyLoggedIn(false);
        dispatch({ type: "LOGOUT", payload: null });
      }
    });
  
    // Cleanup
    return () => unsubscribe();
  }, [dispatch, createFirstName, createSurname]);
  
  // Handle the result of signInWithRedirect
  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          const user = result.user;

          const ipAddress = await getUserIP();
          const browserAndDevice = getBrowserAndDeviceInfo();
          const networkInfo = getNetworkInfo();
  
          const dbRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(dbRef);

          if (!docSnap.exists()) {
            // Create doc
            const userInfo = {
              firstName: createFirstName,
              surname: createSurname,
              userID: user.uid,
              email: user.email,
              displayName: `${createFirstName} ${createSurname}`,
              user: `${createFirstName} ${createSurname}`,
              dateJoined: serverTimestamp(),
              ipAddress: ipAddress,
              role: "user",
              ipAddresses: [ipAddress],
              browserAndDeviceInfo: browserAndDevice,
              networkInfo: networkInfo,
              usageHistory: [
                {
                  action: "login",
                  timestamp: new Date()
                }
              ]
            };
            await setDoc(dbRef, userInfo);
            console.log("User doc created (Google Auth).");
          } else {
            // updateDoc
            await updateDoc(dbRef, {
              usageHistory: arrayUnion({
                action: "login",
                // Use new Date() for arrayUnion
                timestamp: new Date()
              }),
              lastLoginIP: ipAddress,
              ipAddresses: arrayUnion(ipAddress),
              browserAndDeviceInfo: browserAndDevice,
              networkInfo: networkInfo,
              lastLogin: serverTimestamp()
            });
            console.log("User doc updated (Google Auth).");
          }

          dispatch({ type: "LOGIN", payload: user });
          setAlertMessage("You have successfully logged in with Google.");
          setAlertType("success");
          setLoginEmail("");
          setLoginPassword("");
        }
      } catch (error) {
        console.error("Error handling redirect result:", error);
        setAlertMessage("Error signing in with Google.");
        setAlertType("error");
      }
    };

    handleRedirectResult();
  }, [dispatch, createFirstName, createSurname]);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!loginEmail.trim() && !loginPassword.trim()) {
      setAlertMessage("Please enter your email and password.");
      setAlertType("error");
      return;
    }

    if (!loginEmail.trim()) {
      setAlertMessage("Please enter your email.");
      setAlertType("error");
      return;
    }

    if (!isEmailValid(loginEmail)) {
      setAlertMessage("Please enter a valid email address.");
      setAlertType("error");
      return;
    }

    if (!loginPassword.trim()) {
      setAlertMessage("Please enter your password.");
      setAlertType("error");
      return;
    }

    // Check if email ends with @gmail.com
    if (loginEmail.toLowerCase().endsWith('@gmail.com')) {
      setAlertMessage("Please use the quick login option for Gmail accounts.");
      setAlertType("error");
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      const user = userCredential.user;
      dispatch({type:"LOGIN", payload:user});
      console.log(user);

      const ipAddress = await getUserIP();
      const browserAndDevice = getBrowserAndDeviceInfo();
      const networkInfo = getNetworkInfo();

      const dbRef = doc(db, 'users', user.uid);
      // updateDoc partial
      await updateDoc(dbRef, {
        lastLogin: serverTimestamp(),
        lastLoginIP: ipAddress,
        ipAddresses: arrayUnion(ipAddress),
        usageHistory: arrayUnion({
          action: "login",
          // Use local Date() for nested object
          timestamp: new Date()
        }),
        browserAndDeviceInfo: browserAndDevice,
        networkInfo: networkInfo
      });
      console.log("Login event updated for existing user doc.");

      setAlertMessage("You have successfully logged in.");
      setAlertType("success");
      setLoginEmail("");
      setLoginPassword("");
    }
    catch (error) {
      console.error("Error details:", error);
      if (!navigator.onLine) {
        setError("Failed to log in. You are currently offline.");
      } else {
        setAlertMessage("Wrong email or password.");
        setAlertType("error");
      }
    }
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
  
    if (!createFirstName.trim() && !createSurname.trim() && !createEmail.trim() && !createPassword.trim()) {
      setAlertMessage("Please enter your first name, surname, email, and password.");
      setAlertType("error");
      return;
    }

    if (!createFirstName.trim()) {
      setAlertMessage("Please enter your first name.");
      setAlertType("error");
      return;
    }

    if (!createSurname.trim()) {
      setAlertMessage("Please enter your surname.");
      setAlertType("error");
      return;
    }

    if (!isNameValid(createFirstName, createSurname)) {
      setAlertMessage("Please enter a valid first name and surname (letters only).");
      setAlertType("error");
      return;
    }

    if (!createEmail.trim()) {
      setAlertMessage("Please enter your email.");
      setAlertType("error");
      return;
    }

    if (!isEmailValid(createEmail)) {
      setAlertMessage("Please enter a valid email address.");
      setAlertType("error");
      return;
    }

    if (!createPassword.trim()) {
      setAlertMessage("Please enter your password.");
      setAlertType("error");
      return;
    }

    if (!isPasswordStrong(createPassword)) {
      setAlertMessage("Password must be at least 8 characters long and include uppercase, lowercase, numbers, and special characters.");
      setAlertType("error");
      return;
    }

    if (createEmail.toLowerCase().endsWith('@gmail.com')) {
      setAlertMessage("Please use the quick login option for Gmail accounts.");
      setAlertType("error");
      return;
    }

    try {
      const ipAddress = await getUserIP();
      const browserAndDevice = getBrowserAndDeviceInfo();
      const networkInfo = getNetworkInfo();

      const userCredential = await createUserWithEmailAndPassword(auth, createEmail, createPassword);
      const user = userCredential.user;
      console.log("user credential: ", user, createFirstName, createSurname);

      // Create doc with setDoc
      const dbRef = doc(db, 'users', user.uid);
      const userInfo = {
        firstName: createFirstName,
        surname: createSurname,
        userID: user.uid,
        email: user.email,
        displayName: `${createFirstName} ${createSurname}`,
        user: `${createFirstName} ${createSurname}`,
        role: "user",
        dateJoined: serverTimestamp(),
        ipAddress: ipAddress,
        ipAddresses: [ ipAddress ],
        browserAndDeviceInfo: browserAndDevice,
        networkInfo: networkInfo,
        usageHistory: [
          {
            action: "login",
            timestamp: new Date()
          }
        ]
      };
  
      await setDoc(dbRef, userInfo);
      console.log("New user doc created with setDoc, including a usageHistory array.");

      // Update displayName if needed
      if (!auth.currentUser.displayName) {
        await updateProfile(auth.currentUser, { displayName: `${createFirstName} ${createSurname}` });
        console.log("The displayName has been updated.");
      } else {
        console.log("The user already has a displayName.");
      }

      dispatch({ type: "LOGIN", payload: user });
      setAlertMessage("You have created an account and are now logged in.");
      setAlertType("success");
      console.log("The user has been added to Firestore.");

      // Clear create account fields
      setCreateFirstName("");
      setCreateSurname("");
      setCreateEmail("");
      setCreatePassword("");
    } catch (error) {
      console.error("Error creating account:", error);
      if (!navigator.onLine) {
        setError("Failed to create account. You are currently offline.");
      } else if (error.code === 'auth/email-already-in-use') {
        setAlertMessage("This email is already in use. Please use a different email or log in.");
        setAlertType("error");
      } else {
        setAlertMessage("Error creating account. Please try again.");
        setAlertType("error");
      }
    }
  };
   
  const provider = new GoogleAuthProvider();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleGoogleSignIn = async () => {
    console.log(navigator.userAgent);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const ipAddress = await getUserIP();
      const browserAndDevice = getBrowserAndDeviceInfo();
      const networkInfo = getNetworkInfo();

      const dbRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(dbRef);

      if (!docSnap.exists()) {
        // setDoc for brand-new user
        const userInfo = {
          firstName: createFirstName || "",
          surname: createSurname || "",
          userID: user.uid,
          email: user.email,
          displayName: user.displayName || `${createFirstName} ${createSurname}`,
          user: user.displayName || `${createFirstName} ${createSurname}`,
          dateJoined: serverTimestamp(),
          ipAddress: ipAddress,
          role: "user",
          ipAddresses: [ ipAddress ],
          browserAndDeviceInfo: browserAndDevice,
          networkInfo: networkInfo,
          usageHistory: [
            {
              action: "login",
              timestamp: new Date()
            }
          ]
        };
        await setDoc(dbRef, userInfo);
        console.log("New user doc for Google sign-in created.");
      } else {
        // updateDoc
        await updateDoc(dbRef, { 
          lastLogin: serverTimestamp(),
          lastLoginIP: ipAddress,
          ipAddresses: arrayUnion(ipAddress),
          usageHistory: arrayUnion({
            action: "login",
            // new Date() in the array
            timestamp: new Date()
          }),
          browserAndDeviceInfo: browserAndDevice,
          networkInfo: networkInfo
        });
        console.log("Existing user doc updated with Google sign-in data.");
      }

      dispatch({ type: "LOGIN", payload: user });
      setAlertMessage("You have successfully logged in with Google.");
      setAlertType("success");

      // Clear login inputs
      setLoginEmail("");
      setLoginPassword("");
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      setAlertMessage("Error signing in with Google.");
      setAlertType("error");
    }
  };
  
  const authenticationRef = useRef(null);
  const frameInfoRef = useRef();
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (authenticationRef.current && !authenticationRef.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ onClickOutside ]);

  const handleLogout = (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (user) {
      const dbRef = doc(db, 'users', user.uid);
      updateDoc(dbRef, {
        usageHistory: arrayUnion({
          action: "logout",
          // Again, local Date for the array
          timestamp: new Date()
        }),
        lastLogout: serverTimestamp()
      })
      .then(() => {
        console.log("User logout event recorded in usageHistory array.");
      })
      .catch((error) => {
        console.error("Error updating logout event:", error);
      });
    }

    signOut(auth).then(() => {
      setAlertMessage("You are now logged out.");
      setAlertType("success");
      setShowLogin(true);
      setShowAlreadyLoggedIn(false);
      dispatch({ type: "LOGOUT", payload: null });

      // Clear all input fields
      setCreateFirstName("");
      setCreateSurname("");
      setCreateEmail("");
      setCreatePassword("");
      setLoginEmail("");
      setLoginPassword("");
      
      console.log("User has been logged out and AuthContext updated.");
    }).catch((error) => {
      console.error("Error details:", error);
      setAlertMessage("Error logging out.");
      setAlertType("error");
    });
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (!resetEmail.trim()) {
      setAlertMessage("Please enter your email address.");
      setAlertType("error");
      return;
    }

    if (!isEmailValid(resetEmail)) {
      setAlertMessage("Please enter a valid email address.");
      setAlertType("error");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setAlertMessage("Password reset email sent. Please check your inbox.");
      setAlertType("success");
      setShowPasswordReset(false);
      setResetEmail("");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      if (error.code === AuthErrorCodes.USER_DELETED) {
        setAlertMessage("No user found with this email.");
      } else {
        setAlertMessage("Error sending password reset email. Please try again.");
      }
      setAlertType("error");
    }
  };

  const passwordResetRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (passwordResetRef.current && !passwordResetRef.current.contains(event.target)) {
        setShowPasswordReset(false);
      }
    };
    if (showPasswordReset) {
      document.addEventListener('click', handleClickOutside, true);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showPasswordReset]);

  if(!props.show) return null;

  return (
    <div className="authentication" ref={authenticationRef}>
      <Alert component={'login'} message={alertMessage} type={alertType} onClose={() => setAlertMessage("")} />

      {showLogin &&
        <div className="bootstraptAuth" ref={loginRef}>
          {/* Create Account Form */}
          <form id="createAccountForm" onSubmit={handleCreateAccount} noValidate>
            <h1 className="createAccountHeader">create</h1>
            <input 
              id="firstNameInput" 
              type="text" 
              placeholder=" First Name" 
              value={createFirstName}
              onChange={e => setCreateFirstName(e.target.value)} 
              required 
            />
            <input 
              id="surnameInput" 
              type="text" 
              placeholder=" Surname" 
              value={createSurname}
              onChange={e => setCreateSurname(e.target.value)} 
              required 
            />
            <input 
              id="emailInput" 
              type="email" 
              placeholder=" Email" 
              value={createEmail}
              onChange={e => setCreateEmail(e.target.value)} 
              required 
            />
            <div className="bootstraptPasswordDiv">
              <input 
                id="passwordInput" 
                type={showPassword ? "text" : "password"} 
                placeholder=" Password" 
                value={createPassword}
                onChange={e => setCreatePassword(e.target.value)} 
                required 
              />
              <img 
                className="bootstraptAuthShowPasswordIcon" 
                src={eyeIcon} 
                alt="show password" 
                onClick={togglePassword} 
                aria-label="Toggle password visibility"
              />
            </div>
            <button id="createAccountSubmitBtn" type="submit">create account</button>
          </form>

          {/* Login Form */}
          <form id="loginForm" onSubmit={handleLogin} ref={loginRef} noValidate>
            <h1 className="loginHeader">login</h1>
            <input 
              id="emailInput2" 
              type="email" 
              placeholder=" Email" 
              value={loginEmail}
              onChange={e => setLoginEmail(e.target.value)} 
              required 
            />
            <div className="loginSectionPasswordDiv">
              <input 
                id="passwordInput2" 
                type={showPassword2 ? "text" : "password"} 
                placeholder=" Password" 
                value={loginPassword}
                onChange={e => setLoginPassword(e.target.value)} 
                required 
              />
              <img 
                className="loginSectionShowPasswordIcon" 
                src={eyeIcon} 
                alt="show password" 
                onClick={togglePassword2} 
                aria-label="Toggle password visibility"
              />
            </div>
            <div className="forgotPasswordContainer">
              <button 
                type="button" 
                className="forgotPasswordBtn" 
                onClick={() => setShowPasswordReset(true)}
              >
                Forgot Password?
              </button>
            </div>
            <button id="loginSubmitBtn" type="submit">login</button>
          </form>
        </div>
      }

      {showLogin &&
        <div className="GoogleAuth" ref={googleLoginRef}>
          <button onClick={handleGoogleSignIn} className="GoogleLoginBtn">
            <img className="googleLogo" src={googleLogo} alt="Google Logo" />
            <div className="GoogleAuthLoginText">Login with Google</div>
          </button>
        </div>
      }

      {showAlreadyLoggedIn &&
        <div className="userLoggedIn" ref={alreadyLoggedInRef}>
          <p className="warningLoggedInText">You are already logged in.</p>
          <button onClick={handleLogout} className="logoutBtn">logout?</button>
        </div>
      }

      {showPasswordReset && (
        <div className="passwordResetOverlay">
          <div className="passwordResetPopup" ref={passwordResetRef}>
            <h2 className="passwordResetHeader">reset password</h2>
            <form className="passwordResetContent" onSubmit={handlePasswordReset} noValidate>
              <input 
                type="email" 
                id="forgottenPasswordEmailInput"
                placeholder="Enter your email" 
                value={resetEmail}
                onChange={e => setResetEmail(e.target.value)} 
                required 
              />
              <div className="passwordResetButtons">
                <button type="submit" className="passwordResetBtn">Send Reset Link</button>
                <img
                  type="button"
                  className="passwordResetCancelBtn"
                  src={deleteIcon}
                  alt="cancel reset popup"
                  onClick={() => setShowPasswordReset(false)}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default Login;
