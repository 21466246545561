// src/components/Explainer.js

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";

import './Explainer.css';
import image1 from '../images/1.png';
import image2 from '../images/2.png';
import image3 from '../images/3.png';
import image4 from '../images/4.png';
import image5 from '../images/5.png';

import eyeIcon from '../icons/eyeIcon.png';
import editIcon from '../icons/editIcon.png';
import scoutIcon from '../icons/scoutIcon.png';
import backArrow from '../icons/returnArrowIcon.png'; // Back arrow for Step 1
import forwardArrow from '../icons/backArrow.png'; // Forward arrow for Step 1
import backArrowSecond from '../icons/backArrow.png'; // Back arrow for Step 2

const Explainer = ({
  isExplainerOpen,
  explainerStep,
  direction,
  setIsExplainerOpen,
  setExplainerStep,
  setDirection,
  currentUser
}) => {
  console.log(currentUser)
  
  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    
    window.addEventListener('resize', handleResize);
    
    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Variants for the explainer container
  const explainerVariants = {
    closed: {
      width: currentUser 
        ? 'calc(100vw - 20px)' 
        : windowWidth > 460 
          ? '437px' 
          : 'calc(100vw - 20px)',
      height: '50px',
      backgroundColor: '#585123',
      borderRadius: '15px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      color: '#BFB25F',
      justifyContent: 'space-between',
      fontFamily: 'Poppins',
      cursor: 'pointer',
      margin: '0 10px',
      padding: '25px 30px 25px 25px',
      boxSizing: 'border-box',
      transition: { duration: 0.5, ease: "easeInOut" },
      border: 'none'
    },
    open: {
      width: 'calc(100vw - 20px)',
      height: 'calc(100vh - 130px)',
      backgroundColor: '#FFE5D9',
      borderRadius: '25px',
      cursor: 'default',
      padding: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
      border: 'solid 2px rgba(44, 23, 0, 0.8)'
    }
  };

  // Variants for card transitions
  const cardVariants = {
    initial: (direction) => ({
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0
    }),
    animate: {
      x: 0,
      opacity: 1
    },
    exit: (direction) => ({
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0
    })
  };

  // Determine explainer card background color based on step
  const explainerCardBackgroundColor = () => {
    if (explainerStep === 1) return '#FFE5D9';
    if (explainerStep === 2) return '#847852';
    return '#FFE5D9';
  };

  // Handle swipe direction and step changes
  const paginate = (newDirection) => {
    setDirection(newDirection);
    setExplainerStep(prev => prev + newDirection);
  };

  // Define the header texts to cycle through
  const headerTexts = windowWidth < 470 
  ? ['for makers', 'for artisans', 'for artists']
  : ['for entrepreneurs', 'for artisans', 'for artists'];

  // State to track the current header index
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(0);

  // Define display and animation durations
  const displayDuration = 4000; // 10 second
  const animationDuration = 500; // 0.5 seconds
  const totalDuration = displayDuration + animationDuration; // 1.5 seconds

  // useEffect to handle cycling through header texts with controlled timing
  useEffect(() => {
    // Only cycle when explainer is open and on step 1
    if (!isExplainerOpen || explainerStep !== 1) return;

    const timeoutId = setTimeout(() => {
      setCurrentHeaderIndex((prevIndex) => (prevIndex + 1) % headerTexts.length);
    }, totalDuration); // 1.5 seconds total

    // Cleanup timeout on unmount or when dependencies change
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isExplainerOpen, explainerStep, currentHeaderIndex, headerTexts.length]);

  // Define animation variants for the header text
  const headerVariants = {
    initial: { y: -20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: 20, opacity: 0 },
  };

  return (
    <>
      {/* Backdrop to prevent interaction with underlying content */}
      <AnimatePresence>
        {isExplainerOpen && (
          <motion.div
            className="explainerBackdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            onClick={() => {
              setIsExplainerOpen(false);
              setExplainerStep(1);
            }}
          />
        )}
      </AnimatePresence>

      <motion.div
        className="homeExplainerDiv"
        variants={explainerVariants}
        animate={isExplainerOpen ? "open" : "closed"}
        initial="closed"
        onClick={() => !isExplainerOpen && setIsExplainerOpen(true)}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <AnimatePresence custom={direction} initial={false}>
          {!isExplainerOpen ? (
            <motion.span 
              className="closedExplainerDiv"
              key="closed"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              What's this about?
              <img className="closedExplainerArrow" src={backArrow} alt="Expand Explainer" />
            </motion.span>
          ) : (
            <>
              {explainerStep === 1 && (
                <motion.div
                  className="explainerContent"
                  key="step1"
                  custom={direction}
                  variants={cardVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                  style={{ backgroundColor: explainerCardBackgroundColor(), width: '100%', height: '100%', position: 'relative' }}
                > 
                  <img src={image3} className="explainerImg3" alt="Step 1 Image 3" />
                  <img src={image4} className="explainerImg4" alt="Step 1 Image 4" />
                  
                  <div className='explainerStep1Container'>
                    {/* Back Arrow Button (to close explainer) */}
                    <div className="explainerStep1ContainerTop">
                      <div className="explainerStep1Group1">
                        <button 
                          className="explainerBackArrowButton" 
                          onClick={() => {
                            setIsExplainerOpen(false);
                            setExplainerStep(1);
                          }}
                          style={{ 
                            background: 'none', 
                            border: 'none', 
                            cursor: 'pointer', 
                            opacity: '0.6',
                          }}
                        >
                          <img className="backArrowIcon" src={backArrow} alt="Close Explainer" width={24} height={24} />
                        </button>

                        {/* Animated Header */}
                        <AnimatePresence exitBeforeEnter>
                          <motion.div
                            key={currentHeaderIndex}
                            variants={headerVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.5 }} // Animation duration: 0.5s
                            className='explainerStep1Header'
                          >
                            {headerTexts[currentHeaderIndex]}
                          </motion.div>
                        </AnimatePresence>
                      </div>

                      <div className="explainerHeaderIconContainer">
                        <img src={eyeIcon} className="explainerEyeIcon"/>
                        <img src={scoutIcon} className="explainerScoutIcon"/>
                        <img src={editIcon} className="explainerEditIcon"/>
                      </div>
                    </div>

                    <div className='explainerStep1Description'>
                      <strong>taizte simplifies e-commerce</strong> - combining product 
                      upload, website creation & payment processing.
                      <br/>
                      <br/>
                      YAGA just has product upload. This doesn't tell your 
                      business' story or make it standout. taizte websites 
                      showoff your brand better than a store bio. 
                    </div>
                  </div>

                  <div className="explainerStep1Container2">
                    <div className="explainerStep1C2TextDiv">
                      <div className="explainerS1C2Highlight">no complex pricing, 10% commission (taken only after a sale)</div>
                      <div className="explainerS1C2Highlight">easy, drag-and-drop website builder</div>
                      <div className="explainerS1C2Highlight">product upload & store management tools</div>
                      <div className="explainerS1C2Highlight">secure payment processing (YOCO)</div>
                      <div className="explainerS1C2Highlight">small business focused over influencers & big businesses</div>
                      {(windowWidth > 490) && (
                        <div className="explainerS1C2Highlight">
                          fast, human customer support
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="explainerStep1Container3"></div>

                  {/* Forward Arrow Button */}
                  <button 
                    className="forwardArrowButton" 
                    onClick={() => paginate(1)}
                    style={{ 
                      background: 'none', 
                      border: 'none', 
                      cursor: 'pointer', 
                      position: 'absolute', 
                      bottom: 15, 
                      right: 15,
                      zIndex: 1,
                    }}
                  >
                    <img className="forwardArrowIcon" src={forwardArrow} alt="Next Step" width={24} height={24} />
                  </button>
                </motion.div>
              )}

              {explainerStep === 2 && (
                <motion.div
                  className="explainerContent"
                  key="step2"
                  custom={direction}
                  variants={cardVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                  style={{ backgroundColor: explainerCardBackgroundColor(), width: '100%', height: '100%', position: 'relative' }}
                >
                  <img src={image1} className="explainerImg1" alt="Step 1 Image 1" />
                  <img src={image2} className="explainerImg2" alt="Step 1 Image 2" />
                  <img src={image5} className="explainerImg5" alt="Step 2 Image 1" />

                  
                  <div className="explainerS2C1Highlight">
                    If you have any suggestions, critiques or things you wish other 
                    platforms did, please email me at bocashopeterbraaf@gmail.com and 
                    I'll respond as soon as possible.
                    <br/>
                    <br/>
                    I can and will attempt to implement your suggestion. To build a community of entrepreneurs,
                    artisans and artists, it's important that you share your experiences, 
                    so <strong>taizte</strong> reflects our community of taiztemakers.
                  </div>

                  <div className="explainerS2C1Contact">
                    <div><strong>Cellphone: </strong> 064 015 8668</div>
                    <div><strong>Email: </strong> bocashopeterbraaf@gmail.com</div>
                    <div><strong>Instagram: </strong> @taizte_sa</div>
                  </div>

                  {/* Back Arrow Button (to go back to Step 1) */}
                  <button 
                    className="backArrowButtonSecond" 
                    onClick={() => paginate(-1)}
                    style={{ 
                      background: 'none', 
                      border: 'none', 
                      cursor: 'pointer', 
                      position: 'absolute', 
                      bottom: 15, 
                      left: 15 
                    }}
                  >
                    <img className="backArrowIconSecond" src={backArrowSecond} alt="Back to Step 1" width={24} height={24} />
                  </button>
                </motion.div>
              )}
            </>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
};

export default Explainer;
