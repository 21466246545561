// src/admin/components/Transactions.jsx
import React, { useState, useEffect } from 'react';
import './Transactions.css'; // Ensure you have appropriate styles
import { 
  collection, 
  getDocs, 
  query, 
  where, 
  orderBy, 
  updateDoc, 
  doc 
} from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the import path as necessary

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [updatingTransactionId, setUpdatingTransactionId] = useState(null); // To track which transaction is being updated

  // Fetch transactions where sellerPaid is false
  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const transactionsRef = collection(db, 'transactions');
        const q = query(
          transactionsRef,
          where('sellerPaid', '==', false),
          orderBy('transactionDate', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const fetchedTransactions = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTransactions(fetchedTransactions);
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setError('Failed to fetch transactions. Please try again later.');
      }
      setLoading(false);
    };

    fetchTransactions();
  }, []);

  // Function to mark a transaction as paid
  const markAsPaid = async (transactionId) => {
    setUpdatingTransactionId(transactionId);
    try {
      const transactionRef = doc(db, 'transactions', transactionId);
      await updateDoc(transactionRef, {
        sellerPaid: true,
        paymentDate: new Date(), // Optionally record when the payment was made
      });

      // Remove the transaction from the list as it's now paid
      setTransactions(prevTransactions => 
        prevTransactions.filter(transaction => transaction.id !== transactionId)
      );

      alert('Transaction marked as paid successfully.');
    } catch (err) {
      console.error('Error updating transaction:', err);
      alert('Failed to update the transaction. Please try again.');
    }
    setUpdatingTransactionId(null);
  };

  return (
    <div className="transactions-component">
      <h2>Transactions</h2>
      <p>
        This section lists all sellers who need to be paid. These are sellers who have sent the product and the buyer has received it.
      </p>

      {loading ? (
        <p>Loading transactions...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : transactions.length === 0 ? (
        <p>No pending transactions.</p>
      ) : (
        <div className="transactions-list">
          {transactions.map(transaction => (
            <div key={transaction.id} className="transaction-card">
              <h3>Transaction ID: {transaction.id}</h3>
              <div className="transaction-details">
                <div className="detail-group">
                  <h4>Banking Details:</h4>
                  <p><strong>Account Holder:</strong> {transaction.bankingDetails.accountHolder}</p>
                  <p><strong>Account Number:</strong> {transaction.bankingDetails.accountNumber}</p>
                  <p><strong>Branch Code:</strong> {transaction.bankingDetails.branchCode}</p>
                  <p><strong>Bank Name:</strong> {transaction.bankingDetails.bankName}</p>
                </div>
                <div className="detail-group">
                  <h4>Withdrawal Amount:</h4>
                  <p>R{parseFloat(transaction.withdrawalAmount).toFixed(2)}</p>
                </div>
                <div className="detail-group">
                  <h4>User Information:</h4>
                  <p><strong>User ID:</strong> {transaction.userID}</p>
                  <p><strong>Store ID:</strong> {transaction.storeID}</p>
                </div>
                <div className="detail-group">
                  <h4>Transaction Date:</h4>
                  <p>{transaction.transactionDate?.toDate().toLocaleString() || 'N/A'}</p>
                </div>
                <div className="detail-group">
                  <h4>Status:</h4>
                  <p>{transaction.sellerPaid ? 'Paid' : 'Pending'}</p>
                </div>
              </div>
              {!transaction.sellerPaid && (
                <button 
                  className="mark-paid-button" 
                  onClick={() => markAsPaid(transaction.id)}
                  disabled={updatingTransactionId === transaction.id}
                >
                  {updatingTransactionId === transaction.id ? 'Updating...' : 'Mark as Paid'}
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Transactions;
