// LongPress.js

import { useRef, useState, useEffect } from 'react';

const LongPress = (
  onLongPress, 
  onClick, 
  {
    delay = 500, 
    shouldPreventDefault = true, 
    moveThreshold = 10 // Our new threshold in px
  } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const startX = useRef(null);
  const startY = useRef(null);
  const target = useRef();

  const isTouchEvent = (event) => 'touches' in event;

  const preventDefault = (event) => {
    if (!isTouchEvent(event)) return;
    if (event.touches.length < 2 && event.preventDefault) {
      event.preventDefault();
    }
  };

  const start = (event) => {
    // Skip if the target or parent has data-interactive=true
    if (event.target.closest('[data-interactive="true"]')) {
      return;
    }

    if (shouldPreventDefault && event.target) {
      event.target.addEventListener('touchend', preventDefault, { passive: false });
      target.current = event.target;
    }

    if (isTouchEvent(event)) {
      startX.current = event.touches[0].clientX;
      startY.current = event.touches[0].clientY;
    } else {
      startX.current = event.clientX;
      startY.current = event.clientY;
    }

    timeout.current = setTimeout(() => {
      onLongPress(event);
      setLongPressTriggered(true);
    }, delay);
  };

  const move = (event) => {
    if (!startX.current || !startY.current) return;
    let x, y;
    if (isTouchEvent(event)) {
      x = event.touches[0].clientX;
      y = event.touches[0].clientY;
    } else {
      x = event.clientX;
      y = event.clientY;
    }

    const deltaX = Math.abs(x - startX.current);
    const deltaY = Math.abs(y - startY.current);

    // If user moves beyond the threshold in any direction, cancel
    if (deltaX > moveThreshold || deltaY > moveThreshold) {
      clear(event, false);
    }
  };

  const clear = (event, shouldTriggerClick = true) => {
    timeout.current && clearTimeout(timeout.current);
    shouldTriggerClick && !longPressTriggered && onClick(event);
    setLongPressTriggered(false);

    // Cleanup
    if (shouldPreventDefault && target.current) {
      target.current.removeEventListener('touchend', preventDefault);
    }
    startX.current = null;
    startY.current = null;
  };

  useEffect(() => {
    return () => clear(null, false);
  }, []);

  return {
    onMouseDown: start,
    onTouchStart: start,
    onMouseMove: move,
    onTouchMove: move,
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  };
};

export default LongPress;
