import React, { useState, useRef, useEffect } from 'react';
import plusIcon from '../icons/plusIcon.png';
import deleteIcon from '../icons/deleteIcon.png';
import infoIcon from '../icons/infoIcon.png'; // Import the info icon
import vendorIcon from '../icons/vendorIcon.png'; // Import the vendor icon
import dashboardIcon from '../icons/dashboardIcon.png'; // Import the dashboard icon
import './BusinessLogoDiv.css';


const BusinessLogoDiv = ({
  businessLogoDivRef,
  uploadDivRef,
  formData,
  preview,
  isUploading,
  percentage,
  error,
  setFile,
  setPreview,
  setError,
  handleDelete
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const hasLogo = preview || formData.store_logo;

  // Create a ref for the file input
  const inputRef = useRef(null);

  // Reset the input value when hasLogo becomes false
  useEffect(() => {
    if (!hasLogo && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [hasLogo]);

  return (
    <div className="uploadLogoMediaDiv" ref={businessLogoDivRef}>
      {/* Header with Info Icon */}
      <div className="uploadProductImagesHeader">
        upload brand logo
        <img
          src={infoIcon}
          alt="Info"
          className="logoInfoIcon"
          onClick={() => setShowInfo(!showInfo)}
        />
      </div>

      {/* Info Message */}
      {showInfo && (
        <div className="logoInfoPopup">
          <div className="logoInfoPopupContent">
            The logo can be changed in the{' '}
            <div className="logoInfoBtn">
              <img src={vendorIcon} alt="Vendor" className="logoInfoBtnIcon" />
              vendor page
            </div>{' '}
            within the{' '}
            <div className="logoInfoBtn">
              <img src={dashboardIcon} alt="Dashboard" className="logoInfoBtnIcon" />
              dashboard page
            </div>
          </div>
        </div>
      )}

      {/* Container for the logo image or upload icon */}
      <div className="uploadProductMediaContainer">
        {/* If there's no logo selected yet, show plus icon + label */}
        {!hasLogo && (
          <label
            htmlFor="inputField2"
            className="addProductImagePlusBtn"
            style={{ margin: 0 }}
          >
            <img
              className="addProductImagePlusBtnIcon"
              src={plusIcon}
              alt="Upload Logo"
            />
          </label>
        )}

        {/* Hidden input for selecting the file */}
        <input
          type="file"
          id="inputField2"
          accept="image/*"
          onChange={(e) => {
            const selectedFile = e.target.files[0];
            if (selectedFile) {
              if (selectedFile.size > 5 * 1024 * 1024) { // 5 MB limit
                setError('File size exceeds 5 MB. Please select a smaller file.');
                e.target.value = ''; // Reset the input
                return;
              }
              setFile(selectedFile);
              setPreview(URL.createObjectURL(selectedFile));
              setError(''); // Clear any previous errors
            }
          }}
          style={{ display: 'none' }}
          required={!formData.store_logo}
          ref={inputRef} // Attach the ref here
        />

        {/* If logo exists (preview or store_logo), show it */}
        {hasLogo && (
          <div
            ref={uploadDivRef}
            className="logoUploadPreviewDiv"
            style={{ position: 'relative' }}
          >
            <img
              src={preview || formData.store_logo}
              className="logoUploadPreview"
              alt="Uploaded Logo"
            />

            {/* Show a progress bar if uploading */}
            {isUploading && (
              <progress
                className="progressBar"
                value={percentage || 0}
                max="100"
                style={{ position: 'absolute', bottom: '10px' }}
              />
            )}

            {/* If not uploading, show delete icon */}
            {!isUploading && formData.store_logo && (
              <div id="imageUploadDeleteBtn" onClick={handleDelete}>
                <img
                  src={deleteIcon}
                  alt="Delete Logo"
                  className="deleteIcon"
                  style={{
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Show any error message at the bottom */}
      {error && <div className="addProductErrorMessage">{error}</div>}
    </div>
  );
};

export default BusinessLogoDiv;
