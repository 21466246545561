// TextPopup.js

import React, { useState } from "react";
import Select from "react-select";
import "./TextPopup.css";
import "./Fonts.css";
import fonts from "./Fonts"; // an array of { label, value, fontFamily } objects

export default function TextPopup(props) {
  const [selectedFont, setSelectedFont] = useState(props.fontFullName || "Poppins");

  // Locate the font object in the fonts array:
  const selectedFontObj = fonts.find((font) => font.value === selectedFont) || fonts[0];

  // When a user selects a new font from the dropdown:
  const handleSelectChange = (option) => {
    if (!option) return;
    setSelectedFont(option.value);
    props.setFontFullName(option.value);
    props.selectedFont(option.value);
  };

  // Custom styles similar to your CategoriesDiv but adapted for fonts
  const customStyles = {
    container: () => ({
      width: '100%'
    }),
    control: (base, state) => ({
      ...base,
      position: 'relative',
      backgroundColor: '#f5f5f5',
      height: '30px',
      borderRadius: '10px',
      fontFamily: selectedFontObj.fontFamily, // show the chosen font in the control
      fontSize: '14px',
      zIndex: 1001,
      boxShadow: '-2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45)',
      boxSizing: 'border-box',
      border: 'none',
      cursor: 'pointer',
    }),
    // Remove the vertical line (the "indicator separator"):
    indicatorSeparator: () => ({
      display: 'none',
    }),
    // The dropdown menu container:
    menu: (base) => ({
      ...base,
      position: 'absolute',
      backgroundColor: '#f5f5f5',
      marginTop: '10px',
      borderRadius: '12px',
      fontFamily: 'Poppins', // fallback
      fontSize: '16px',
      padding: '0 5px',
      width: 'calc(95vw - 50px)',
      maxHeight: '280px', // Ensure the menu doesn't exceed this height
      overflow: 'hidden', // Keep the menu itself hidden from overflow
      zIndex: 1000,
    }),
    // The scrollable list inside the dropdown:
    menuList: (base) => ({
      ...base,
      maxHeight: '280px',
      overflowY: 'auto', // Change from 'hidden' to 'auto' to enable scrolling
      // Force scrollbar to appear even if globally disabled:
      '::-webkit-scrollbar': {
        width: '6px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#bfbfbf',
        borderRadius: '3px',
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? '#FB890E'
        : state.isFocused
        ? '#FFC55C'
        : base.backgroundColor,
      color: state.isSelected || state.isFocused ? 'rgba(0, 0, 0, 0.8)' : base.color,
      margin: '5px 0',
      boxSizing: 'border-box',
      overflow: 'hidden',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      textAlign: 'left',
      fontFamily: state.data.fontFamily || 'Poppins', // show each option in its correct font
      transition: 'font-family 0.3s ease',
      cursor: 'pointer',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#333',
      fontFamily: selectedFontObj.fontFamily, // ensures singleValue also shows the chosen font
      transition: 'font-family 0.3s ease',
    }),
  };

  return (
    <div
      className={`textPopupFullSection${
        props.parentComponent === "draggableFrames" ? "DraggableFrames" : ""
      }`}
      onClick={props.onPopupClick}
      ref={props.textPopupRef}
    >
      <div className="textPopupDiv">
        <div className="textPopupBottomSection">
          {/* Colour Selector */}
          <div
            className="fontColourSelector"
            onClick={(e) => {
              e.stopPropagation();
              props.setShowTextColorPopup(!props.showTextColorPopup);
            }}
          >
            <div
              className="currentTextColourOption"
              alt="choose font colour"
              style={{ backgroundColor: props.selectedFontColor }}
            />
          </div>

          {/* React-Select for Fonts */}
          <Select
            value={fonts.find((f) => f.value === selectedFont)}
            onChange={handleSelectChange}
            options={fonts}
            placeholder="Choose font..."
            styles={customStyles}
            isClearable={false} 
          />

          {/* Font Size Selector */}
          <input
            className="fontSizeSelector"
            type="text"
            value={props.selectedFontSize}
            onChange={(e) => props.setFontSize(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
