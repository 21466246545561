// src/ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ user, userRole, children, requiredRole, authLoading }) => {
  if (authLoading) {
    // Optionally, render a loading spinner or placeholder
    return null; // Or <LoadingSpinner /> if you have one
  }

  if (!user) {
    // If not logged in, redirect to login page
    return <Navigate to="/" replace />;
  }

  if (requiredRole && userRole !== requiredRole) {
    // If user doesn't have the required role, redirect to unauthorized page
    return <Navigate to="/unauthorized" replace />;
  }

  // If user is authorized, render the child component
  return children;
};

export default ProtectedRoute;
