// src/components/Favourites.jsx

import { useContext, useState, useEffect, useRef } from "react";
import { Link, Outlet } from "react-router-dom";

import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, arrayUnion, arrayRemove, query, 
  where, onSnapshot 
} from '@firebase/firestore';
import { auth, db, storage } from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import './Favourites.css';
import NotificationPopup from './NotificationPopup';
import Login from "../login/Login";
import MarketProductCard from "../market/MarketProductCard";
import MarketStoreLogo from "../market/MarketStoreLogo";
import MarketStorePopup from "../market/MarketStorePopup";

import backArrow from '../icons/backArrow.png';
import loadIcon from '../icons/scoutIcon.png';
import notificationIcon from '../icons/notificationIcon.png';

const Favourites = (props) => {
  const loginPopupRef = useRef();
  const [userID, setUserID] = useState(null);
  const [showInfo1, setShowInfo1] = useState(false);
  const [likedProductData, setLikedProductData] = useState([]);
  const [likedStores, setLikedStores] = useState([]);
  const favouritesProductsSectionRef = useRef();
  const [showLogin, setShowLogin] = useState(false);
  const [userLoggedOut, setUserLoggedOut] = useState(false);
  const [show, setShow] = useState(false);
  const [dependencies, setDependencies] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [notifications, setNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [popupClosed, setPopupClosed] = useState(false);

  useEffect(() => {
    document.title = 'Favourites | taizte™';
  }, []);

  // Move onAuthStateChanged inside useEffect
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user.uid);
        setUserLoggedOut(false);
      } else {
        setUserLoggedOut(true);
        setShowLogin(true);
        setShowInfo1(true);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (favouritesProductsSectionRef.current) {
      // Check the length of likedProductData
      if (likedProductData.length === 2) {
        favouritesProductsSectionRef.current.style.display = 'grid';
        favouritesProductsSectionRef.current.style.gridTemplateColumns = 'repeat(auto-fill, minmax(150px, 1fr))';
      } else {
        favouritesProductsSectionRef.current.style.display = '';
        favouritesProductsSectionRef.current.style.flexWrap = '';
      }
    }
  }, [likedProductData]);

  const onLogoClick = (logo, category, description, store, storeID, index) => {
    setShow(true);
    setDependencies({ logo, category, description, store, storeID, index });
  };

  const closeMarketStorePopup = () => {
    setShow(false);
    setDependencies(null);
  };

  useEffect(() => {
    if (!userID) return; // Make sure userID is not undefined
    getLikedProducts();
  }, [userID]); // Add userID as a dependency

  const getLikedProducts = async () => {
    try {
      const docRef = doc(db, 'favourites', userID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setLikedProductData(data.liked_products || []); // Provide default empty array
        setLikedStores(data.liked_stores || []); // Provide default empty array
      } else {
        // Handle the case where the document does not exist
        console.log("No such document!");
        setLikedProductData([]); // Set to empty array
        setLikedStores([]); // Set to empty array
      }
    } catch (error) {
      console.error("Error getting document:", error);
      // Optionally, set error state here to inform the user
    } finally {
      setIsLoading(false); // Ensure loading is stopped in both cases
    }
  };

  const handleDeleteProduct = (productId) => {
    // Filter out the product that matches the productId
    const updatedProducts = likedProductData.filter(product => product.productID !== productId);
    setLikedProductData(updatedProducts);
  };
  
  const togglePopup = () => {
    setShowNotifications(!showNotifications);
    setPopupClosed(!popupClosed); // Set this to true when the popup is closed
  };

  useEffect(() => {
    if (!userID) return; // Make sure userID is not undefined
    getNotifications();
    console.log(notifications);
  }, [userID]); // Add userID as a dependency

  const getNotifications = async () => {
    try {
      const q = query(
        collection(db, "notifications"), 
        where("userID", "==", userID), 
        where("notificationType", "==", "sale")
      );
      const querySnapshot = await getDocs(q);
      let fetchedNotifications = [];

      querySnapshot.forEach((doc) => {
        let notification = doc.data();
        notification.id = doc.id; // Add document ID to the notification object
        fetchedNotifications.push(notification);
      });

      // Sort notifications by time
      fetchedNotifications.sort((a, b) => b.messageDateNumeric - a.messageDateNumeric);

      // Update notifications state
      setNotifications(fetchedNotifications);

      // Filter and set read and unread notifications
      setReadNotifications(fetchedNotifications.filter(n => n.readStatus === 'read'));
      setUnreadNotifications(fetchedNotifications.filter(n => n.readStatus === 'unread'));
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };  

  const markNotificationAsRead = async (notificationId) => {
    try {
      // Update the readStatus in Firebase
      const notificationRef = doc(db, "notifications", notificationId);
      await updateDoc(notificationRef, {
        readStatus: 'read'
      });

      // Update local state
      // Create a new array with the updated readStatus
      const updatedNotifications = notifications.map(notification => {
        if (notification.id === notificationId) {
          return { ...notification, readStatus: 'read' };
        }
        return notification;
      });

      // Update the notifications state
      setNotifications(updatedNotifications);

      // Update read and unread notifications states
      setReadNotifications(updatedNotifications.filter(n => n.readStatus === 'read'));
      setUnreadNotifications(updatedNotifications.filter(n => n.readStatus === 'unread'));
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  return (
    <div>
      <style>{'body { background-color: #EA999C; }' }</style>
      <div className="favouritesHeader">
        <div className="favouritesHeaderContainer1">
          <Link to="/">
            <div className="favouritesBack">
              <img src={backArrow} className="favouritesBackIcon" alt="Back" />
            </div>
          </Link>
          <div className='favouritesHeadingDiv'>
            <h1 className='favouritesHeadingText'>favourites.</h1>
          </div>
        </div>
        <div className="favouritesNotificationIconContainer" style={{ position: 'relative' }}>
          <img 
            src={notificationIcon} 
            className="favouritesNotificationIcon" 
            onClick={togglePopup} 
            alt="Notifications" 
          />
          {unreadNotifications.length > 0 && (
            <span className="favouritesNotificationBadge">
              {unreadNotifications.length}
            </span>
          )}
        </div>
      </div>
      
      {showNotifications && 
        <NotificationPopup 
          userID={userID}
          markNotificationAsRead={markNotificationAsRead}
          notifications={notifications}
          unreadNotifications={unreadNotifications}
          readNotifications={readNotifications}
        />
      }

      {isLoading ? (
        <div className="favouritesLoadingDiv">
          <img src={loadIcon} className="favouritesLoadingIcon" alt="Loading..." />
        </div>
      ) : (
        <div className="favouritesAssests">
          <div className="favouritesStoresSection">
            <div className="favouritesStoreHeading">stores.</div>
            {likedStores.length > 0 ? (
              likedStores.map((store, index) => (
                <MarketStoreLogo
                  onLogoClick={onLogoClick}
                  showLoginPopup={() => { setShowLogin(true); }}
                  userLoggedOut={userLoggedOut}
                  key={index}
                  show={show}
                  logo={store.store_logo}
                  category={store.industry}
                  description={store.store_description}
                  store={store.store}
                  storeID={store.storeID}
                />
              ))
            ) : (
              <div className="emptyFavouritesStoresDiv" key="no-stores">
                You have not liked any stores yet.
              </div>
            )}
            {show && dependencies && (
              <MarketStorePopup
                showLoginPopup={() => { setShowLogin(true); }}
                userLoggedOut={userLoggedOut}
                show={show}
                onClickOutside={() => { setShow(false); }}
                closePopup={closeMarketStorePopup}
                logo={dependencies.logo}
                category={dependencies.category}
                description={dependencies.description}
                store={dependencies.store}
                storeID={dependencies.storeID}
                key={`popup-${dependencies.index}`}
              />
            )}
            <div className="favouritesStoresSectionShadow"></div>
          </div>
          
          {/* change this to being scoutCard */}
          <div className="favouritesProductsSection" ref={favouritesProductsSectionRef}>
            {likedProductData.length > 0 ? (
              likedProductData.map((product, index) => (
                <MarketProductCard 
                  key={index}
                  data={product}
                  productID={product.productID}
                  productImage={product.image}
                  productPrice={product.listed_price}
                  productTitle={product.productName}
                  store={product.store}
                  productDescription={product.description}
                  productCategory={product.category}
                  onDeleteProduct={handleDeleteProduct}
                />
              ))
            ) : (
              <div className="emptyFavouritesDiv" key="no-products">
                You haven't liked any products yet.
                {/* You can add a broken heart image/animation here */}
              </div>
            )}
          </div>
        </div>
      )}

      {showLogin && 
        <div ref={loginPopupRef} className="favouritesLogin">
          <div className="favouritesLoginDiv">
            <p className="favouritesLoginTextDiv">
              To <strong>access your favourites</strong>, login or create an account.
            </p>
            <Login 
              show={showInfo1} 
              onClickOutside={() => { setShowInfo1(false); }}
            />
          </div>
        </div>
      }
      
      <Outlet />
    </div>
  );
}

export default Favourites;
