import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from './frameStyles.json'
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDragControls, motion } from "framer-motion"
import { Resizable } from 'react-resizable';
import debounce from 'lodash.debounce'
import { 
  ref, getDownloadURL, getStorage, listAll, 
  uploadBytesResumable, deleteObject } 
from 'firebase/storage'
import { auth, db, storage } from '../../firebase'
import { 
  collection, doc, updateDoc, setDoc, 
  getDoc, onSnapshot, deleteDoc } 
from '@firebase/firestore'
import { onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4} from "uuid";
import FontFaceObserver from 'fontfaceobserver';

import plusIcon from '../../icons/plusIcon.png'
import checkIcon from '../../icons/checkIcon.png'
import optionsIcon from '../../icons/optionsIcon.png'
import ElementBar from "./ElementBar"
import TextPopup from './TextPopup'
import TextPopupPortal from './TextPopupPortal'
import './Frames.css'
import './Fonts.css'
import ReorderFrame from "./ReorderFrame";
import WebsiteBackgroundPopup from "./WebsiteBackgroundPopup";

const Frames = (props) => {
  const frameBoundaryRef = useRef()
  const isDraggingRef = useRef(false)
  const imageRef = useRef()
  const frameRef = useRef()
  const textColourPopupRef = useRef();
  const framePlusIconRef = useRef()
  const frameTextAreaRef = useRef()
  const resizeHandlerRef = useRef();
  const blurOverlayRef = useRef(null);

  const dragControls = useDragControls()
  const [frameBoundaryRect, setFrameBoundaryRect] = useState(null)
  const [resizeStartY, setResizeStartY] = useState(null)
  let [showInfo1, setShowInfo1] = useState(false)
  const [frameImg, setFrameImg] = useState()
  const [zIndex, setZIndex] = useState(props.zIndex)
  const [isImageFrame, setIsImageFrame] = useState(false)
  const [isTextArea, setIsTextArea] = useState(false)
  const [showTextAreaOptions, setShowTextAreaOptions] = useState(false)
  const [text, setText] = useState('')
  const [selectedTextAreaFont, setSelectedTextAreaFont] = useState('Poppins')
  const [fontFullName, setFontFullName] = useState('Poppins')
  const [textAlign, setTextAlign] = useState('left')
  const [selectedFontSize, setSelectedFontSize] = useState(16)

  const isDarkBackground = (color) => {
    if (!color) return false;
  
    // Remove '#' if present
    if (color.startsWith('#')) {
      color = color.slice(1);
    }
  
    let r, g, b;
  
    if (color.length === 3) {
      r = parseInt(color[0] + color[0], 16);
      g = parseInt(color[1] + color[1], 16);
      b = parseInt(color[2] + color[2], 16);
    } else if (color.length === 6) {
      r = parseInt(color.slice(0, 2), 16);
      g = parseInt(color.slice(2, 4), 16);
      b = parseInt(color.slice(4, 6), 16);
    } else {
      // Unsupported color format
      return false;
    }
  
    // Calculate brightness
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  
    return brightness < 128; // Returns true if dark
  };
  
  useEffect(() => {
    if (isDarkBackground(props.websiteColor)) {
      setSelectedFontColor('#000000');
    } else {
      setSelectedFontColor('#FFFFFF');
    }
  }, [props.websiteColor])

  const [selectedFontColor, setSelectedFontColor] = useState(isDarkBackground(props.websiteColor) ? '#000000' : '#FFFFFF')
  let [showTextPopup, setShowTextPopup] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [frameDesign, setFrameDesign] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [frameRefStyle, setFrameRefStyle] = useState(props.currentFrame.frame_styling != null ? props.currentFrame.frame_styling : null)
  const [frameHeight, setFrameHeight] = useState(0); 
  const [isFrameActive, setIsFrameActive] = useState(false);
  const [showImageDonePopup, setShowImageDonePopup] = useState(false)
  const [refreshKey, setRefreshKey] = useState(0);
  const [fetchedFrameData, setFetchedFrameData] = useState();
  const [isFrameRefMounted, setIsFrameRefMounted] = useState(false)
  const [isFontLoaded, setIsFontLoaded] = useState(false)
  const [isResizing, setIsResizing] = useState(false);
  const [changeToText, setChangeToText] = useState(false)
  const [showImagePopup, setShowImagePopup] = useState(false);
  const marginTop = props.frameStyling?.marginTop || 0;
  const marginBottom = props.frameStyling?.marginBottom || 0;
  const [showTextColorPopup, setShowTextColorPopup] = useState(false)
  const [previewColor, setPreviewColor] = useState(null)
  const [showCommonColors, setShowCommonColors] = useState(false); // New state for collapsible div
  const [colorPickerOpen, setColorPickerOpen] = useState(false)
  
  const [frameWidth, setFrameWidth] = useState(() => {
    if (props.stretchFrame) {
      return window.innerWidth * 0.95; // 95% if not stretched
    }
    return window.innerWidth; // 100% if stretched
  });

  useEffect(() => {
    if (showTextPopup && props.index === 0) {
      const frameBoundary = frameBoundaryRef.current;
      const blurOverlay = blurOverlayRef.current;
      if (frameBoundary && blurOverlay) {
        const rect = frameBoundary.getBoundingClientRect();
        blurOverlay.style.top = `${rect.top}px`;
        blurOverlay.style.left = `${rect.left}px`;
        blurOverlay.style.width = `${rect.width}px`;
        blurOverlay.style.height = `${rect.height}px`;
      }
    }
  }, [showTextPopup, props.index])

  useEffect(() => {
    const updatePosition = () => {
      if (showTextPopup && frameBoundaryRef.current) {
        const rect = frameBoundaryRef.current.getBoundingClientRect();
        setFrameBoundaryRect(rect);
      } else {
        setFrameBoundaryRect(null);
      }
    };

    updatePosition(); // Initial calculation

    // Update position on resize and scroll
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    // Cleanup
    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [showTextPopup])

  // Effect to check if frameRef is mounted
  useEffect(() => {
    setIsFrameRefMounted(frameRef.current != null);
  }, [frameRef.current]);

  useEffect(() => {
    if (props.selectedObjectID) {
      // console.log("Here is the frameID and selectedObjectID: ", props.frameID, props.selectedObjectID)
      if (props.frameID !== props.selectedObjectID) {
        // console.log("There is no match")
        setIsClicked(false); // Assuming this removes the selected styles
        setIsFrameActive(false);
        setShowTextPopup(false)
        setShowTextAreaOptions(false)
        setShowImageDonePopup(false)
        setZIndex(props.zIndex); // Reset zIndex to its original value
      }
    }
  }, [props.selectedObjectID, props.frameID, props.zIndex]);

  useEffect(() => {
    if (isFrameRefMounted && !props.websiteView) {
      if (frameRef.current != null) {
        const style = styles[props.frameStyle]
        console.log(props)
        const width = frameRef.current.offsetWidth; // Get the actual width
        props.setFrameWidth(width)
        const aspectRatio = style.aspectRatio;

        if (aspectRatio) {
          const [numerator, denominator] = aspectRatio.split('/').map(Number);
          if (denominator) {
            const switchedRatio = denominator / numerator; // Switch the ratio from 2/3 to 3/2
            const height = width * switchedRatio; 
            setFrameHeight(height); 
          } else {
            // console.error("Invalid aspect ratio format");
          }
        } else {
          console.log("There is an associated height")
        }
        
      }
    }
  }, [isFrameRefMounted])

  // Utility function to darken a color by a given percentage (0 to 1)
  const darkenColor = (color, amount) => {
    let r, g, b;

    if (color.startsWith('#')) {
      // Handle hex colors
      if (color.length === 4) { // #RGB
        r = parseInt(color[1] + color[1], 16);
        g = parseInt(color[2] + color[2], 16);
        b = parseInt(color[3] + color[3], 16);
      } else if (color.length === 7) { // #RRGGBB
        r = parseInt(color.slice(1, 3), 16);
        g = parseInt(color.slice(3, 5), 16);
        b = parseInt(color.slice(5, 7), 16);
      } else {
        // Unsupported hex format
        return color;
      }

      // Darken each channel
      r = Math.max(0, Math.floor(r * (1 - amount)));
      g = Math.max(0, Math.floor(g * (1 - amount)));
      b = Math.max(0, Math.floor(b * (1 - amount)));

      return `rgb(${r}, ${g}, ${b})`;
    } else if (color.startsWith('rgb')) {
      // Handle rgb or rgba
      const parts = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/);
      if (parts) {
        r = Math.max(0, Math.floor(parseInt(parts[1]) * (1 - amount)));
        g = Math.max(0, Math.floor(parseInt(parts[2]) * (1 - amount)));
        b = Math.max(0, Math.floor(parseInt(parts[3]) * (1 - amount)));
        return `rgb(${r}, ${g}, ${b})`;
      }
      // If regex fails, return original color
      return color;
    }

    // If color format is not supported, return as is
    return color;
  };

  
  const forceRefresh = () => {
    setRefreshKey(prevKey => prevKey + 1); // Increment the key to trigger a re-render
  };

  useEffect(() => {
    if (props.frames && props.frameID) {
      // console.log(props.frames, props.frameID)
      const matchingFrame = props.frames.find(frame => frame.frameID === props.frameID);
      
      if (matchingFrame == undefined) {
        return
      }
      // console.log(matchingFrame)
      setFetchedFrameData(matchingFrame)
      setTextAreaStyling(matchingFrame)
    }
  }, [props.frameID, props.frames, props.selectedObject, refreshKey])

  const dragPosition = useRef({ x: 0, y: 0 });

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "frame",
    item: props.websiteView ? null : { type: 'frame', id: props.frameID, index: props.index },
    collect: (monitor) => {
      if (props.websiteView) return { isDragging: false };
      
      const clientOffset = monitor.getClientOffset();
      
      if (isResizing || document.getSelection().toString() !== '') {
        return { isDragging: false };
      }
      if (clientOffset) {
        dragPosition.current = clientOffset;
      }
      
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end: () => {
      if (!props.websiteView) {
        clearInterval(scrollInterval.current);
      }
    }
  }), [props.websiteView, isResizing, props.frameID, props.index]);

  useEffect(() => {
    isDraggingRef.current = isDragging;
  }, [isDragging])

  useEffect(() => {
    const handleMouseMove = debounce((e) => {
      if (isDraggingRef.current) {
        const { clientX, clientY } = e;

        dragPosition.current = { x: clientX, y: clientY };
      }
    }, 0)
  
    document.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  
  useEffect(() => {
    if (!isDragging) {
      // Reset position when dragging ends
      const frameElement = frameRef.current;
      if (frameElement) {
        frameElement.style.position = 'relative';
        frameElement.style.left = '0';
        frameElement.style.top = '0';
        frameElement.style.transform = 'none';
        frameElement.style.zIndex = 'auto';
      }
    }
  }, [isDragging]);
  
  const scrollInterval = useRef(null);
  
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  
  useEffect(() => {
    if (isDragging) {
      document.body.style.cursor = 'grabbing';
    } else {
      document.body.style.cursor = 'auto';
    }
  }, [isDragging])

  useEffect(() => {
    if (isDragging) {
      scrollInterval.current = setInterval(() => {
        const { y } = dragPosition.current; 
        const { innerHeight } = window;
        
        // Scroll down
        if (y >= innerHeight - 120) {
          window.scrollBy(0, 10); 
        }
  
        // Scroll up
        if (y <= 120) {
          window.scrollBy(0, -10); 
        }
      }, 100); 
    } else {
      clearInterval(scrollInterval.current);
    }
  }, [isDragging]);

  const [, drop] = useDrop({
    accept: "frame",
    hover(item, monitor) {
      console.log('Hovered over:', props.frameID)
      const currentRef = isImageFrame ? imageRef.current : frameRef.current;
  
      const dragIndex = item.index;
      const hoverIndex = props.index;
      
      if (dragIndex === hoverIndex) return; // No reorder needed if the indices are the same
    
      const hoverBoundingRect = currentRef.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 4; // More sensitive threshold (1/4 of the height)
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      const isHoveringAboveThreshold = clientOffset.y < hoverBoundingRect.top + hoverMiddleY;
      const isHoveringBelowThreshold = clientOffset.y > hoverBoundingRect.bottom - hoverMiddleY;
    
      console.log('targetId:', item.id, "current frame being dragged ID: ", props.frameID); // Log the targetId prop
    
      // More responsive conditions for swapping the frame positions
      if ((dragIndex < hoverIndex && hoverClientY > hoverMiddleY) ||
          (dragIndex > hoverIndex && hoverClientY < hoverMiddleY)) {
        props.moveFrame(dragIndex, hoverIndex, props.frameID);
        item.index = hoverIndex;
      }
    },
    drop(item, monitor) {
      console.log('Monitor:', monitor)
      console.log('Dropped on:', props.frameID);
    }
  });

  const combineRefs = (...refs) => {
    return (node) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref != null) {
          ref.current = node;
        }
      });
    };
  }
  
  const setTextAreaStyling = ((data) => {
    // console.log(data)
    const frameData = data
    const frameFont = frameData.font;
    const frameFontSize = frameData.font_size;
    const frameFontFullName = frameData.font_fullname;
    const frameFontColor = frameData.font_color;
    const fetchedFrameDesign = frameData.frame_design;
    const frameStyles = frameData.frame_styling

    if (frameStyles) {
      setFrameRefStyle(frameStyles);
    } else {
      // console.log("No frame_styling set")    
    }

    const frameHeightValue = frameData.frame_styling?.height?.replace('px', '');
    if (frameHeightValue) {
      setFrameHeight(parseInt(frameHeightValue, 10));
    }
  
    // Only proceed if there is text to set
    if (fetchedFrameDesign != null || frameData.text != null) {
      setIsTextArea(true);
      setFrameDesign(fetchedFrameDesign);
      setSelectedTextAreaFont(frameFont);
      setFontFullName(frameFontFullName);
      setSelectedFontColor(frameFontColor);
      setSelectedFontSize(frameFontSize);

      const fontObserver = new FontFaceObserver(frameFont);

      // Start a timer for font loading
      const fontLoadTimer = setTimeout(() => {
        // console.log('Taking some time to fetch the font...');
      }, 3000); // 3 seconds threshold

      fontObserver.load(null, 10000)
        .then(() => {
          clearTimeout(fontLoadTimer); // Clear timer if font loads within threshold

          // Check if frameTextAreaRef.current is defined
          if (frameTextAreaRef.current) {
            frameTextAreaRef.current.style.fontFamily = `${frameFont}`;
            frameTextAreaRef.current.style.fontSize = `${frameFontSize}px`;
            frameTextAreaRef.current.style.color = `${frameFontColor}`;
            frameTextAreaRef.current.style.textAlign = frameStyles?.textAlign; // Also check for frameStyles
            setTextAlign(frameStyles?.textAlign)
            setText(frameData.text); // Set text here after font is loaded
          }

          setIsLoading(false); // Font is loaded and applied
          props.onFramesLoaded()
        }).catch(() => {
          console.error('Font loading failed for:', frameFont);
          clearTimeout(fontLoadTimer); // Clear timer even if font fails to load
          setIsLoading(false); // Proceed with fallback font or error handling
          // Optionally set text here if you want it to appear even if font fails
          props.onFramesLoaded()
        });
    }
  })

  useEffect(() => {
    updateFrameBorder();
  }, [isFrameActive, isClicked, props.websiteColor, frameRefStyle]);  

  useEffect(() => {
    // console.log('Current frame style:', props.frameStyle);
    // console.log('Available styles:', styles);
    if (props.frameStyle && styles[props.frameStyle]) {
      const style = styles[props.frameStyle];
      // console.log('Applied style:', style);
      if (style && style.height) {
        const initialHeight = parseInt(style.height, 10)
        setFrameHeight(initialHeight);
      }
    }
  }, [props.frameStyle]);

  // console.log(props)
  
  const updateHeight = (newHeight) => {
    setFrameHeight(newHeight);
  
    // Find the current frame
    const currentFrame = props.frames.find(frame => frame.frameID === props.frameID);
  
    // If the current frame is found
    if (currentFrame) {
      // Prepare the updated frame styling object with the new height
      const updatedFrameStyling = { 
        ...currentFrame.frame_styling, 
        height: `${newHeight}px`
      };
  
      // Update frame styling in the state
      setFrameRefStyle(updatedFrameStyling);
  
      // Update the current frame with new styling and text
      const updatedFrame = {
        ...currentFrame,
        frame_styling: updatedFrameStyling,
        text: text // Assuming 'text' is the current state of the text in the textarea
      };
  
      // Update the frames array with the updated current frame
      const updatedData = props.frames.map(frame => 
        frame.frameID === props.frameID ? updatedFrame : frame
      );
  
      // Update frames in the parent component
      props.updateFrames(updatedData, updatedFrameStyling);
    }
  };

  useEffect(() => {
    const handleFrameResize = () => {
      if (props.stretchFrame) {
        setFrameWidth(window.innerWidth);
      } else {
        setFrameWidth(window.innerWidth * 0.95);
      }
    };
  
    handleFrameResize(); // Set initial width
  
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.stretchFrame]);
  
  
  const handleResize = (event, { size, handle }) => {
    props.setShowImageFrameBar(true)
    if (handle) {
      // console.log("dragging")
      setIsResizing(true)
      props.setIsResizing(true)
    }
    updateHeight(size.height);
  };
  
  // Function to reset resizing state when resizing ends
  const onResizeStop = () => {
    setIsResizing(false);
    props.setIsResizing(false)
    setIsFrameActive(true)
    props.setShowImageFrameBar(true)
  };
  
  // Handlers for mouse events
  const handleMouseEnter = () => {
    if (!props.websiteView) {
      setIsHovered(true);
    }
  };
  
  const handleMouseLeave = () => {
    if (!props.websiteView) {
      setIsHovered(false);
    }
  };
  
  const handleClick = (frameID, zIndex, frameType) => {
    setIsClicked(true);
    props.setFrameSelected(true)
    props.setDraggableFrameSelected(false)
    setIsFrameActive(true)
    updateFrameBorder()

    if (isImageFrame) {
      // console.log("Hiding the frame bar")
      props.setShowFrameBar(false)
      props.setShowImageFrameBar(true)
    } else {
      props.setShowFrameBar(true)
      console.log('show frame bar')
    }

    if (frameType === 'imageFrame') {
      setShowImageDonePopup(true)
      props.handleFrameSelected(frameID, zIndex, null, "imageFrame");
      return;
    }
  
    if (frameRef.current) {
      const computedStyles = window.getComputedStyle(frameRef.current);
      let frameStyle = {};
  
      // Convert the CSSStyleDeclaration object to a plain object
      for (let key of computedStyles) {
        frameStyle[key] = computedStyles.getPropertyValue(key);
      }
  
      // Pass the frame styling to the handleFrameSelected function
      props.handleFrameSelected(frameID, zIndex, frameStyle, "textFrame");
    }
  };

  const updateFrameBorder = () => {
    if (frameRef.current) {
      let borderColor = 'rgba(0, 0, 0, 0.8)'; // Default border color
      if (isDarkBackground(props.websiteColor)) {
        borderColor = frameRefStyle?.backgroundColor === '#FFFFFF' ? 'orangered' : 'rgb(255, 255, 255)';
      }
  
      if (isFrameActive || isClicked) {
        frameRef.current.style.border = `dashed 3px ${borderColor}`;
      } else {
        const originalBorderStyle = frameRefStyle && frameRefStyle.border ? frameRefStyle.border : 'none';
        frameRef.current.style.border = originalBorderStyle;
      }
    }
  };
  
  
  const isFrameEmpty = () => {
    // Find the current frame's styling from the frames array
    const currentFrameStyling = props.frames.find(frame => frame.frameID === props.frameID)?.frame_styling;
    const currentFrameText = props.frames.find(frame => frame.frameID === props.frameID)?.text;

    // Check if text is empty
    const isTextEmpty = !currentFrameText || currentFrameText === null || currentFrameText === '';   
  
    // Check if the frame is an image frame
    const hasImage = isImageFrame;
  
    // Check if frame has border or background color
    const hasBorderOrBackground = currentFrameStyling && 
      (currentFrameStyling.border || currentFrameStyling.backgroundColor);
  
    // Frame is considered empty if it has no text, no image, and no border/background color
    return isTextEmpty && !hasImage && !hasBorderOrBackground;
  };
  
  // Function to get dynamic style for the frame
  const getDynamicFrameStyle = () => {
    const baseStyle = { ...frameRefStyle, zIndex: props.zIndex };

    // Apply dashed border if frame is empty
    if (isFrameEmpty()) {
      return {
        ...baseStyle,
        border: (isDarkBackground(props.websiteColor)) ? '2px dashed rgba(128, 128, 128, 0.8)' : '2px dashed rgba(0, 0, 0, 0.8)'  // Example dashed border style
      };
    }

    // If frame is not empty, return the base style
    return baseStyle;
  };

  useEffect(() => {
    getFrameStyle()
  }, [props.websiteColor])

  const getFrameStyle = () => {
    const baseStyle = {
      ...getDynamicFrameStyle(),
      zIndex: props.frameSelected ? 1000 : props.zIndex,
      width: '100%',
      height: `${frameHeight}px`,
    };
  
    // Only apply hover styles if not in website view
    if (!props.websiteView && (isHovered || isClicked)) {
      return {
        ...baseStyle,
        ...liftedStyle(frameRefStyle),
      };
    }
  
    return baseStyle;
  };
  
  
  const onTextPopupClick = () => {
    // Set isClicked to true
    setIsClicked(true);
  }

  useEffect(() => {
    if (props.frameStyling) {
      // console.log("The props.frameStyling: ", props.frameStyling)
      setFrameRefStyle(props.frameStyling);
  
      // Apply styling to frameRef and/or other elements
      if (frameRef.current) {
        Object.keys(props.frameStyling).forEach(styleKey => {
          frameRef.current.style[styleKey] = props.frameStyling[styleKey];
        });
      }
  
      // Apply text styling if necessary
      if (frameTextAreaRef.current && props.frameStyling.textAlign) {
        setTextAlign(props.frameStyling.textAlign)
        frameTextAreaRef.current.style.textAlign = props.frameStyling.textAlign;
      }
  
      // console.log("Frame styling has been updated, ", props.frameStyling);
    }
  }, [props.frameStyling]);

  function useOutsideClick(refs, outsideClickCallback, websiteView) {
    useEffect(() => {
      if (websiteView) return;
  
      const handleClickOutside = (event) => {
        console.log("Click detected:", event.target);
        for (let ref of refs) {
          if (ref.current && ref.current.contains(event.target)) {
            console.log("Click inside:", ref.current);
            return;
          }
        }
        console.log("Click outside");
        outsideClickCallback();
      };
      
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [refs, outsideClickCallback, websiteView]); // Ensure dependencies are correct
  }
  
  const frameOutsideClick = () => {
    setIsClicked(false);
    setIsHovered(false); // Ensure hovered state is reset
    setIsFrameActive(false);
    updateFrameBorder();
    setShowTextPopup(false);
    props.closeFrameBar(false);
    setShowTextAreaOptions(false);
    setZIndex(props.zIndex);
  };
  

  const imageOutsideClick = () => {
    setIsClicked(false); // Assuming this removes the imageLiftedStyle
    setIsFrameActive(false)
    // props.closeFrameBar(false)
    props.setShowImageFrameBar(false)
    setZIndex(props.zIndex)
    // console.log("image frame is no longer selected")
  };

  // Use the custom hook
  useOutsideClick(
    [
      frameRef,
      imageRef,
      props.textPopupRef,
      textColourPopupRef,
      props.vendorBarRef,
      props.frameDynamicsRef,
      props.frameBarRef,
      props.imageFrameBarRef,
      props.frameBorderPopupRef,
      props.frameTypesPopupRef,
      resizeHandlerRef,
      props.opacityPopupRef,
      props.doneBtnDivRef,
      props.elementBarRef,
    ],
    () => {
      frameOutsideClick();
      imageOutsideClick()
      props.setShowZIndexPopup(false);
      setShowTextColorPopup(false);
      props.showLightenSticker(false);
      // Include any additional state resets or callbacks here
    },
    props.websiteView
  );
  

  useEffect(() => {
    if (props.liftedStyle == true) {
      // Sticker is selected and thus the liftedStyle should be removed 
      // - to prevent frames from overlaying it
      setIsClicked(false)
      setIsHovered(false)
    } 
  })

  const textIconClicked = () => {
    setShowTextPopup(true)
    props.showLightenSticker(true)
    setIsTextArea(true)
    setShowTextAreaOptions(true)
  }

  const removeTextPopup = () => {
    setShowTextPopup(false)
    props.showLightenSticker(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchTextFrame(), fetchFrameImage()]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false); // Optionally set loading to false even on error
      }
    };
  
    fetchData();
  }, []); 
  

  const fetchFrameImage = async() => {
    const dbRef = doc(db, 'frames', props.frameID);
    const docSnap = await getDoc(dbRef);
    if (docSnap.exists()) {
      const frameData = docSnap.data();
      console.log(frameData)
      const frameImageLink = frameData.frame_image;
      console.log("frame Image ", frameImageLink)
      const frameHeightValue = frameData.frame_styling?.height?.replace('px', '');
  
      if (frameImageLink) {
        setIsImageFrame(true);
        setFrameImg(frameImageLink);
      } else {
        setIsImageFrame(false);
      }
  
      if (frameHeightValue) {
        setFrameHeight(parseInt(frameHeightValue, 10));
      }
    }
  };
  

  const fetchTextFrame = async () => {
    setIsLoading(true);
  
    const dbRef = doc(db, 'frames', props.frameID);
    const docSnap = await getDoc(dbRef);
    if (docSnap.exists()) {
      const frameData = docSnap.data();
      console.log(frameData)
      setFetchedFrameData(frameData)
      const frameFont = frameData.font;
      const frameFontSize = frameData.font_size;
      const frameFontFullName = frameData.font_fullname;
      const frameFontColor = frameData.font_color;
      const fetchedFrameDesign = frameData.frame_design;
      const frameStyles = frameData.frame_styling

      if (frameStyles) {
        setFrameRefStyle(frameStyles);
      } else {
        // Handle the case where frame_styling is not available
        // For example, set a default style or take other appropriate actions
      }

      const frameHeightValue = frameData.frame_styling?.height?.replace('px', '');
      if (frameHeightValue) {
        setFrameHeight(parseInt(frameHeightValue, 10));
      }
  
      // Only proceed if there is text to set
      if (fetchedFrameDesign != null || frameData.text != null) {
        setIsTextArea(true);
        setFrameDesign(fetchedFrameDesign);
        setSelectedTextAreaFont(frameFont);
        setFontFullName(frameFontFullName);
        setSelectedFontColor(frameFontColor);
        setSelectedFontSize(frameFontSize);

        const fontObserver = new FontFaceObserver(frameFont);

        // Start a timer for font loading
        const fontLoadTimer = setTimeout(() => {
          // console.log('Taking some time to fetch the font...');
        }, 3000); // 3 seconds threshold

        fontObserver.load(null, 15000)
          .then(() => {
            clearTimeout(fontLoadTimer); // Clear timer if font loads within threshold

            // Check if frameTextAreaRef.current is defined
            if (frameTextAreaRef.current) {
              frameTextAreaRef.current.style.fontFamily = `${frameFont}`;
              frameTextAreaRef.current.style.fontSize = `${frameFontSize}px`;
              frameTextAreaRef.current.style.color = `${frameFontColor}`;
              frameTextAreaRef.current.style.textAlign = frameStyles?.textAlign; // Also check for frameStyles
              setTextAlign(frameStyles?.textAlign)
              setText(frameData.text); // Set text here after font is loaded
            }

            setIsFontLoaded(true)
            props.onFramesLoaded()
          }).catch(() => {
            console.error('Font loading failed for:', frameFont);
            clearTimeout(fontLoadTimer); // Clear timer even if font fails to load
            setIsFontLoaded(false)
            props.onFramesLoaded()
          });
      }
      
    } else {
      setIsTextArea(false);
      setIsLoading(false);
    }
  };
  
  const setFontSize = useCallback(async(fontSize) => {
    // console.log(fontFullName)
    frameTextAreaRef.current.style.fontSize = `${fontSize}px`
    frameTextAreaRef.current.style.fontFamily = `${fontFullName}`;
    setSelectedFontSize(fontSize)
    setFetchedFrameData(prevData => {
      return {
          ...prevData,
          font_size: fontSize,
          font_fullname: fontFullName
      };
    });

  })

  const selectedFont = useCallback(async(fontName) => {
    // console.log(fontName)

    if (fontName === 'Poppins') {
      frameTextAreaRef.current.style.fontFamily = 'Poppins'
      setFontFullName('Poppins')
      setSelectedTextAreaFont('Poppins')
    } else {
      const fontsFolderRef = ref(storage, `fonts/${fontName}`)
      getDownloadURL(fontsFolderRef)
      .then(function(url) {
        const strippedFontName = fontName.split('.')[0]
        // console.log(strippedFontName)
        // console.log(url)
        frameTextAreaRef.current.style.fontFamily = `${strippedFontName}`
        setSelectedTextAreaFont(strippedFontName)
        setFontFullName(fontName)
      })
      .catch((err) => {
        // console.log(err)
      })
    }
  })

  const getSelectedFontColor = useCallback(async(color) => {
    setSelectedFontColor(color)
    frameTextAreaRef.current.style.color = `${color}`
  })

  const addImageToFrame = useCallback(async(url, storeID, frameID) => {
    if (props.websiteView) return;

    setIsImageFrame(true)
    const resolution = "_1000x1000"
    function insertResolution(str,indexPos,str2){
      return str.substring(0, indexPos) + str2 + str.substring(indexPos);
    };
          
    let productImg = url
    const resizedImage = (insertResolution(productImg,productImg.lastIndexOf('?'),resolution));

    setFrameImg(url)

    const frameRef = doc(db, 'frames', frameID)
    try {
      updateDoc(frameRef, {
        frame_image: resizedImage,
        non_resized_frame_image: url,
      })
      // console.log("image added to frame")
    } catch(err) {
      // console.log(err)
    }

    const storeRef = doc(db, 'stores', storeID)

    getDoc(storeRef).then(docSnap => {
      if (docSnap.exists()) {
        const frames = docSnap.data().frames
        const currentFrameID = frameID

        if (frames) {
          const frameIndex = frames.findIndex(frame => frame.frameID === currentFrameID)

          const updatedFrame = {
            ...frames[frameIndex],
            frame_image: resizedImage,
            non_resized_frame_image: url,
          }

          const updatedFrames = frames.map((frame, index) => {
            if (index === frameIndex) {
              return updatedFrame;
            }
            return frame;
          })

          // console.log(updatedFrames)

          updateDoc(storeRef, {
            frames: updatedFrames,
          });
        } else {
          // console.log("no frames")
        }
      }
    })

  }, [frameImg, props.websiteView])

  const updateFrameStylingInFirebase = async () => {
    if (props.websiteView) return;

    props.setShowZIndexPopup(false)
    updateFrameBorder()
    setChangeToText(false)
    setShowImagePopup(false)
    // console.log(`Updating frame styling in Firebase for frame at index ${props.index}, height: ${frameHeight}`)
    if (isImageFrame) {
      setShowImageDonePopup(false)
      props.setShowImageFrameBar(false)
    } else {
      props.setFrameSelected(false)
      props.setShowFrameBar(false)
    }

    props.showLightenSticker(false)
    setIsClicked(false)
    setIsFrameActive(false)
    props.closeFrameBar(false)
    const frameRef = doc(db, 'frames', props.frameID);
    const storeRef = doc(db, 'stores', props.storeID);
  
    try {
      // Update frame document
      const frameDoc = await getDoc(frameRef);
      if (frameDoc.exists()) {
        const frameData = frameDoc.data();
        const updatedFrameStyling = {
          ...frameData.frame_styling,
          height: `${frameHeight}px`
        };
        setFrameRefStyle(updatedFrameStyling)
        await updateDoc(frameRef, {
          frame_styling: updatedFrameStyling,
        });
        // console.log("Frame styling updated in Firebase");
      }
  
      // Update store document
      const storeDoc = await getDoc(storeRef);
      if (storeDoc.exists()) {
        const storeData = storeDoc.data();
        const framesArray = storeData.frames;
  
        const frameIndex = framesArray.findIndex(frame => frame.frameID === props.frameID);
        if (frameIndex !== -1) {
          framesArray[frameIndex] = {
            ...framesArray[frameIndex],
            frame_styling: {
              ...framesArray[frameIndex].frame_styling,
              height: `${frameHeight}px`
            },
          };
  
          await updateDoc(storeRef, {
            frames: framesArray,
          });
          props.setFrameSelected(false)
          // console.log("Store's frame styling updated in Firebase");
        }
      }
    } catch (error) {
      // console.error("Error updating frame styling in Firebase:", error);
    }
  };

  const changeImageToTextFrame = () => {
    props.handleDeleteFunctionality(isImageFrame, frameImg, props.frameID, props.storeID )
    console.log("image deleted")
    setFrameImg('')
    setIsImageFrame(false)
    setIsTextArea(true)
    props.setShowImageFrameBar(false)
    props.setShowFrameBar(true)
    setShowImageDonePopup(false)
    setShowTextAreaOptions(true)
    setShowTextPopup(true)
  }

  const saveText = () => {
    if (props.websiteView) return;
    // console.log("Updated stuff ", props, frameHeight)
    if (isImageFrame) {
      changeImageToTextFrame()
    }

    const dbRef = doc(db, 'frames', props.frameID)
    let frameColorValue;
    
    if (frameDesign === 'color') {
      frameColorValue = props.selectedFrameColor ? props.selectedFrameColor : '#FFFFFF';
    } else {
      frameColorValue = null;
    }

    updateDoc(dbRef, {
      text: text || '', 
      font: selectedTextAreaFont || 'Poppins',
      font_size: selectedFontSize || 16,
      font_fullname: fontFullName || 'Poppins',
      font_color: selectedFontColor || '#000000',
      frame_color: frameColorValue,
      frame_design: frameDesign || 'none',
      non_resized_frame_image: '',
      frame_image: ''
    })

    const storeRef = doc(db, 'stores', props.storeID)
    getDoc(storeRef).then(docSnap => {
      if (docSnap.exists()) {
        const frames = docSnap.data().frames
        const currentFrameID = props.frameID

        // console.log(frameRefStyle)
        if (frames) {
          const frameIndex = frames.findIndex(frame => frame.frameID === currentFrameID)
          const updatedFrame = {
            ...frames[frameIndex],
            text: text || '',
            font: selectedTextAreaFont || 'Poppins',
            font_size: selectedFontSize || 16,
            font_fullname: fontFullName || 'Poppins',
            font_color: selectedFontColor || '#000000',
            frame_color: frameColorValue,
            frame_design: frameDesign || 'none',
            frame_image: '',
            non_resized_frame_image: '',
            frame_styling: frameRefStyle
          }

          const updatedFrames = frames.map((frame, index) => {
            if (index === frameIndex) {
              return updatedFrame;
            }
            return frame;
          })

          updateDoc(storeRef, {
            frames: updatedFrames,
          });

          props.updateFrames(updatedFrames, updatedFrame)

          // console.log("The text has been saved and the frames updated: ", updatedFrames)
        } else { 
          // console.log("no frames") 
        }
      }
    })

    if (frameRef.current && frameRefStyle) {
      Object.keys(frameRefStyle).forEach(key => {
        frameRef.current.style[key] = frameRefStyle[key];
      });
    }

    setShowTextAreaOptions(false)
    setShowTextPopup(false)
    props.showLightenSticker(false)
  }

  const saveBtnClick = () => {
    // console.log("Save button cicked")
    setIsFrameActive(false)
    setShowInfo1(false)
    saveTextAndFrameHeight(); 
    forceRefresh()
    setChangeToText(false)
    setShowImagePopup(false)
    setShowTextColorPopup(false)
    props.setShowZIndexPopup(false)
  }

  const saveTextAndFrameHeight = () => {
    saveText(); // Your existing function to save text
    updateFrameStylingInFirebase(); // New function to update frame height
    setZIndex(props.zIndex)
  };

  const imageIconClicked = () => {
    setIsTextArea(false)
  }

  const checkAndAdjustFrameHeight = () => {
    if (frameTextAreaRef.current) {
      const textArea = frameTextAreaRef.current;
      const requiredHeight = textArea.scrollHeight;
      // console.log("Height values: ", requiredHeight, frameHeight)
      if (requiredHeight > frameHeight) {
        // console.log("Automatically adjusting height")
        updateHeight(requiredHeight);
      }
    }
  };

  const liftedStyle = (frameStyling) => {
    let borderStyle = 'none';
  
    if (frameStyling) {
      borderStyle = frameStyling.border ? frameStyling.border : borderStyle;
  
      if (frameStyling.backgroundColor && !frameStyling.border) {
        borderStyle = 'none';
      }
    }
  
    let newStyle = {
      position: 'relative',
      zIndex: props.frameSelected ? 1000 : 'auto',
      border: borderStyle,
      // Avoid spreading frameStyling here to prevent overriding baseStyle
    };
  
    if (isImageFrame) {
      // Apply a brightness filter to darken the image by 20%
      newStyle.filter = 'brightness(0.8)';
    } else if (frameStyling && frameStyling.backgroundColor) {
      // Apply a darker version of the background color
      const darkerColor = darkenColor(frameStyling.backgroundColor, 0.2); // Darken by 20%
      newStyle.backgroundColor = darkerColor;
    } else {
      if (isDarkBackground(props.websiteColor)) {
        // Apply white background with 0.3 opacity when website background is dark
        newStyle.backgroundColor = 'rgba(255, 255, 255, 0.3)';
      } else {
        // Apply background blur effect when no background color is present
        newStyle.backgroundColor =
          frameDesign === 'none'
            ? 'rgba(0, 0, 0, 0.2)'
            : frameDesign !== 'color'
            ? `${props.websiteColor}`
            : frameRefStyle.backgroundColor;
  
        if (frameDesign !== 'none') {
          newStyle.filter = 'brightness(0.8)';
          newStyle.backdropFilter = 'blur(12px)';
        } else {
          newStyle.filter = 'none';
          newStyle.backdropFilter = 'none';
        }
      }
    }
  
    return newStyle;
  };
  
  
  useEffect(() => {
    if (isResizing && frameBoundaryRef.current) {
      console.log("Resizing")
      frameBoundaryRef.current.style.pointerEvents = 'none';
    } else if (frameBoundaryRef.current) {
      console.log("Not Resizing")
      frameBoundaryRef.current.style.pointerEvents = 'auto';
    }
  }, [isResizing])

  const [popupSize, setPopupSize] = useState({ width: '300px', height: 'auto' })
  let textPopupContainerStyle = {}
  let textColorPopupStyle = {}

  if (frameBoundaryRect) {
    const { bottom, top, left, width, height } = frameBoundaryRect;
    const scrollY = window.scrollY;
    const scrollX = window.scrollX;

    if (props.index === 0) {
      // Position below the frameBoundaryDiv if the first frame is being edited
      textPopupContainerStyle = {
        position: 'absolute',
        top: bottom + scrollY + 10 + marginTop , // 10px below frameBoundaryDiv
        left: left + scrollX,
        width: width,
        zIndex: 1000,
      };

      textColorPopupStyle = {
        position: 'absolute',
        top: (!showCommonColors && !colorPickerOpen) ? 70 : 'unset', // 10px below frameBoundaryDiv
        bottom: (showCommonColors || colorPickerOpen) ? -420 : 'unset',
        width: width - 20,
        zIndex: 1001,
      };
      
    } else {
      // Position above the frameBoundaryDiv
      textPopupContainerStyle = {
        position: 'absolute',
        top: top + scrollY - 10 + marginTop, // 10px above frameBoundaryDiv
        left: left + scrollX,
        width: width,
        zIndex: 1000,
        transform: 'translateY(-100%)', // Move it up by its own height
      };

      textColorPopupStyle = {
        position: 'absolute',
        top: top + scrollY + marginTop + height + 45, // 10px above frameBoundaryDiv
        width: width - 20,
        zIndex: 1001,
        transform: 'translateY(-100%)', // Move it up by its own height
      };
    }
  }

  const handlePopupSizeChange = useCallback((width, height) => {
    console.log({ width, height })
    setPopupSize({ width, height });
  }, [])

  const handleSelectedColor = useCallback((color) => {
    if (color && color.hex) {
      setPreviewColor(color.hex); // Update previewColor for real-time preview
    } else if (typeof color === 'string') {
      setPreviewColor(color); // Directly set previewColor if a string is provided
    }
  }, []);

  const handleConfirmColor = useCallback(async (newColor) => {
    if (newColor) { // Only proceed if a new color has been selected
      try {
        setSelectedFontColor(newColor);
        getSelectedFontColor(newColor); // Applies the color to the textarea
        console.log('Confirmed Color:', newColor); // For debugging
      } catch (error) {
        console.error('Error updating text:', error);
      }
    }
    setPreviewColor(null); // Reset previewColor regardless
    setShowTextColorPopup(false); // Close the color picker popup
  }, [props.storeID]);
  
  
  const handleCancelColor = useCallback(() => {
    setPreviewColor(null); // Revert to original color
    setShowTextColorPopup(false); // Close the popup
  }, []);

  if (props.websiteView && isFrameEmpty()) {
    return null;
  }

  return (
    <div 
      className="frameBoundaryDiv" 
      ref={frameBoundaryRef}
      style={{zIndex: isClicked ? 1000 : props.zIndex}}
    >
      {showTextPopup && frameBoundaryRect && (
        <TextPopupPortal>
          <div 
            className={props.index === 0 ? 'textPopupContainer2' : 'textPopupContainer'}
            ref={props.textPopupRef}
            style={textPopupContainerStyle}
          >
            <TextPopup
              parentComponent={'frames'}
              data={props.data}
              frameID={props.frameID} 
              storeID={props.storeID}
              selectedFont={selectedFont}
              selectedFontSize={selectedFontSize}
              selectedFontColor={selectedFontColor}
              selectedTextAreaFont={selectedTextAreaFont}
              fontFullName={fontFullName}
              setFontFullName={setFontFullName}
              setFontSize={setFontSize}
              closePopup={props.closePopup}
              closeTextPopup={() => {
                props.showLightenSticker(false)
                setShowTextPopup(false)
              }}
              frameDesign={frameDesign}
              onPopupClick={onTextPopupClick}
              setShowTextColorPopup={setShowTextColorPopup}
              showTextColorPopup={showTextColorPopup}
              textPopupRef={props.textPopupRef}
            />
            {showTextColorPopup &&
              <div
                className="textColourPopupContainer"
                ref={textColourPopupRef}
                style={{
                  ...textColorPopupStyle,
                  height: popupSize.height 
                }}
              >
                <WebsiteBackgroundPopup 
                  parentComponent={'frames'}
                  useCase={'textColorChange'}
                  storeID={props.storeID}
                  closePopup={() => {setShowTextColorPopup(false)}}
                  show={showTextColorPopup} 
                  onClickOutside={() => {setShowTextColorPopup(false)}} 
                  onSelectColor={handleSelectedColor}
                  objectColor={previewColor || selectedFontColor}
                  data={props.data}
                  pickerRef={props.pickerRef}
                  onDone={handleConfirmColor} 
                  onCancel={handleCancelColor}
                  onSizeChange={handlePopupSizeChange}
                  showCommonColors={showCommonColors}
                  setShowCommonColors={setShowCommonColors}
                  setColorPickerOpen={setColorPickerOpen}
                />
              </div>
            }
            {showTextAreaOptions ? 
              <div className="textareaOptionsContainer" ref={props.doneBtnDivRef}>
                <div className="textareaOptions">
                  <div className="textareaDoneBtn" 
                  onClick={saveBtnClick}
                  >
                    done
                    <img src={checkIcon} 
                      className="textareaCheckIcon" 
                      alt="finished with text"
                    />
                  </div>
                  <img src={optionsIcon} ref={props.vendorBarRef}
                    className="textareaElementBarIcon"
                    onClick={() => {
                      setShowInfo1(true)
                    }}
                  />
                </div> 
              </div>: null
            }
          </div> 
        </TextPopupPortal>
      )}
      {!isLoading &&
      <ReorderFrame 
        id={props.currentFrame.frameID} 
        index={props.index} 
        moveFrame={props.websiteView ? null : props.moveFrame}
      >
        <motion.div
          layout={!isResizing}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="frameBoundaryContainer"
          onClick={(e) => e.stopPropagation()}
          ref={combineRefs(drag, drop)}
          style={{ 
            opacity: props.isDragging ? 0.5 : 1, 
            cursor: props.isDragging ? 'grabbing' : 'pointer',
            width: "100%",
            transition: 'all 0.2s ease',
          }} 
          key={refreshKey}
        >
          <Resizable
            height={frameHeight}
            width={frameWidth}
            onResize={props.websiteView ? null : handleResize}
            onResizeStop={props.websiteView ? null : onResizeStop}
            handle={
              (
                <div ref={resizeHandlerRef} className={
                  isFrameActive 
                    ? (isDarkBackground(props.websiteColor) 
                        ? (frameRefStyle?.backgroundColor === '#FFFFFF' 
                            ? "frameResizeHandler orangeHandle" 
                            : "frameResizeHandler whiteHandle") 
                        : "frameResizeHandler") 
                    : "frameResizeHandler hidden"
                } />
              )
            }
            minConstraints={[100, 50]} // Set minimum constraints
            maxConstraints={[800, 600]} // Set maximum constraints as needed
            resizeHandles={['s']}
            style={{
              width: props.stretchFrame ? '100%' : '95%', 
              cursor: isDragging ? 'grabbing' : 'pointer', 
              display: 'flex',
              boxSizing: 'border-box',
              position: 'relative'
            }}
            onPointerDown={(e) => {
              if (!isResizing) {
                dragControls.start(e);
              }
            }}
          >
            {isImageFrame?
            <div>
              <img className={`image${props.frameStyle}`} 
                src={frameImg}
                ref={imageRef} 
                style={getFrameStyle()}          
                onClick={() => {
                  if (!props.websiteView) {
                    handleClick(props.frameID, props.zIndex, 'imageFrame');
                    props.handleDeleteFunctionality(isImageFrame, frameImg, props.frameID, props.storeID);
                  }
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                draggable={isResizing ? "false" : "true"}
              /> 
            </div>
            :
            <div 
              className={props.frameStyle}
              style={{ 
                ...getFrameStyle(),
                top: 0, // Ensure top remains fixed
                position: 'relative', // Or 'absolute' based on layout
              }}       
              ref={frameRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={()=> {
                if (!props.websiteView) {
                  handleClick(props.frameID, props.zIndex, 'textFrame');
                }
              }}
              draggable="false"
            >
              {!isTextArea && !props.websiteView ?
              <img id={(isDarkBackground(props.websiteColor)) ? 'whiteFramePlusIcon' : 'framePlusIcon'}
                src={plusIcon} 
                alt="insert an element into this frame" 
                onClick={() => {setShowInfo1(true)}}
                ref={framePlusIconRef}
              /> :
              <textarea 
                className="frameTextArea"
                ref={frameTextAreaRef} 
                value={text} 
                readOnly={props.websiteView}
                spellCheck={isClicked}
                style={{
                  cursor: props.websiteView && 'default'
                }}
                onChange={(e) => {
                  if (props.websiteView) return;
                  setText(e.target.value);
                  checkAndAdjustFrameHeight(); // This will adjust height on every text change
                }}
                onClick={() => {
                  if (!props.websiteView) {
                    setShowTextPopup(true);
                    props.showLightenSticker(true);
                    setShowTextAreaOptions(true);
                  }
                }}
              />
              // onClickOutside of a frame, the frame has its border removed
              }
            </div>
            }
          </Resizable>
          {!props.websiteView &&
            <ElementBar
              show={showInfo1} 
              vendorBarRef={props.vendorBarRef}
              frameBarRef={props.frameBarRef}
              imageFrameBarRef={props.imageFrameBarRef}
              frameBorderPopupRef={props.frameBorderPopupRef}
              frameTypesPopupRef={props.frameTypesPopupRef}
              draggableFrameRef={props.draggableFrameRef}
              frameRef={frameRef}
              imageRef={imageRef}
              textPopupRef={props.textPopupRef}
              resizeHandlerRef={resizeHandlerRef}
              onClickOutside={() => {setShowInfo1(false)}}
              frameID={props.frameID}
              storeID={props.storeID}
              openFramePopup={props.openFramePopup}
              openStickerPopup={props.openStickerPopup}
              addImageToFrame={addImageToFrame}
              isImageFrame={isImageFrame}
              frameImg={frameImg}
              removeFrame={props.removeFrame}
              removeTextPopup={removeTextPopup}
              closePopup={() => {setShowInfo1(false)}}
              textIconClicked={textIconClicked}
              imageIconClicked={imageIconClicked}
              setShowImageFrameBar={props.setShowImageFrameBar}
              isFrameEmpty={isFrameEmpty()}
              changeToText={changeToText}
              setChangeToText={setChangeToText}
              elementBarRef={props.elementBarRef}
              showImagePopup={showImagePopup}
              setShowImagePopup={setShowImagePopup}
              saveText={saveText}
            />
          }
        </motion.div>
      </ReorderFrame>
      }
      {showImageDonePopup &&  
        <div className="imageFrameOptionsContainer" ref={props.doneBtnDivRef}>
          <div className="imageFrameOptions">
            <div className="imageFrameDoneBtn" 
              onClick={updateFrameStylingInFirebase}
            >
              done
              <img src={checkIcon} 
                className="imageFrameCheckIcon" 
                alt="finished with text"
              />
            </div>
            <img src={optionsIcon} 
              className="imageFrameElementBarIcon"
              onClick={() => {setShowInfo1(true)}}
            />
          </div> 
        </div>
      }
    </div>
  )
}

export default Frames