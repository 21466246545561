export const shippingInformation = [
  {
    name: 'Aramex',
    logo: 'aramexLogo',
    options: [
      {
        description: 'standard',
        dimensions: '35 x 45cm',
        price: 'R99.99',
        value: 'Aramex Standard (35 x 45cm)',
        estimatedTime: '24 - 72 hours'
      }
      // Add more options here if necessary
    ]
  },
  {
    name: 'Pudo',
    logo: 'pudoLogo',
    subgroups: [
      {
        subgroup: 'Locker to Locker',
        options: [
          {
            description: 'Extra Small',
            dimensions: '60 x 17 x 8cm',
            price: 'R49.00',
            value: 'Locker-to-Locker Pudo Extra Small (60 x 17 x 8cm)',
            estimatedTime: '1-4 business days',
            maxWeight: '2kg'
          },
          {
            description: 'Small',
            dimensions: '60 x 41 x 8cm',
            price: 'R59.00',
            value: 'Locker-to-Locker Pudo Small (60 x 41 x 8cm)',
            estimatedTime: '1-4 business days',
            maxWeight: '5kg'
          },
          {
            description: 'Medium',
            dimensions: '60 x 41 x 19cm',
            price: 'R69.00',
            value: 'Locker-to-Locker Pudo Medium (60 x 41 x 19cm)',
            estimatedTime: '1-4 business days',
            maxWeight: '10kg'
          },
          {
            description: 'Large',
            dimensions: '60 x 41 x 41cm',
            price: 'R89.00',
            value: 'Locker-to-Locker Pudo Large (60 x 41 x 41cm)',
            estimatedTime: '1-4 business days',
            maxWeight: '15kg'
          },
          {
            description: 'Extra Large',
            dimensions: '60 x 41 x 69cm',
            price: 'R119.00',
            value: 'Locker-to-Locker Pudo Extra Large (60 x 41 x 69cm)',
            estimatedTime: '1-4 business days',
            maxWeight: '20kg'
          }
        ]
      },
      // {
      //   subgroup:  "Door to Locker",
      //   options: [
      //     {
      //       description: "Extra Small",
      //       dimensions: "60 x 17 x 8cm",
      //       price: "R50.00",
      //       value: "Door-to-Locker Pudo Extra Small (60 x 17 x 8cm)",
      //       estimatedTime: '1-4 business days'
      //     },
      //     {
      //       description: "Small",
      //       dimensions: "60 x 41 x 8cm",
      //       price: "R60.00",
      //       value: "Door-to-Locker Pudo Small (60 x 41 x 8cm)",
      //       estimatedTime: '1-4 business days'
      //     },
      //     {
      //       description: "Medium",
      //       dimensions: "60 x 41 x 19cm",
      //       price: "R100.00",
      //       value: "Door-to-Locker Pudo Medium (60 x 41 x 19cm)",
      //       estimatedTime: '1-4 business days'
      //     },
      //     {
      //       description: "Large",
      //       dimensions: "60 x 41 x 41cm",
      //       price: "R150.00",
      //       value: "Door-to-Locker Pudo Large (60 x 41 x 41cm)",
      //       estimatedTime: '1-4 business days'
      //     },
      //     {
      //       description: "Extra Large",
      //       dimensions: "60 x 41 x 69cm",
      //       price: "R200.00",
      //       value: "Door-to-Locker Pudo Extra Large (60 x 41 x 69cm)",
      //       estimatedTime: '1-4 business days'
      //     }
      //   ]
      // },
      // {
      //   subgroup:  "Locker to Door",
      //   options: [
      //     {
      //       description: "Extra Small",
      //       dimensions: "60 x 17 x 8cm",
      //       price: "R69.00",
      //       value: "Locker-to-Door Pudo Extra Small (60 x 17 x 8cm)",
      //       estimatedTime: '1-4 business days',
      //       maxWeight: '2kg'
      //     },
      //     {
      //       description: "Small",
      //       dimensions: "60 x 41 x 8cm",
      //       price: "R79.00",
      //       value: "Locker-to-Door Pudo Small (60 x 41 x 8cm)",
      //       estimatedTime: '1-4 business days',
      //       maxWeight: '5kg'
      //     },
      //     {
      //       description: "Medium",
      //       dimensions: "60 x 41 x 19cm",
      //       price: "R109.00",
      //       value: "Locker-to-Door Pudo Medium (60 x 41 x 19cm)",
      //       estimatedTime: '1-4 business days',
      //       maxWeight: '10kg'
      //     },
      //     {
      //       description: "Large",
      //       dimensions: "60 x 41 x 41cm",
      //       price: "R149.00",
      //       value: "Locker-to-Door Pudo Large (60 x 41 x 41cm)",
      //       estimatedTime: '1-4 business days',
      //       maxWeight: '15kg'
      //     },
      //     {
      //       description: "Extra Large",
      //       dimensions: "60 x 41 x 69cm",
      //       price: "R199.00",
      //       value: "Locker-to-Door Pudo Extra Large (60 x 41 x 69cm)",
      //       estimatedTime: '1-4 business days',
      //       maxWeight: '20kg'
      //     }
      //   ]
      // }          
    ]
  },
  {
    name: 'Paxi',
    logo: 'paxiLogo',
    subgroups: [
      {
        subgroup: 'Fast Delivery',
        options: [
          {
            description: 'Standard',
            dimensions: '45 x 37cm',
            price: 'R109.95',
            value: 'Paxi Fast Standard (45 x 37cm)',
            estimatedTime: '3 - 5 business days'
          },
          {
            description: 'Large',
            dimensions: '64 x 51cm',
            price: 'R139.95',
            value: 'Paxi Fast Large (64 x 51cm)',
            estimatedTime: '3 - 5 business days'
          }
        ]
      },
      {
        subgroup: 'Standard Delivery',
        options: [
          {
            description: 'Standard',
            dimensions: '45 x 37cm',
            price: 'R59.95',
            value: 'Paxi Slow Standard (45 x 37cm)',
            estimatedTime: '7 - 9 business days'
          },
          {
            description: 'Large',
            dimensions: '64 x 51cm',
            price: 'R89.95',
            value: 'Paxi Slow Large (64 x 51cm)',
            estimatedTime: '7 - 9 business days'
          }
        ]
      }
    ]
  }
  // Add more shipping methods here if necessary
];
  