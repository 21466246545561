// ShippingOptions.jsx

import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { auth, db, storage } from '../../firebase';
import Select from 'react-select';
import { doc, updateDoc } from 'firebase/firestore'; // For updating store doc
import './AddProduct.css';
import './ShippingOptions.css';

import aramexLogo from '../../images/aramexLogo.png';
import aramexStores from '../../images/aramexStores.png';
import paxiStores from '../../images/paxiStores.png';

import pudoLogo from '../../images/pudoLogo.png';
import paxiLogo from '../../images/paxiLogo.png';
import infoIcon from '../../icons/infoIcon.png'; // Ensure this path is correct

import { shippingInformation } from '../../cart/ShippingInformation';

const ShippingOptions = (props) => {
  const {
    shippingOptions,
    setShippingOptions,
    preselectAllShipping,         // <-- This comes from AddProduct.jsx (from store doc)
    setPreselectAllShipping,      // <-- We'll call this to update local (and then Firebase)
    storeID,                      // <-- Also passed from AddProduct so we can update store doc
  } = props;

  const [checkboxStates, setCheckboxStates] = useState({});
  const [selectedPudoOption, setSelectedPudoOption] = useState(null);
  const [selectedPaxiOption, setSelectedPaxiOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // State for showing/hiding overall shipping info
  const [showShippingInfo, setShowShippingInfo] = useState(false);

  // State for tracking which shipping method's steps are expanded (Aramex, Pudo, Paxi, or null)
  const [expandedMethod, setExpandedMethod] = useState(null);

  // ----- NEW STATE FOR SELECT-ALL LOGIC -----
  // Whether to show "Select all courier options?" prompt:
  // We'll show this if preselectAllShipping === false, so user can decide each time.
  const [showSelectAllPrompt, setShowSelectAllPrompt] = useState(false);

  // Did user say yes or no to "select all"?
  // If user says yes => we check all options; no => we uncheck all.
  const [selectAllAnswer, setSelectAllAnswer] = useState(null);

  // Whether to show the second prompt: "Do you want to preselect for future?"
  const [showPreselectPrompt, setShowPreselectPrompt] = useState(false);

  // Did user say yes or no to preselecting for future?
  const [preselectAllAnswer, setPreselectAllAnswer] = useState(null);

  // If "Pudo" or "Paxi" exist in shippingInformation
  const pudoMethod = shippingInformation.find(method => method.name === 'Pudo');
  const pudoSubgroups = pudoMethod?.subgroups || [];
  const pudoSelectOptions = pudoSubgroups.map(sub => ({ label: sub.subgroup, value: sub.subgroup }));

  const paxiMethod = shippingInformation.find(method => method.name === 'Paxi');
  const paxiSubgroups = paxiMethod?.subgroups || [];
  const paxiSelectOptions = paxiSubgroups.map(sub => ({ label: sub.subgroup, value: sub.subgroup }));

  // Map shipping methods to their logos
  const logoMap = {
    'aramexLogo': aramexLogo,
    'pudoLogo': pudoLogo,
    'paxiLogo': paxiLogo,
  };

  // On mount or whenever shippingOptions changes, refresh checkboxes
  useEffect(() => {
    const newStates = {};
    shippingInformation.forEach(method => {
      // Options directly under method
      if (method.options) {
        method.options.forEach(opt => {
          newStates[opt.value] = shippingOptions.includes(opt.value);
        });
      }
      // Options under subgroups (for Pudo and Paxi)
      if (method.subgroups) {
        method.subgroups.forEach(sub => {
          sub.options.forEach(opt => {
            newStates[opt.value] = shippingOptions.includes(opt.value);
          });
        });
      }
    });
    setCheckboxStates(newStates);
  }, [shippingOptions]);

  // Decide whether to show the initial "Select all courier options?" prompt
  // If store doc has preselectAllShipping = true, we skip this prompt
  useEffect(() => {
    if (preselectAllShipping) {
      setShowSelectAllPrompt(false);
      setSelectAllAnswer(null);
      setShowPreselectPrompt(false);
      setPreselectAllAnswer(null);
    } else {
      // If user hasn't answered yet, show prompt
      setShowSelectAllPrompt(true);
    }
  }, [preselectAllShipping]);

  // Handle updates to checkboxes
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const updatedStates = { ...checkboxStates, [value]: checked };
    setCheckboxStates(updatedStates);

    if (checked) {
      setShippingOptions([...shippingOptions, value]);
    } else {
      setShippingOptions(
        shippingOptions.filter(option => option !== value)
      );
    }
  };

  // If user selects YES on "Select all courier options?"
  const handleSelectAllYes = () => {
    setSelectAllAnswer('yes');
    // Check all boxes
    const updatedStates = {};
    shippingInformation.forEach(method => {
      if (method.options) {
        method.options.forEach(opt => {
          updatedStates[opt.value] = true;
        });
      }
      if (method.subgroups) {
        method.subgroups.forEach(sub => {
          sub.options.forEach(opt => {
            updatedStates[opt.value] = true;
          });
        });
      }
    });
    setCheckboxStates(updatedStates);

    // Also set shippingOptions array with all option values
    const allOptionValues = [];
    shippingInformation.forEach(method => {
      if (method.options) {
        method.options.forEach(opt => {
          allOptionValues.push(opt.value);
        });
      }
      if (method.subgroups) {
        method.subgroups.forEach(sub => {
          sub.options.forEach(opt => {
            allOptionValues.push(opt.value);
          });
        });
      }
    });
    setShippingOptions(allOptionValues);

    // Next, show the second prompt
    setShowPreselectPrompt(true);
  };

  // If user selects NO on "Select all courier options?"
  const handleSelectAllNo = () => {
    setSelectAllAnswer('no');
    // Deselect all
    setCheckboxStates({});
    setShippingOptions([]);
    // Hide second prompt
    setShowPreselectPrompt(false);
    setPreselectAllAnswer(null);
  };

  // If user selects YES on "Preselect for future?" => set store doc field to true
  const handlePreselectYes = async () => {
    setPreselectAllAnswer('yes');
    // We want to store "preselectAllShipping: true" in the store doc
    try {
      if (storeID) {
        const storeRef = doc(db, 'stores', storeID);
        await updateDoc(storeRef, {
          preselectAllShipping: true
        });
        // Locally set state as well
        setPreselectAllShipping(true);
      }
    } catch (error) {
      console.error("Error updating store doc for preselectAllShipping:", error);
    }
  };

  // If user selects NO on "Preselect for future?"
  const handlePreselectNo = () => {
    setPreselectAllAnswer('no');
    // No action needed in Firebase
  };

  // If store doc is already set to preselectAllShipping = true, show a div letting the user know
  // and allow them to disable.
  const handleDisablePreselect = async () => {
    try {
      if (storeID) {
        const storeRef = doc(db, 'stores', storeID);
        await updateDoc(storeRef, {
          preselectAllShipping: false
        });
        // Locally set state
        setPreselectAllShipping(false);
        // Also deselect everything
        setCheckboxStates({});
        setShippingOptions([]);
        // Return to original logic: ask user if they want to select all
        setShowSelectAllPrompt(true);
        setSelectAllAnswer(null);
        setShowPreselectPrompt(false);
        setPreselectAllAnswer(null);
      }
    } catch (error) {
      console.error("Error disabling preselectAllShipping:", error);
    }
  };

  // Toggle which method is expanded
  const handleMethodClick = (methodName) => {
    setExpandedMethod(prev => (prev === methodName ? null : methodName));
  };

  // Helper function to render steps based on method name
  const renderStepsForMethod = (methodName) => {
    switch (methodName) {
      case 'Aramex':
        return (
          <>
            <div className="aramexExplainerStep">1. Buy a sleeve at one of the stores shown below</div>
            <div className="aramexExplainerStep">
              2. Scan the QR code on the Aramex sleeve and then enter 
              your details and your customer's details (shown in your 
              dashboard) digitally
            </div>
            <div className="aramexExplainerStep">
              3. Place your item into the Aramex sleeve and take a
              photo of your parcel (which you will upload on taizte and
              we'll send to your customer)
            </div>
            <div className="aramexExplainerStep">
              4. Place the filled sleeve into the Aramex red dropoff 
              bin (at one of the stores below)
            </div>
            <div className="aramexExplainerStep">
              5. The sleeve will then be collected (Mon. - Fri. by 15:00)
            </div>
            <div className="partnerStoreLogos">
              <p>Stores with Aramex Dropoff bins:</p>
              <img src={aramexStores} className="aramexStores" alt="Aramex Stores"/>
            </div>
          </>
        );
      case 'Paxi':
        return (
          <>
            <div className="paxiExplainerStep">1. Go to your nearest Paxi Point and buy the bag matching your customer's choice.</div>
            <div className="paxiExplainerStep">2. Give your info and the receiver's info (shown in your dashboard) at the till, you'll need your ID.</div>
            <div className="paxiExplainerStep">3. Once the info has been recorded, hand your parcel to the cashier.</div>
            <div className="paxiExplainerStep">4. You can then track your parcel and you'll receive SMS updates from PAXI.</div>
            <div className="partnerStoreLogos">
              <p>Paxi Point Stores:</p>
              <img src={paxiStores} className="paxiStores" alt="Paxi Stores"/>
            </div>
          </>
        );
      case 'Pudo':
        return (
          <>
            <div className="pudoExplainerStep">1. Register/login on the Pudo app and make a booking for a locker.</div>
            <div className="pudoExplainerStep">2. While making the booking, fill in your info, the customer's info (shown in your dashboard), and choose the correct box size and locker nearest to you.</div>
            <div className="pudoExplainerStep">3. You'll get a PIN code to access the locker, which you will drop your package into.</div>
            <div className="pudoExplainerStep">4. Take photos of your parcel and then deposit it in the locker.</div>
            <div className="pudoExplainerStep">5. Your customer will receive a notification with a QR code and PIN (to access their parcel).</div>
          </>
        );
      default:
        return null;
    }
  };

  // Render checkboxes for each shipping method
  const renderMethodOptions = (method) => {
    if (!method.options) return null;
    return method.options.map((opt, oIndex) => {
      if (method.name === 'Aramex') {
        return (
          <React.Fragment key={oIndex}>
            <label className="addProductAramexOption1">
              <p className="aramexOption1Text">{opt.description} ({opt.dimensions})</p>
              <p className="aramexPrice">{opt.price}</p>
            </label>
            <input
              className="aramexcheckBox"
              type="checkbox"
              checked={checkboxStates[opt.value] || false}
              onChange={handleCheckboxChange}
              value={opt.value}
              name={`checkbox-aramex-${oIndex}`}
              id={`aramexcheckBox${oIndex}`}
            />
          </React.Fragment>
        );
      }

      if (method.name === 'Paxi') {
        const optionClass = oIndex === 0 ? "paxiOption1" : "paxiOption2";
        const optionContainer = `paxiOption${oIndex+1}Container`;
        const optionText = `paxiOption${oIndex+1}Text`;

        return (
          <label className={optionClass} key={oIndex}>
            <div className={optionContainer}>
              <p className={optionText}>{opt.description} ({opt.dimensions})</p>
              <p className="paxiPrice">{opt.price}</p>
            </div>
            <input 
              className={`paxicheckBox${oIndex+1}`}
              type="checkbox"
              checked={checkboxStates[opt.value] || false}
              onChange={handleCheckboxChange}
              value={opt.value}
              name={`checkbox-paxi-${oIndex}`}
              id={`paxicheckBox${oIndex+1}`}
            />
          </label>
        );
      }

      if (method.name === 'Pudo') {
        // Similar structure for Pudo if needed
        // Add your Pudo option rendering here
        return null; // We handle Pudo in renderPudoSubgroupOptions
      }

      return null;
    });
  };

  const renderPudoSubgroupOptions = (subgroupName) => {
    const subgroup = pudoSubgroups.find(s => s.subgroup === subgroupName);
    if (!subgroup) return null;

    return subgroup.options.map((opt, index) => {
      const optionClass = `pudoOption${index+1}`;
      const containerClass = `pudoOption${index+1}Container`;
      const textClass = `pudoOption${index+1}Text`;
      const dimensionsClass = `pudoOption${index+1}Dimensions`;

      return (
        <div className={optionClass} key={index}>
          <div className={containerClass}>
            <p className={textClass}>{opt.description.toLowerCase()}</p>
            <p className={dimensionsClass}>({opt.dimensions})</p>
            <p className="pudoPrice">{opt.price}</p>
          </div>
          <input
            className={`pudocheckBox${index+1}`}
            type="checkbox"
            checked={checkboxStates[opt.value] || false}
            onChange={handleCheckboxChange}
            value={opt.value}
            name={`checkbox-pudo-${subgroupName}-${index}`}
            id={`pudocheckBox${index+1}`}
          />
        </div>
      );
    });
  };

  const renderPaxiSubgroupOptions = (subgroupName) => {
    const subgroup = paxiSubgroups.find(s => s.subgroup === subgroupName);
    if (!subgroup) return null;

    return subgroup.options.map((opt, index) => {
      const optionClass = `paxiOption${index+1}`;
      const containerClass = `paxiOption${index+1}Container`;
      const textClass = `paxiOption${index+1}Text`;
      const dimensionsClass = `paxiOption${index+1}Dimensions`;

      return (
        <div className={optionClass} key={index}>
          <div className={containerClass}>
            <p className={textClass}>{opt.description.toLowerCase()}</p>
            <p className={dimensionsClass}>({opt.dimensions})</p>
            <p className="paxiPrice">{opt.price}</p>
          </div>
          <input
            className={`paxicheckBox${index+1}`}
            type="checkbox"
            checked={checkboxStates[opt.value] || false}
            onChange={handleCheckboxChange}
            value={opt.value}
            name={`checkbox-paxi-${subgroupName}-${index}`}
            id={`paxicheckBox${index+1}`}
          />
        </div>
      );
    });
  };

  return (
    <div className="shippingOptions">
      <h1 className="courierOptionsHeader">courier options</h1>

      {/* Info Icon for shipping info */}
      <div className="shippingInfoIconContainer">
        <img
          src={infoIcon}
          className="shippingInfoIcon"
          alt="Info Icon"
          onClick={() => setShowShippingInfo(!showShippingInfo)}
        />
      </div>

      {/* If preselectAllShipping is true, show the "All courier options have been preselected" div */}
      {preselectAllShipping && (
        <div className="preselectedAllDiv">
          <p className="preselectedAllText">
            all courier options have already been selected
          </p>
          <button 
            className={`courierBtn ${false ? 'selected' : ''}`} 
            onClick={handleDisablePreselect}
          >
            deselect
          </button>
        </div>
      )}

      {/* If user does NOT have preselectAllShipping, show the "Select all courier options?" prompt */}
      {showSelectAllPrompt && !preselectAllShipping && (
        <div className="selectAllDiv">
          <p className="selectAllDivText">select all courier options?</p>
          <div className="selectAllButtons">
            <button 
              className={`courierBtn ${selectAllAnswer === 'yes' ? 'selected' : ''}`} 
              onClick={handleSelectAllYes}
            >
              yes
            </button>
            <button 
              className={`courierBtn ${selectAllAnswer === 'no' ? 'selected' : ''}`} 
              onClick={handleSelectAllNo}
            >
              no
            </button>
          </div>
        </div>
      )}

      {/* If user said "yes" for the first question, show the second prompt */}
      {showPreselectPrompt && selectAllAnswer === 'yes' && (
        <div className="preselectFutureDiv">
          <p className="preselectFutureDivText">would you like to preselect all courier options for future?</p>
          <div className="preselectFutureButtons">
            <button 
              className={`courierBtn ${preselectAllAnswer === 'yes' ? 'selected' : ''}`} 
              onClick={handlePreselectYes}
            >
              yes
            </button>
            <button 
              className={`courierBtn ${preselectAllAnswer === 'no' ? 'selected' : ''}`} 
              onClick={handlePreselectNo}
            >
              no
            </button>
          </div>
        </div>
      )}

      {/* The shipping info div that appears when showShippingInfo is true */}
      {showShippingInfo && (
        <div className="shippingInfoDiv">
          <p className="shippingInfoText">
            Click one of the logos below to find out how the shipping method works.
          </p>
          <div className="shippingInfoLogosContainer">
            {shippingInformation.map((method, i) => (
              <img
                key={i}
                src={logoMap[method.logo]}
                alt={method.name}
                className="shippingInfoLogo"
                onClick={() => handleMethodClick(method.name)}
              />
            ))}
          </div>
          {/* Render the steps if a method is expanded */}
          {expandedMethod && (
            <div className="shippingMethodSteps">
              {renderStepsForMethod(expandedMethod)}
            </div>
          )}
        </div>
      )}

      {/* Aramex */}
      {shippingInformation.filter(m => m.name === 'Aramex').map((method, index) => (
        <div className="aramex" key={index}>
          {method.logo && (
            <img
              className="aramexLogo"
              src={logoMap[method.logo]}
              alt={method.name}
            />
          )}
          <div className="aramexOptions">{renderMethodOptions(method)}</div>
        </div>
      ))}

      {/* Pudo */}
      {pudoMethod && (
        <div className="pudo">
          {pudoMethod.logo && (
            <img
              className="pudoLogo"
              src={logoMap[pudoMethod.logo]}
              alt={pudoMethod.name}
            />
          )}
          <div className="pudoOptionsDiv">
            <Select
              onMenuOpen={() => setIsDropdownOpen(true)}
              onMenuClose={() => setIsDropdownOpen(false)}
              className="shippingSubgroupSelect"
              options={pudoSelectOptions}
              onChange={(option) => { setSelectedPudoOption(option); }}
              styles={{
                container: (base) => ({
                  paddingBottom: '-30px',
                }),
                control: (base) => ({
                  ...base,
                  position: 'relative',
                  backgroundColor: 'rgb(241, 240, 240)',
                  borderRadius: '10px',
                  width: '100%',
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  zIndex: 1001,
                  fontWeight: 'bold',
                  boxShadow:
                    '-2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45)',
                  borderColor: 'none',
                  border: 'none',
                }),
                menu: (base) => ({
                  ...base,
                  position: 'relative',
                  backgroundColor: 'rgb(241, 240, 240)',
                  top: '-22px',
                  marginBottom: '-24px',
                  borderRadius: '0px 0px 12px 12px',
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  zIndex: 1000,
                  paddingTop: '15px',
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#FB890E'
                    : state.isFocused
                    ? '#FFC55C'
                    : base.backgroundColor,
                  color:
                    state.isSelected || state.isFocused
                      ? 'rgba(0, 0, 0, 0.8)'
                      : base.color,
                  width: '98%',
                  margin: '2px auto',
                  boxSizing: 'border-box',
                  overflow: 'hidden',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  textAlign: 'left',
                }),
                indicatorSeparator: () => ({ display: 'none' }),
              }}
            />
            {selectedPudoOption && (
              <div
                className={
                  selectedPudoOption.value === 'Locker to Locker'
                    ? 'pudoOptionsLockerToLocker'
                    : selectedPudoOption.value === 'Door to Locker'
                    ? 'pudoOptionsDoorToLocker'
                    : selectedPudoOption.value === 'Locker to Door'
                    ? 'pudoOptionsLockerToDoor'
                    : ''
                }
              >
                {renderPudoSubgroupOptions(selectedPudoOption.value)}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Paxi */}
      {paxiMethod && (
        <div className="paxi">
          {paxiMethod.logo && (
            <img
              className="paxiLogo"
              src={logoMap[paxiMethod.logo]}
              alt={paxiMethod.name}
            />
          )}
          <div className="paxiOptionsDiv">
            <Select
              onMenuOpen={() => setIsDropdownOpen(true)}
              onMenuClose={() => setIsDropdownOpen(false)}
              className="shippingSubgroupSelect"
              options={paxiSelectOptions}
              onChange={(option) => {
                setSelectedPaxiOption(option);
              }}
              styles={{
                container: (base) => ({
                  paddingBottom: '-30px',
                }),
                control: (base) => ({
                  ...base,
                  position: 'relative',
                  backgroundColor: 'rgb(241, 240, 240)',
                  borderRadius: '10px',
                  width: '100%',
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  zIndex: 1001,
                  fontWeight: 'bold',
                  boxShadow:
                    '-2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45)',
                  borderColor: 'none',
                  border: 'none',
                }),
                menu: (base) => ({
                  ...base,
                  position: 'relative',
                  backgroundColor: 'rgb(241, 240, 240)',
                  top: '-22px',
                  marginBottom: '-24px',
                  borderRadius: '0px 0px 12px 12px',
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  zIndex: 1000,
                  paddingTop: '15px',
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#FB890E'
                    : state.isFocused
                    ? '#FFC55C'
                    : base.backgroundColor,
                  color:
                    state.isSelected || state.isFocused
                      ? 'rgba(0, 0, 0, 0.8)'
                      : base.color,
                  width: '98%',
                  margin: '2px auto',
                  boxSizing: 'border-box',
                  overflow: 'hidden',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  textAlign: 'left',
                }),
                indicatorSeparator: () => ({ display: 'none' }),
              }}
            />
            {selectedPaxiOption && (
              <div
                className={
                  selectedPaxiOption.value === 'Fast Delivery'
                    ? 'paxiOptionsFastDelivery'
                    : selectedPaxiOption.value === 'Standard Delivery'
                    ? 'paxiOptionsSlowerDelivery'
                    : ''
                }
              >
                {renderPaxiSubgroupOptions(selectedPaxiOption.value)}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShippingOptions;
