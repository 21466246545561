import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { Link, Outlet } from "react-router-dom";

import { onAuthStateChanged } from "firebase/auth";
import { 
  addDoc, collection, doc, updateDoc, setDoc, 
  getDoc, getDocs, arrayUnion, arrayRemove, query, 
  where, onSnapshot 
} from '@firebase/firestore';
import { auth, db, storage } from '../firebase';
import { motion, AnimatePresence, useAnimation, useMotionValue, animate } from "framer-motion";

import './Cart.css';
import historyIcon from '../icons/historyIcon.png';
import CartContainer from './CartContainer';
import PaymentPopup from './PaymentPopup';
import deleteIcon from '../icons/deleteIcon.png';
import loadIcon from '../icons/scoutIcon.png';
import Login from '../login/Login'; 

import backIcon from '../icons/backArrow.png';
import ScoutCard from '../scout/ScoutCard';
import ImageGallery from "../scout/ImageGallery";
import EthicalChecksPopup from "../scout/EthicalChecksPopup";
import notificationIcon from "../icons/notificationIcon.png";
import NotificationPopup from "../home/NotificationPopup";
import ScoutGradient from '../scout/ScoutGradient';
import FilmGrain from '../scout/FilmGrain';
import DustEffect from '../scout/DustEffect';

import BuyerInfoPopup from "./BuyerInfoPopup";

const Cart = (props) => {
  const loginPopupRef = useRef(); 
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cart, setCart] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [expandedStores, setExpandedStores] = useState({});
  const [imageGrowthAnimation, setImageGrowthAnimation] = useState();
  const [expandProductCard, setExpandProductCard] = useState(false);
  const [currentProduct, setCurrentProduct] = useState([]);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const trackRef = useRef(null);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [totalShippingPrice, setTotalShippingPrice] = useState(0);
  const [isShippingPriceFetched, setIsShippingPriceFetched] = useState(false);
  const paymentSliderRef = useRef();
  const xVal = useMotionValue(0);
  const [colorArray, setColorArray] = useState([]);
  const productInCartAnimation = useAnimation();
  const [showCartMessage, setShowCartMessage] = useState(false);
  let [animation, setAnimation] = useState(50);
  const wideScreenBreakpoint = 670;
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= wideScreenBreakpoint);
  const [globalTotalShippingPrice, setGlobalTotalShippingPrice] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [blockSlider, setBlockSlider] = useState(false);
  const [count, setCount] = useState(0);
  const [showShippingPopup, setShowShippingPopup] = useState(false);
  const shippingPopupAnimation = useAnimation();
  const [showTotalContainer, setShowTotalContainer] = useState(true);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [popupClosed, setPopupClosed] = useState(false);
  const [showBuyerInfoPopup, setShowBuyerInfoPopup] = useState(false);
  const [buyerInfo, setBuyerInfo] = useState(null)
  const [showInfo1, setShowInfo1] = useState(false); 
  const [currentUser, setCurrentUser] = useState(null); 
  const [showCartIcons, setShowCartIcons] = useState(false)

  const [noProducts, setNoProducts] = useState(false);
  const [visibleCardIndex, setVisibleCardIndex] = useState(0);
  const [showTextDivMessage, setShowTextDivMessage] = useState(false);
  const [infoDivExpanded, setInfoDivExpanded] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryThumbnails, setGalleryThumbnails] = useState([]);

  const [savedDominantColors] = useState([
    'rgb(197, 201, 198)',
    'rgb(91, 106, 125)',
    'rgb(138, 60, 36)',
    'rgb(210, 136, 55)',
    'rgb(114, 126, 132)'
  ]);

  useEffect(() => {
    document.title = 'Cart | taizte™';
  }, []);

  useEffect(() => {
    if (showShippingPopup) {
      shippingPopupAnimation.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.5 }
      }).then(() => {
        setTimeout(() => {
          setShowShippingPopup(false);
        }, 3500); // Adjust time as needed
      });
    }
  }, [showShippingPopup, shippingPopupAnimation]);

  const cartColorArray = [
    "rgb(30, 68, 30)",
    "rgb(125, 69, 27)",
    "rgb(198, 202, 83)",
    "rgb(118, 117, 4)",
    "rgb(219, 76, 64)"
  ];

  const handleBlockSlider = (shouldBlock, count, currentStoreID) => {
    console.log(count);
    console.log(shouldBlock);
    setBlockSlider(shouldBlock);
    if (shouldBlock) {
      setCount(count); // Assuming you have a state [count, setCount] for storing the count
    }
  };

  useEffect(() => { 
    const unsubscribe = onAuthStateChanged(auth, (user) => { 
      if (user) { 
        setCurrentUser(user); 
        setIsLoggedIn(true);
        setShowCartIcons(true)
        
        // Hide the login success popup after 2 seconds
        const timer = setTimeout(() => {
          setShowInfo1(false);
        }, 2000);
        
        // Cleanup the timeout on unmount or if user logs out before timeout
        return () => clearTimeout(timer);
      } else { 
        setShowInfo1(true); 
        setCurrentUser(null); 
        setIsLoggedIn(false);
        setShowCartIcons(false)
      } 
    }); 
    
    return () => unsubscribe(); 
  }, []); 

  useEffect(() => {
    if (showShippingPopup) {
      shippingPopupAnimation.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.5 }
      }).then(() => {
        setTimeout(() => {
          setShowShippingPopup(false);
        }, 3500); // Adjust time as needed
      });
    }
  }, [showShippingPopup, shippingPopupAnimation]);

  useEffect(() => {
    if (!showPaymentPopup) {
      xVal.set(0);
    }
  }, [showPaymentPopup, xVal]);

  const updateGlobalTotalShippingPrice = (storeID, storeName, totalShipping) => {
    setGlobalTotalShippingPrice(prevShippingPrices => {
      // Find the index of the store by storeID
      console.log("Total shipping: ", totalShipping);
      const storeIndex = prevShippingPrices.findIndex(store => store.storeID === storeID);
      let updatedShippingPrices;
      if (storeIndex > -1) {
        // Store exists, replace its total_shipping
        updatedShippingPrices = [...prevShippingPrices];
        updatedShippingPrices[storeIndex] = {
          ...updatedShippingPrices[storeIndex],
          total_shipping: totalShipping // Replacing the value
        };
      } else {
        // Store does not exist, add a new entry with storeID
        updatedShippingPrices = [...prevShippingPrices, { storeID, store: storeName, total_shipping: totalShipping }];
      }

      // Calculate the new total shipping price
      const total = updatedShippingPrices.reduce((acc, store) => acc + store.total_shipping, 0);
      setTotalShippingPrice(total); // Update the total shipping price state
      const cartRef = doc(db, "cart", props.userID);

      console.log("Total sum: ", totalSum, " Total: ", total);
      updateDoc(cartRef, { cartShippingTotal: total, cartTotal: (totalSum + total) }).catch(error => {
        console.error("Error updating cart totals:", error);
      });
      return updatedShippingPrices;
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= wideScreenBreakpoint);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    
    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => { 
    if (props.navBarState === true) {
      setAnimation(-20);
    } else {
      setAnimation(0);
    }
  }, [props.navBarState]);

  useEffect(() => {
    if (paymentSliderRef.current) {
      setSliderWidth(paymentSliderRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (showBuyerInfoPopup) {
      animate(xVal, 0, { duration: 0.5 });
    }
  }, [showBuyerInfoPopup, xVal]);

  const handleClosePaymentPopup = () => {
    // Reset states that need to be re-triggered
    setBuyerInfo(null);
    setShowPaymentPopup(false);
    // If needed, also reset showBuyerInfoPopup
    setShowBuyerInfoPopup(false);
    // The next time the user drags the slider, 
    // it will start the process over again as buyerInfo is now null.
  };

  const dragTransition = (event, info) => {
    if (props.country !== 'South Africa') {
      props.setAlertMessage(`Purchasing is not allowed in ${props.country} yet.`);
      props.setAlertType('error');
      animate(xVal, 0, { duration: 0.3 });
      return;
    }
  
    if (blockSlider) {
      setShowShippingPopup(true);
      animate(xVal, 0, { duration: 0.3 });
      return;
    }
  
    if (totalSum === 0 || totalShippingPrice === 0 || (totalSum + totalShippingPrice) === 0) {
      props.setAlertMessage("Cannot proceed to payment: Products fee, Shipping & Handling fees, or Total is zero.");
      props.setAlertType("error");
      animate(xVal, 0, { duration: 0.3 });
      return;
    }

    setShowPaymentPopup(false)
  
    if (info.point.x < sliderWidth * 0.7) {
      animate(xVal, 0, { duration: 0.3 });
      setShowBuyerInfoPopup(false);
    } else {
      animate(xVal, sliderWidth - 50, { 
        duration: 0.3,
        onComplete: () => {
          setShowBuyerInfoPopup(true);
        }
      });
    }
  };
  
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Consolidated useEffect to handle fetching cart, calculating totals, and updating Firebase
  useEffect(() => {
    const fetchAndProcessCart = async () => {
      if (!props.userID) {
        // If user is not logged in, reset cart totals
        return;
      }

      try {
        const cartRef = doc(db, "cart", props.userID);
        const cartSnap = await getDoc(cartRef);

        if (cartSnap.exists()) {
          const cartData = cartSnap.data().cart || [];
          const groupedCart = cartData.reduce((groups, item) => {
            const store = (groups[item.store] || []);
            store.push(item);
            groups[item.store] = store;
            return groups;
          }, {});

          setCart(groupedCart);
          setIsLoading(false);

          let calculatedTotalSum = 0;
          let calculatedTotalShipping = 0;
          const updatedGlobalShippingPrices = [];

          // Iterate over each store to calculate totals
          for (const storeName in groupedCart) {
            const storeProducts = groupedCart[storeName];
            const storeID = storeProducts[0].storeID;

            // Fetch store bundling rules
            const storeDocRef = doc(db, "stores", storeID);
            const storeSnap = await getDoc(storeDocRef);

            if (storeSnap.exists()) {
              const bundlingRules = storeSnap.data().bundlingRules || {};

              let storeTotalShipping = 0;
              const shippingCounts = {};

              // Calculate shipping per store
              storeProducts.forEach(product => {
                const shippingMethod = product.selected_shippingName;
                if (shippingMethod) {
                  shippingCounts[shippingMethod] = (shippingCounts[shippingMethod] || 0) + 1;
                }
              });

              for (const method in shippingCounts) {
                const count = shippingCounts[method];
                const bundlingRule = bundlingRules[method] || 1;
                const applicableBundles = Math.floor(count / bundlingRule);
                const remaining = count % bundlingRule;

                // Fetch shipping price from product
                const productWithMethod = storeProducts.find(p => p.selected_shippingName === method);
                const shippingPrice = parseFloat(
                  String(productWithMethod.selected_shippingPrice || "0").replace("R", "")
                );
                storeTotalShipping += (applicableBundles * shippingPrice) + (remaining * shippingPrice);
              }

              calculatedTotalShipping += storeTotalShipping;

              // Add to global shipping prices
              updatedGlobalShippingPrices.push({
                storeID,
                store: storeName,
                total_shipping: storeTotalShipping
              });
            } else {
              console.error(`Store document does not exist for storeID: ${storeID}`);
            }


            // Calculate total sum for products
            const storeTotal = storeProducts.reduce((sum, item) => sum + Number((item.listed_price || "0").replace("R", "")), 0);
            calculatedTotalSum += storeTotal;
          }

          console.log(calculatedTotalSum)
          setTotalSum(calculatedTotalSum);
          setTotalShippingPrice(calculatedTotalShipping);
          setGlobalTotalShippingPrice(updatedGlobalShippingPrices);

          // Update Firebase with calculated totals
          await updateDoc(cartRef, { 
            cartShippingTotal: calculatedTotalShipping, 
            cartTotal: (calculatedTotalSum + calculatedTotalShipping),
            productTotal: calculatedTotalSum
          });

        } else {
          // If cart does not exist, initialize it with zero totals
          await setDoc(doc(db, "cart", props.userID), {
            cart: [],
            cartShippingTotal: 0,
            cartTotal: 0
          });
          setCart({});
          setTotalSum(0);
          setTotalShippingPrice(0);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching or processing cart:", error);
      }
    };

    fetchAndProcessCart();
  }, [props.userID]);

  // Remove separate useEffects that were handling cart totals individually

  const setShippingPrice = useCallback((value) => {
    setTotalShippingPrice(value);
  }, []);

  // Adjust getProducts useEffect to only fetch products without handling totals
  useEffect(() => {
    if (props.userID) {
      const getProducts = async() => {
        try {
          const cartRef = doc(db, "cart", props.userID);
          const cartSnap = await getDoc(cartRef);

          if (cartSnap.exists()) {
            const list = cartSnap.data().cart || [];

            const groups = list.reduce((groups, item) => {
              const store = (groups[item.store] || []);
              store.push(item);
              groups[item.store] = store;
              return groups;
            }, {});

            console.log("Groups: ", groups);
            setCart(groups);
            setIsLoading(false);
          } else {
            setCart({});
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
      getProducts();
    }
  }, [props.userID]);

  const stores = Object.keys(cart).map(storeName => ({
    storeID: cart[storeName][0].storeID, // Assuming all products in a store have the same storeID
    storeName: storeName,
    products: cart[storeName]
  }));

  const calculateTotalShippingPrice = (cartData) => {
    let totalShipping = 0;
    for (let item of cartData) {
      if (item.selected_shippingPrice) {
        totalShipping += parseFloat(item.selected_shippingPrice);
      }
    }
    return totalShipping;
  };  

  // Removed fetchTotalShippingPrice useEffect as totals are now handled in the consolidated useEffect

  const getTotalForGroup = (groups, storeName) => {
    const group = groups[storeName];
    return group.reduce((sum, item) => sum + Number(item.listed_price || 0), 0);
  };

  const closeExpandedProductCard = () => {
    setExpandProductCard(false);
  };

  const expandCardFunction = useCallback(async(
    productID, productImage, productThumbnail, productPrice, productTitle, store, 
    productDescription, productCategory, data, bundling, cart
  ) => {
    console.log(cart);
    setIsLoading(false);
    setExpandProductCard(true);
    setImageGrowthAnimation(true);
    console.log(data);
    setCurrentProduct([{
      productID: productID,
      images: productImage,
      imageThumbnails: productThumbnail,
      productPrice: productPrice,
      productName: productTitle,
      store: store,
      productDescription: productDescription,
      productCategory: productCategory,
      data: data,
      bundling: bundling,
    }]);
    console.log([{
      productID: productID,
      productImage: productImage,
      productPrice: productPrice,
      productName: productTitle,
      store: store,
      productDescription: productDescription,
      productCategory: productCategory,
      data: data
    }]);
    
  }, []);

  const handleTotalShippingPrice = (shippingValue) => {
    console.log("Total Shipping Price:", shippingValue);
  
    const cartRef = doc(db, 'cart', props.userID);
    updateDoc(cartRef, { 
      cartShippingTotal: shippingValue, 
      cartTotal: (totalSum + shippingValue) ,
      productTotal: totalSum,
    })
      .then(() => {
        setTotalShippingPrice(shippingValue);
        setIsShippingPriceFetched(true);
      })
      .catch(error => {
        console.error("Error updating shipping price:", error);
      });
  };

  const handleDominantColor = (dominantColor, index) => {
    setColorArray((prevColors) => {
      const newColors = [...prevColors];
      newColors[index] = dominantColor;
      return newColors;
    });
  };

  const alreadyInCart = useCallback((alreadyInCartMessage) => {
    setShowCartMessage(alreadyInCartMessage);
    console.log('alreadyInCart called with', alreadyInCartMessage);
    if (alreadyInCartMessage === true) {
      setShowCartMessage(true);
  
      // Start the animation
      productInCartAnimation.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.5 },
      });
  
      // Reset the animation after 4 seconds
      setTimeout(() => {
        productInCartAnimation.start({
          y: -30,
          opacity: 0,
          transition: { duration: 0.5 },
        });
      }, 4000);
    } else {
      setShowCartMessage(false);
    }
  }, [productInCartAnimation]);

  const [ethicalChecks, setEthicalChecks] = useState();
  const [ethicalChecksText, setEthicalChecksText] = useState('');
  const [showEthicalChecksPopup, setShowEthicalChecksPopup] = useState(false);

  const openEthicalChecksPopup = useCallback((eCheck, eCheckText) => {
    console.log(eCheck, eCheckText);
    setEthicalChecks(eCheck);
    setEthicalChecksText(eCheckText);
    setShowEthicalChecksPopup(true);
  }, []);

  const togglePopup = () => {
    setShowNotifications(!showNotifications);
    setPopupClosed(!popupClosed); // Set this to true when the popup is closed
  };

  useEffect(() => {
    if (!props.userID) return; // Make sure props.userID is not undefined
    getNotifications();
    console.log(notifications);
  }, [props.userID]);

  const getNotifications = async () => {
    try {
      const q = query(
        collection(db, "notifications"), 
        where("userID", "==", props.userID), 
        where("notificationType", "==", "productUpdate")
      );
      const querySnapshot = await getDocs(q);
      let fetchedNotifications = [];
  
      querySnapshot.forEach((doc) => {
        let notification = doc.data();
        notification.id = doc.id; // Add document ID to the notification object
        fetchedNotifications.push(notification);
      });
  
      // Sort notifications by time
      fetchedNotifications.sort((a, b) => b.messageDateNumeric - a.messageDateNumeric);
  
      // Update notifications state
      setNotifications(fetchedNotifications);
  
      // Filter and set read and unread notifications
      setReadNotifications(fetchedNotifications.filter(n => n.readStatus === 'read'));
      setUnreadNotifications(fetchedNotifications.filter(n => n.readStatus === 'unread'));
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      // Update the readStatus in Firebase
      const notificationRef = doc(db, "notifications", notificationId);
      await updateDoc(notificationRef, {
        readStatus: 'read'
      });
  
      // Update local state
      // Create a new array with the updated readStatus
      const updatedNotifications = notifications.map(notification => {
        if (notification.id === notificationId) {
          return { ...notification, readStatus: 'read' };
        }
        return notification;
      });
  
      // Update the notifications state
      setNotifications(updatedNotifications);
  
      // Update read and unread notifications states
      setReadNotifications(updatedNotifications.filter(n => n.readStatus === 'read'));
      setUnreadNotifications(updatedNotifications.filter(n => n.readStatus === 'unread'));
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  const openGallery = (images, thumbnails) => {
    setGalleryImages(images);
    setGalleryThumbnails(thumbnails);
    setIsGalleryOpen(true);
  };

  const closeGallery = () => setIsGalleryOpen(false);

  return (
    <div className="cartBody">
      <div ref={loginPopupRef} className="cartLoginPopup">
        <Login 
          show={showInfo1} 
          onClickOutside={currentUser ? () => setShowInfo1(false) : undefined} 
        />
      </div>

      {showPaymentPopup && (
        <PaymentPopup 
          onClosePaymentPopup={handleClosePaymentPopup}
          setShowPaymentPopup={setShowPaymentPopup} 
          userID={props.userID}
          totalAmount={(totalSum ?? 0) + (totalShippingPrice ?? 0)}
          setAlertMessage={props.setAlertMessage}
          setAlertType={props.setAlertType}
          stores={stores}
          buyerInfo={buyerInfo}
          cartShippingTotal={totalShippingPrice} // Passing cartShippingTotal
          productTotal={totalSum}               // Passing productTotal
          cartTotal={totalSum + totalShippingPrice} // Passing cartTotal
        />
      )}
      {!isLoading && expandProductCard &&
        <div
          className={`cartCardPopupDiv ${isGalleryOpen ? 'no-blur' : ''}`}
        >
          <div 
            style={{ 
              position: 'relative', 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100vw', 
              height: '100vh', 
              margin: 'auto'
            }}
          >
            <div 
              className="cartCardExitIconContainer" 
              onClick={closeExpandedProductCard}
            >
              <img src={backIcon} alt="delete uploaded" id="cartDeleteIcon" />
            </div>
            {currentProduct.map((object, index) => (
              <ScoutCard
                page={'cart'}
                showLogin={props.showLogin}
                isLoggedIn={isLoggedIn}
                productID={object.productID}
                data={object.data}
                data_length={(object.data).length}
                image={object.images}
                imageThumbnail={object.imageThumbnails}
                onDominantColor={handleDominantColor}
                listed_price={object.productPrice}
                title={object.productName}
                store={object.store}
                storeID={object.storeID}
                description={object.productDescription}
                category={object.productCategory}
                index={index}
                navBarState={props.navBarState}
                userID={props.userID}
                alreadyInCart={alreadyInCart}
                bundling={object.bundling}
                isWideScreen={isWideScreen}
                openEthicalChecksPopup={openEthicalChecksPopup}  
                showTextDivMessage={showTextDivMessage}
                setShowTextDivMessage={setShowTextDivMessage}
                infoDivExpanded={infoDivExpanded}
                setInfoDivExpanded={setInfoDivExpanded} 
                dominantColors={savedDominantColors} 
                visible={true} 
                onLoad={null} 
                animationsActive={false} 
                setTransitionStarted={props.setTransitionStarted} 
                websiteBackground={'#FFFFFF'}
                openGallery={openGallery} 
                setAlertMessage={props.setAlertMessage}
                setAlertType={props.setAlertType}
                country={props.country}
              />
            ))}
          </div>
          <AnimatePresence>
            {showTextDivMessage &&
              <motion.div
                className="textDivMessage"
                initial={{ y: -30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -30, opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                {infoDivExpanded ? 'Longpress the description box to collapse it.' : 'Longpress the description box to expand it.'}
              </motion.div>
            }
          </AnimatePresence>
          <AnimatePresence>
            {isGalleryOpen && (
              <ImageGallery 
                image={galleryImages} 
                imageThumbnail={galleryThumbnails}
                closeGallery={closeGallery}
              />
            )}
          </AnimatePresence>
        </div>
      }
      <style>{'body { background: linear-gradient(-160deg, #E94C49, #F56551 ); }' }</style>
      <div className="cartHeader">
        <div className="cartHeadingDiv">
          <h1 className='cartHeading'>cart</h1>
        </div>
        {showCartIcons &&
          <div className="cartBtnDiv">
            <div className="trackParcelBtn">track parcel</div>
            {/* <img className="cartHistoryIcon" src={historyIcon} alt="history" /> */}
            <div className="cartNotificationIconContainer" style={{ position: 'relative' }}>
              <img src={notificationIcon} className="cartNotificationIcon" onClick={togglePopup}/>
              {unreadNotifications.length > 0 && (
                <span className="cartNotificationBadge">
                  {unreadNotifications.length}
                </span>
              )}
            </div>
          </div>
        }
      </div>
      {showNotifications && 
        <NotificationPopup 
          userID={props.userID}
          markNotificationAsRead={markNotificationAsRead}
          notifications={notifications}
          unreadNotifications={unreadNotifications}
          readNotifications={readNotifications}
        />
      }

      {showBuyerInfoPopup && 
        <BuyerInfoPopup 
          cartData={Object.values(cart).flat()} 
          onDone={(data) => {
            setBuyerInfo(data)
            setShowBuyerInfoPopup(false);
            setShowPaymentPopup(true);
          }}
          onClose={() => setShowBuyerInfoPopup(false)}
          totalSum={totalSum}
          totalShippingPrice={totalShippingPrice}
        />
      }
      
      {/* Replace the loading icon with conditional messages */}
      {!isLoading && !isLoggedIn && (
        <div className="emptyCartDiv">Please log in to view your cart.</div>
      )}
      {!isLoading && isLoggedIn && Object.keys(cart).length === 0 && (
        <div className="emptyCartDiv">Your cart is empty.</div>
      )}
      {/* **Modified Section Ends Here** */}

      {/* Optionally, you can remove the original loading icon section */}
      {/* {isLoading ?
        <div className="cartLoadingDiv">
          <img src={loadIcon} className="cartLoadingIcon" alt="loading icon" />
        </div> : null
      } */}

      {/* Alternatively, if you still want to handle the loading state differently, you can adjust accordingly */}
      {/* For example, show nothing or a different message while loading */}
      
      <div className="cartProductSection">
        {!isLoading && isLoggedIn && Object.keys(cart).length > 0 && (
          Object.keys(cart).map(storeName => {
            if (cart[storeName] && cart[storeName].length > 0) {
              console.log(cart[storeName][0].address);
              return (
                <CartContainer
                  userID={props.userID}
                  key={storeName}
                  setShippingPrice={setShippingPrice} 
                  updateGlobalTotalShippingPrice={updateGlobalTotalShippingPrice}
                  storeName={storeName}
                  storeID={cart[storeName][0].storeID} // We've already checked that this exists
                  cart={cart}
                  address={cart[storeName][0].address}
                  expandedStores={expandedStores}
                  setIsActive={setIsActive}
                  isActive={isActive}
                  expandCardFunction={expandCardFunction}
                  getTotalForGroup={getTotalForGroup}
                  setExpandedStores={setExpandedStores}
                  handleTotalShippingPrice={handleTotalShippingPrice}
                  setIsLoadingDeleted={setIsLoading}
                  setCart={setCart}
                  onBlockSlider={handleBlockSlider}
                  setShowTotalContainer={setShowTotalContainer}
                  setAlertMessage={props.setAlertMessage}
                  setAlertType={props.setAlertType}
                  country={props.country}
                />
              );
            } else {
              // If there are no items, don't render CartContainer and optionally handle this case
              return null; // Or some other placeholder/fallback
            }
          })
        )}
      </div>
      {showEthicalChecksPopup && 
        <EthicalChecksPopup 
          onClickOutside={() => {setShowEthicalChecksPopup(false)}}
          ethicalChecks={ethicalChecks}
          ethicalChecksText={ethicalChecksText}
        />
      }
      {showShippingPopup && (
        <motion.div 
          className="shippingPopupContainer"
          initial={{ y: -50, opacity: 0 }}
          animate={shippingPopupAnimation}
        >
          <div className="shippingPopup">
            {count} {count === 1 ? 'product' : 'products'} still need{count === 1 ? 's' : ''} shipping to be selected.
          </div>
        </motion.div>
      )}
      {showTotalContainer && isLoggedIn && Object.keys(cart).length !== 0 ? 
      <motion.div className="cartTotalContainer" animate={{ y: animation }}>
        <div className="cartTotalDiv">
          <div className="productsTotal">
            <div className="productsText">Products</div>
            <div className="productsAmount">R{(totalSum ?? 0).toFixed(2)}</div>
          </div>
          <div className="shippingHandlingFeesTotal">
            <div className="shippingHandlingFeesText">Shipping & Handling fees</div>
            <div className="shippingHandlingFeesAmount">R{(totalShippingPrice ?? 0).toFixed(2)}</div>
          </div>
          <hr border-radius="2" size="3" width="95%" color="#000000"/>
          <div className="cartTotal">
            <div className="cartTotalText">Total</div>
            <div className="cartTotalAmount">R{((totalSum ?? 0) + (totalShippingPrice ?? 0)).toFixed(2)}</div>
          </div>
          <div className="paymentSlider" ref={paymentSliderRef}>
            <div ref={trackRef} className="paymentSliderTrack">
              <motion.div className="paymentSliderButton"
                style={{ x: xVal }}
                key={props.index}
                drag="x"
                dragConstraints={trackRef}
                dragMomentum={!blockSlider}
                dragElastic={0}
                onDragEnd={dragTransition}
              />
            </div>
          </div>
        </div>
      </motion.div> : null
      }
      {showCartMessage && 
        <motion.div
          className="alreadyInCartDiv"
          initial={{ y: -30, opacity: 0 }}
          animate={productInCartAnimation}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="alreadyInCartDivText">
            The product is already in the cart, to review it go to the cart page
          </div>
        </motion.div>
      }
    </div>
  );
};

export default Cart;
