import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { doc, updateDoc, arrayUnion, arrayRemove } from '@firebase/firestore';
import { storage, db } from '../../firebase';
import plusIcon from '../../icons/plusIcon.png';
import deleteIcon from '../../icons/deleteIcon.png';
import infoIcon from '../../icons/infoIcon.png';
import loadIcon from '../../icons/scoutIcon.png';
import { motion, AnimatePresence } from 'framer-motion';
import './ParcelUploadComponent.css';

const ParcelUploadComponent = ({ setUploadedImages, productID }) => {
  const [uploadImages, setUploadImages] = useState([]);
  const [error, setError] = useState('');
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Cleanup on unmount: revoke object URLs
  useEffect(() => {
    return () => {
      uploadImages.forEach((img) => {
        if (img.preview && !img.fromFirebase) {
          URL.revokeObjectURL(img.preview);
        }
      });
    };
  }, [uploadImages]);

  // UseEffect to update parent’s uploadedImages after uploadImages changes
  useEffect(() => {
    const completedImageURLs = uploadImages
      .filter((img) => img.completed)
      .map((img) => img.preview);

    setUploadedImages((prev) => {
      // Only update if there's an actual difference
      const prevStr = JSON.stringify(prev);
      const newStr = JSON.stringify(completedImageURLs);
      if (prevStr !== newStr) {
        return completedImageURLs;
      }
      return prev;
    });
  }, [uploadImages, setUploadedImages]);

  const toggleInfoVisibility = () => {
    setIsInfoVisible((prev) => !prev);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    const remainingSlots = 3 - uploadImages.length;
    if (files.length > remainingSlots) {
      setError(`You can only upload ${remainingSlots} more image(s).`);
      e.target.value = '';
      return;
    }

    const oversizedFiles = files.filter(file => file.size > 5 * 1024 * 1024);
    if (oversizedFiles.length > 0) {
      setError('One or more files exceed the 5 MB size limit. Please select smaller files.');
      // Optionally, you can choose to not proceed with any files or skip oversized ones
      // Here, we'll skip oversized files
      files = files.filter(file => file.size <= 5 * 1024 * 1024);
    }

    const duplicateFiles = files.filter(file => 
      uploadImages.some(img => img.file && img.file.name === file.name)
    );
    if (duplicateFiles.length > 0) {
      setError('One or more selected images have already been uploaded.');
      // Optionally, skip duplicate files
      files = files.filter(file => 
        !uploadImages.some(img => img.file && img.file.name === file.name)
      );
    }

    if (files.length === 0) {
      e.target.value = '';
      return;
    }

    setError('');
    setIsUploading(true);

    files.forEach((file) => {
      const uniqueName = file.name + uuidv4();
      const storageRefInstance = ref(storage, `parcel images/${uniqueName}`);
      const uploadTask = uploadBytesResumable(storageRefInstance, file);

      // Add image to state as uploading
      const newImage = {
        file,
        preview: URL.createObjectURL(file),
        progress: 0,
        completed: false,
        fromFirebase: false,
      };
      setUploadImages((prev) => [...prev, newImage]);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadImages((prev) =>
            prev.map((img) =>
              img.file === file ? { ...img, progress } : img
            )
          );
        },
        (uploadError) => {
          console.error('Error uploading file: ', uploadError);
          setError('Error uploading one or more files. Please try again.');
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Update Firestore with the new image URL
            const dbRef = doc(db, 'products', productID);
            await updateDoc(dbRef, {
              parcelImages: arrayUnion(downloadURL),
            });
            console.log('Parcel image URL added to Firestore');

            // Mark image as completed
            setUploadImages((prev) =>
              prev.map((img) =>
                img.file === file
                  ? { ...img, preview: downloadURL, completed: true, fromFirebase: true }
                  : img
              )
            );
          } catch (err) {
            console.error('Error during post-upload processing:', err);
            setError('Error processing one or more uploaded images. Please try again.');
          } finally {
            // Check if all uploads are completed
            setUploadImages((prev) => {
              const allCompleted = prev.every(img => img.completed);
              if (allCompleted) {
                setIsUploading(false);
              }
              return prev;
            });
          }
        }
      );
    });

    // Reset the input value to allow re-uploading the same file if needed
    e.target.value = '';
  };

  const handleDelete = async (index) => {
    const imageToDelete = uploadImages[index];
    if (!imageToDelete) return;

    const newUploadImages = uploadImages.filter((_, i) => i !== index);
    setUploadImages(newUploadImages);

    // If the image was completed and from Firebase, remove it from Firestore and Storage
    if (imageToDelete.completed && imageToDelete.fromFirebase) {
      try {
        const dbRef = doc(db, 'products', productID);
        await updateDoc(dbRef, {
          parcelImages: arrayRemove(imageToDelete.preview),
        });
        console.log(`${imageToDelete.preview} removed from Firestore document ${productID}`);

        // Extract the file name from the download URL
        let fileName = imageToDelete.preview
          .slice(imageToDelete.preview.lastIndexOf('%2F') + 3, imageToDelete.preview.lastIndexOf('?'))
          .replace(/%20/g, ' ');

        const deleteRefOriginal = ref(storage, `parcel images/${fileName}`);
        await deleteObject(deleteRefOriginal);
        console.log('Parcel image successfully deleted from Firebase');
      } catch (error) {
        console.error('Error deleting image from Firebase:', error);
        setError('Error deleting image from storage. Please try again.');
      }
    }
    setError('');
  };

  const maxImagesReached = uploadImages.length >= 3;

  return (
    <div className="parcelUploadContainer">
      <div className="parcelUploadHeader">
        Upload Parcel Images (Max 3)
        <img
          className="parcelUploadImgInfoIcon"
          src={infoIcon}
          alt="Info"
          onClick={toggleInfoVisibility}
          style={{ cursor: 'pointer', marginLeft: '8px' }}
        />
      </div>

      <AnimatePresence>
        {isInfoVisible && (
          <motion.div
            className="parcelUploadInfoDiv"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
          >
            Please upload images of the parcel after it has been 
            wrapped, before you send it. This increases your 
            customer's trust in your store.
          </motion.div>
        )}
      </AnimatePresence>

      <div className="parcelImagesContainer">
        {uploadImages.map((img, index) => (
          <div key={index} className="parcelImageWrapper">
            <img src={img.preview} alt="Parcel" className="parcelImage" />
            {img.completed ? (
              <div id="parcelImgUploadDeleteBtn" onClick={() => handleDelete(index)}>
                <img
                  src={deleteIcon}
                  alt="Delete"
                  className="parcelDeleteIcon"
                />
              </div>
            ) : (
              <progress className="parcelProgressBar" value={img.progress || 0} max="100"></progress>
            )}
          </div>
        ))}
        {(!maxImagesReached && !isUploading) && (
          <label 
            htmlFor="parcelImageUpload" 
            className="parcelAddImageLabel"
            style={{ margin: uploadImages.length === 0 ? '0px' : '10px 0 0 15px' }}
          >
            <img src={plusIcon} alt="Add" className="parcelAddImageIcon" />
          </label>
        )}
        {isUploading && (
          <div className="imageUploadLoadingDiv">
            <img src={loadIcon} className="marketLoadingIcon" alt="Loading icon" />
          </div>
        )}
      </div>
      <input
        type="file"
        id="parcelImageUpload"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImageUpload}
        multiple
      />
      {error && <div className="parcelUploadError">{error}</div>}
    </div>
  );
};

export default ParcelUploadComponent;
