import React from 'react';
import { motion } from 'framer-motion';
import { doc, updateDoc } from '@firebase/firestore';
import { db } from '../firebase';
import '../scout/InstructionsPopup.css'; // Reuse the same CSS for consistent styling

const VendorExplainer = ({ userID, onClose, onRemind }) => {
  // Handler for the "Understood" button
  const handleClose = async () => {
    if (userID) {
      try {
        const userDocRef = doc(db, "users", userID);
        await updateDoc(userDocRef, {
          showVendorInstructionPopup: false,
        });
      } catch (error) {
        console.error("Error updating user document: ", error);
      }
    }
    if (onClose) onClose();
  };

  // Handler for the "Remind Me Again" button
  const handleRemind = async () => {
    if (userID) {
      try {
        const userDocRef = doc(db, "users", userID);
        await updateDoc(userDocRef, {
          showVendorInstructionPopup: true,
        });
      } catch (error) {
        console.error("Error updating user document: ", error);
      }
    }
    if (onRemind) onRemind();
  };

  // Framer Motion variants for animation
  const variants = {
    hidden: { opacity: 0, y: "100vh" }, // Start offscreen at the bottom
    visible: { opacity: 1, y: 0 },     // Animate to centre
    exit: { opacity: 0, y: "100vh" },  // Animate back down
  };

  return (
    <div className="instructionPopupBackground">
      <motion.div
        className="instructionsPopup"
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <div className="instructionsContentHeader">
          getting started
        </div>
        <div className="instructionsContent">
          <div className="instructionsContentItem">
            To upload products, click the <strong>“add product”</strong> button at the top left corner.
          </div>
          <div className="instructionsContentItem">
            To edit your store’s website, click the <strong>frame icon</strong> in the bottom right corner or the <strong>asset icon</strong> in the bottom left corner to upload images.
          </div>
          <div className="instructionsContentItem">
            Feel free to play around – click different things to explore and see how the website creator works.
          </div>
          <div className="instructionsContentItem">
            Clicking the <strong>top right icon</strong> gives you a link you can share so people can see your website.
          </div>
          <div className="instructionsButtons">
            <div
              className="remindInstructionPopupBtn"
              onClick={handleRemind}
            >
              remind me again
            </div>
            <div
              className="closeInstructionPopupBtn"
              onClick={handleClose}
            >
              understood
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default VendorExplainer;
