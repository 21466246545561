import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';        // Adjust path if needed
import plusIcon from '../../icons/plusIcon.png';
import deleteIcon from '../../icons/deleteIcon.png';
import './SocialMediaManager.css'

const socialPlatforms = [
  'instagram',
  'facebook',
  'tiktok',
  'linkedin',
  'twitter',
  'snapchat',
  'youtube',
  'pinterest'
];

function SocialMediaManager({ storeID }) {
  const [socialLinks, setSocialLinks] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Fetch store's social media links from Firestore
  useEffect(() => {
    if (!storeID) return; // Wait for storeID to be known
    const fetchStoreLinks = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, 'stores', storeID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const storeData = docSnap.data();
          // If there's an array called socialMediaLinks, load it
          setSocialLinks(storeData.socialMediaLinks || []);
        }
      } catch (err) {
        console.error(err);
        setError('Failed to load social links');
      } finally {
        setLoading(false);
      }
    };
    fetchStoreLinks();
  }, [storeID]);

  // Add a new social link
  const addLink = async () => {
    const trimmed = inputValue.trim();
    if (!trimmed) return;

    const newLinkObj = { url: trimmed };
    const updatedLinks = [...socialLinks, newLinkObj];

    try {
      const docRef = doc(db, 'stores', storeID);
      await updateDoc(docRef, { socialMediaLinks: updatedLinks });
      setSocialLinks(updatedLinks);
      setInputValue('');
      setError('');
    } catch (err) {
      console.error(err);
      setError('Failed to add link');
    }
  };

  // Remove link from array (by index)
  const removeLink = async (index) => {
    const updatedLinks = socialLinks.filter((_, i) => i !== index);
    try {
      const docRef = doc(db, 'stores', storeID);
      await updateDoc(docRef, { socialMediaLinks: updatedLinks });
      setSocialLinks(updatedLinks);
    } catch (err) {
      console.error(err);
      setError('Failed to remove link');
    }
  };

  return (
    <div className="socialMediaManagerContainer">
      <div className="socialMediaManagerHeader">social media links</div>

      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}

      {/* Input to add new link */}
      {/* Display existing links */}
      <div className="socialMediaManagerLinksList">
        {socialLinks.map((linkObj, index) => {
          const link = linkObj.url;
          const lowerCaseLink = link.toLowerCase();
          const matchedPlatform = socialPlatforms.find((platform) =>
            lowerCaseLink.includes(platform)
          );
          // If we detect the platform, show the platform name; otherwise show the full link
          let displayName = link;
          if (matchedPlatform) {
            displayName =
              matchedPlatform.charAt(0).toUpperCase() +
              matchedPlatform.slice(1);
          }

          return (
            <div key={index} className="socialMediaLinkItem">
              <div
                onClick={() => window.open(link, '_blank')}
                className="socialMediaLinkButton"
              >
                {displayName}
                <span
                  className="deleteTag"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeLink(index);
                  }}
                >
                  <img
                    src={deleteIcon}
                    alt="Delete link"
                    className="deleteTagIcon"
                  />
                </span>
              </div>
            </div>
          );
        })}
      </div>
      
      <div className="socialMediaLinkInputDiv">
        <input 
          type="text"
          className="socialMediaManagerLinkInput"
          placeholder="Paste link, e.g. https://instagram.com/yourprofile"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <img
          onClick={addLink}
          src={plusIcon}
          alt="Add social link"
          className="addSocialMediaLinkIcon"
        />
      </div>

      
    </div>
  );
}

export default SocialMediaManager;
