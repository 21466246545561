import React, { useContext, useEffect, useState } from 'react';
import { VendorFormContext } from '../context/VendorFormContext.jsx';
import StepIndicator from './StepIndicator.jsx';


const VendorFormContent = ({ step, next, prev, renderStep, country }) => {
  const { formData } = useContext(VendorFormContext);
  const [isExactHeight, setIsExactHeight] = useState(false);

  useEffect(() => {
    const checkHeight = () => {
      if (window.innerHeight <= 750) {
        setIsExactHeight(true);
      } else {
        setIsExactHeight(false);
      }
    };

    // Initial check
    checkHeight();

    // Add event listener for window resize
    window.addEventListener('resize', checkHeight);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', checkHeight);
  }, []);

  return (
    <>
      <StepIndicator currentStep={step} />
      {country && country !== 'South Africa' && (
        <div className="vendorFormCountryUploadNote">
          Note that product <strong>purchasing is not available in {country} yet, </strong> 
          but feel free to test the app.
        </div>
      )}
      <div
        className="vendorFormContainer"
        style={
          isExactHeight
            ? { position: 'absolute', top: '60px', overflow: 'scroll' }
            : {}
        }
      >
        {renderStep()}
      </div>
    </>
  );
};

export default VendorFormContent;
