import React, { createContext, useState, useCallback } from 'react';

export const VendorFormContext = createContext();

export const VendorFormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    // Step 1: Basic Business Information
    store: '',
    store_description: '',
    industry: '',
    store_logo: '',
    nonResizedLogoURL: '',
    fileName: '',
    // Phone Number and Physical Address
    phone: '',
    address: '',
    offeringType: '', // 'Services' or 'Product'
    services: '',

    // Step 3: Business Context
    operationalDuration: '',
    businessLocationType: '',
    goalsExpectations: '',
    goalsExpectationsOther: '',
    describeBrand: '',
    keyWords: '',

    // Step 4: Website Aesthetic Preferences
    style: '',
    socialMediaLinks: [],
    sellerDeliveryPreferance: '',
    consentToShowcase: ''

    // Data from previous steps will be included from StorelessUser.jsx
  });

  const updateFormData = useCallback((newData) => {
    setFormData((prev) => ({ ...prev, ...newData }));
  }, [])

  return (
    <VendorFormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </VendorFormContext.Provider>
  );
};
