// BuyerInfoPopup.jsx

import { useState, useEffect, useRef, useMemo } from "react";
import { getAuth } from "firebase/auth";
import { 
  doc, updateDoc, setDoc, getDoc 
} from '@firebase/firestore';
import { auth, db } from '../firebase';
import { motion } from "framer-motion";
import './BuyerInfoPopup.css';

import { shippingInformation } from './ShippingInformation';
import closeIcon from '../icons/deleteIcon.png';

const BuyerInfoPopup = ({ cartData, onDone, onClose, totalSum, totalShippingPrice }) => { 
  const debounceTimer = useRef(null);
  const addressContainerRef = useRef(); 
  const popupRef = useRef(null);
  const buttonRef = useRef(null); // **Ref for Payment Recap Button**
  const recapRef = useRef(null); // **Ref for Payment Recap Container**

  // State variables
  const [buyerAddress, setBuyerAddress] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [email, setEmail] = useState(''); // **New State for Email**
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);
  const [locationError, setLocationError] = useState(null);

  const [paxiStoreCode, setPaxiStoreCode] = useState('');
  const [pudoLockerAddress, setPudoLockerAddress] = useState('');
  const [showPaxiInput, setShowPaxiInput] = useState(false);
  const [showPudoInput, setShowPudoInput] = useState(false);

  const [showRecap, setShowRecap] = useState(false);

  const authInstance = getAuth();
  const MAPBOX_SUGGESTIONS_URL = "https://mapboxgeocoding-a6vcfarowq-uc.a.run.app/get-address-suggestions";
  const MAPBOX_REVERSE_URL = "https://mapboxgeocoding-a6vcfarowq-uc.a.run.app/get-address-from-coordinates";

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is inside the popup, button, or recap
      const isClickInsidePopup = popupRef.current && popupRef.current.contains(event.target);
      const isClickOnButton = buttonRef.current && buttonRef.current.contains(event.target);
      const isClickInsideRecap = recapRef.current && recapRef.current.contains(event.target);

      if (!isClickInsidePopup && !isClickOnButton && !isClickInsideRecap) {
        onClose(); // Invoke onClose when clicking outside all relevant elements
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // Function to fetch address suggestions via Cloud Function
  const fetchAddressSuggestions = (query) => {
    console.log("Fetching address suggestions for:", query);
    // Clear the previous timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    if (query.length < 3) {
      // If query is too short, clear suggestions
      setAddressSuggestions([]);
      setIsSuggestionsVisible(false);
      return;
    }

    // Set a new timer for debouncing
    debounceTimer.current = setTimeout(() => {
      const user = authInstance.currentUser;
      if (!user) {
        // Optionally, handle unauthenticated state
        setAddressSuggestions([]);
        setIsSuggestionsVisible(false);
        return;
      }

      // Retrieve ID Token
      user.getIdToken()
        .then((idToken) => {
          fetch(MAPBOX_SUGGESTIONS_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${idToken}`, // Include ID Token
            },
            body: JSON.stringify({ query }),
          })
            .then(response => {
              if (!response.ok) {
                throw new Error(`Server responded with status ${response.status}`);
              }
              return response.json();
            })
            .then(data => {
              console.log("Received data from suggestions API:", data);
              if (data.suggestions) {
                setAddressSuggestions(data.suggestions);
                setIsSuggestionsVisible(true);
              } else {
                setAddressSuggestions([]);
                setIsSuggestionsVisible(false);
              }
            })
            .catch(error => {
              console.error("Error fetching address suggestions:", error);
              setAddressSuggestions([]);
              setIsSuggestionsVisible(false);
            });
        })
        .catch(error => {
          console.error("Error retrieving ID token:", error);
          setAddressSuggestions([]);
          setIsSuggestionsVisible(false);
        });
    }, 500); // 500ms debounce
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    // Format the selected suggestion
    const formattedPlaceName = suggestion.place_name.replace(/,\s*South Africa$/, '');
    
    // Update the buyerAddress state with the selected address
    setBuyerAddress(formattedPlaceName);
    
    // Hide suggestions after selection
    setAddressSuggestions([]);
    setIsSuggestionsVisible(false);
    
    // Optionally, blur the input to remove focus
    if (addressContainerRef.current) {
      const input = addressContainerRef.current.querySelector('input');
      if (input) {
        input.blur();
      }
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the changed input is the address field
    if (name === 'address') {
      setBuyerAddress(value);
      fetchAddressSuggestions(value);
    } else if (name === 'firstName') {
      setFirstName(value);
    } else if (name === 'surname') {
      setSurname(value);
    } else if (name === 'cellphone') {
      setCellphone(value);
    } else if (name === 'email') { // **Handle Email Input**
      setEmail(value);
    }
    // **Handle Conditional Fields**
    else if (name === 'paxiStoreCode') {
      setPaxiStoreCode(value);
    }
    else if (name === 'pudoLockerAddress') {
      setPudoLockerAddress(value);
    }
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addressContainerRef.current && !addressContainerRef.current.contains(event.target)) {
        setIsSuggestionsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Fetch existing user data if not passed via props
  useEffect(() => {
    const fetchUserData = async () => {
      // Else, fetch from Firebase
      const currentUser = authInstance.currentUser;
      if (currentUser) {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userSnap = await getDoc(userDocRef);
          if (userSnap.exists()) {
            const data = userSnap.data();
            setFirstName(data.firstName || '');
            setSurname(data.surname || '');
            setCellphone(data.cellphone || '');
            setBuyerAddress(data.address || '');
            setEmail(data.email || ''); // **Set Email if Exists in Firebase**
            setPaxiStoreCode(data.paxiStoreCode || '');
            setPudoLockerAddress(data.pudoLockerAddress || '');
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [authInstance]);

  // **Process Unique Courier Methods with Subgroups**
  const uniqueCourierMethods = useMemo(() => {
    const methodMap = {};

    cartData.forEach(product => {
      const method = product.courierMethod;
      const subgroup = product.selected_subgroup;
      if (method) {
        if (!methodMap[method]) {
          methodMap[method] = new Set();
        }
        if (subgroup) {
          methodMap[method].add(subgroup);
        }
      }
    });

    return Object.keys(methodMap).map(method => ({
      courierMethod: method,
      subgroups: Array.from(methodMap[method])
    }));
  }, [cartData]);

  // **Validation Functions**
  const validatePaxiStoreCode = (code) => {
    const regex = /^P\d{4}$/; // Starts with 'P' followed by 4 digits
    return regex.test(code);
  };

  // Compute whether the Submit button should be shown
  const canShowSubmit = useMemo(() => {
    // Check standard required fields
    if (!firstName.trim() || !surname.trim() || !cellphone.trim() || !buyerAddress.trim() || !email.trim()) {
      return false;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim())) {
      return false;
    }

    // Iterate through courier methods to check conditional fields
    for (let methodObj of uniqueCourierMethods) {
      const { courierMethod, subgroups } = methodObj;

      if (courierMethod === 'Paxi') {
        if (!paxiStoreCode.trim() || !validatePaxiStoreCode(paxiStoreCode.trim())) {
          return false;
        }
      }

      if (courierMethod === 'Pudo') {
        const relevantSubgroups = ['Locker-to-Locker', 'Door-to-Locker'];
        const shouldRequireLocker = subgroups.some(sub => relevantSubgroups.includes(sub));
        if (shouldRequireLocker && !pudoLockerAddress.trim()) {
          return false;
        }
      }
    }

    // All checks passed
    return true;
  }, [firstName, surname, cellphone, buyerAddress, email, paxiStoreCode, pudoLockerAddress, uniqueCourierMethods]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus(null); // Reset submit status

    // Basic validation (already handled in canShowSubmit)
    if (!canShowSubmit) {
      setSubmitStatus({ success: false, message: "Please fill in all required fields." });
      return;
    }

    // Additional Validation (email format already checked)

    // **Additional Validation for Conditional Fields**

    for (let methodObj of uniqueCourierMethods) {
      const { courierMethod, subgroups } = methodObj;

      if (courierMethod === 'Paxi') {
        if (!paxiStoreCode.trim()) {
          setSubmitStatus({ success: false, message: "Please enter your Paxi store code." });
          return;
        }
        if (!validatePaxiStoreCode(paxiStoreCode.trim())) {
          setSubmitStatus({ success: false, message: "Paxi store code must start with 'P' followed by 4 digits." });
          return;
        }
      }

      if (courierMethod === 'Pudo') {
        const relevantSubgroups = ['Locker-to-Locker', 'Door-to-Locker'];
        const shouldShowLockerDiv = subgroups.some(sub => relevantSubgroups.includes(sub));
        if (shouldShowLockerDiv) {
          if (!pudoLockerAddress.trim()) {
            setSubmitStatus({ success: false, message: "Please enter your Pudo locker location." });
            return;
          }
        }
      }
    }

    const currentUser = authInstance.currentUser;
    if (!currentUser) {
      setSubmitStatus({ success: false, message: "User not authenticated." });
      return;
    }

    const userDocRef = doc(db, "users", currentUser.uid);

    // **Prepare Data to Save**
    const buyerInfoData  = {
      firstName: firstName.trim(),
      surname: surname.trim(),
      cellphone: cellphone.trim(),
      address: buyerAddress.trim(),
      email: email.trim(), // **Include Email in Data to Save**
      userID: currentUser.uid,
      updatedAt: new Date(),
      // **Include Conditional Fields in Data to Save**
      paxiStoreCode: paxiStoreCode.trim() || null,
      pudoLockerAddress: pudoLockerAddress.trim() || null
    };

    try {
      // Check if user document exists
      const userSnap = await getDoc(userDocRef);
      if (userSnap.exists()) {
        // Update existing document
        await updateDoc(userDocRef, buyerInfoData);
      } else {
        // Create new document
        await setDoc(userDocRef, buyerInfoData);
      }
      setSubmitStatus({ success: true, message: "Your information has been saved successfully." });
    
      // **Invoke the onDone callback to notify the parent component**
      if (onDone) {
        onDone(buyerInfoData);
      }
    } catch (error) {
      console.error("Error saving user data:", error);
      setSubmitStatus({ success: false, message: "An error occurred while saving your information. Please try again." });
    }
  };

  // Handler for "Use Current Address" button
  const handleUseCurrentAddress = () => {
    setIsFetchingLocation(true);
    setLocationError(null);

    if (!navigator.geolocation) {
      setLocationError("Geolocation is not supported by your browser.");
      setIsFetchingLocation(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        const user = authInstance.currentUser;
        if (!user) {
          setLocationError("User not authenticated.");
          setIsFetchingLocation(false);
          return;
        }

        try {
          const idToken = await user.getIdToken();

          const response = await fetch(`${MAPBOX_REVERSE_URL}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${idToken}`, // Include ID Token
            },
            body: JSON.stringify({ latitude, longitude }),
          });

          if (!response.ok) {
            throw new Error(`Server responded with status ${response.status}`);
          }

          const data = await response.json();

          if (data.address) {
            setBuyerAddress(data.address);
            setIsSuggestionsVisible(false); // Hide suggestions if any
          } else {
            setLocationError("Unable to retrieve your address.");
          }
        } catch (error) {
          console.error("Error fetching address from coordinates:", error);
          setLocationError("An error occurred while fetching your address.");
        } finally {
          setIsFetchingLocation(false);
        }
      },
      (error) => {
        console.error("Geolocation error:", error);
        if (error.code === error.PERMISSION_DENIED) {
          setLocationError("Permission denied. Unable to access your location.");
        } else {
          setLocationError("An error occurred while fetching your location.");
        }
        setIsFetchingLocation(false);
      }
    );
  };

  return (
    <>
      {/* Payment Recap Button positioned at the top right */}
      {!showRecap && (
        <button 
          type="button" 
          className="paymentRecapBtn" 
          onClick={() => setShowRecap(true)}
          ref={buttonRef} // **Attach Ref to Payment Recap Button**
          aria-label="Open Payment Recap"
        >
          Payment Recap
        </button>
      )}

      {/* Payment Recap Container */}
      {showRecap && (
        <motion.div 
          className="paymentRecap"
          initial={{ y: -200 }}
          animate={{ y: 0 }}
          exit={{ y: -200 }}
          transition={{ duration: 0.3 }}
          ref={recapRef} // **Attach Ref to Payment Recap Container**
        >
          <div className="paymentRecapHeader">
            <button 
              className="closeRecapBtn" 
              onClick={() => setShowRecap(false)} 
              aria-label="Close Payment Recap"
            >
              <img src={closeIcon} alt="Close Recap" className="closeIcon" />
            </button>
          </div>
          <div className="paymentRecapContent">
            <div className="paymentRecapRow">
              <div>Products:</div><div>R{(totalSum ?? 0).toFixed(2)}</div>
            </div>
            <div className="paymentRecapRow">
              <div>Shipping & Handling:</div><div>R{(totalShippingPrice ?? 0).toFixed(2)}</div>
            </div>
            <hr />
            <div className="paymentRecapTotal">
              <div>Total:</div><div>R{((totalSum ?? 0) + (totalShippingPrice ?? 0)).toFixed(2)}</div>
            </div>
          </div>
        </motion.div>
      )}

      {/* Buyer Info Popup Blur and Content */}
      <div className="buyerInfoPopupBlur">
        <motion.div 
          className='buyerInfoPopupDiv authentication' 
          ref={popupRef}
          animate={{ marginTop: showRecap ? 100 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <form onSubmit={handleSubmit} className="buyerInfoForm" id="buyerInfoForm">
            <input 
              id="buyerInfoFirstNameInput" 
              type="text" 
              placeholder=" First Name" 
              value={firstName}
              onChange={handleChange} 
              name="firstName"
              required 
            />

            <input 
              id="buyerInfoSurnameInput" 
              type="text" 
              placeholder=" Surname" 
              value={surname}
              onChange={handleChange} 
              name="surname"
              required 
            />

            <input 
              id="buyerInfoEmailInput" 
              type="email" 
              placeholder=" Email" 
              value={email}
              onChange={handleChange} 
              name="email"
              required 
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              title="Please enter a valid email address."
            />

            <input 
              id="buyerInfoCellphoneInput" 
              type="tel" 
              placeholder=" Cellphone" 
              value={cellphone}
              onChange={handleChange} 
              name="cellphone"
              required 
              pattern="^\+?[0-9]{10,15}$"
              title="Please enter a valid cellphone number."
            />

            <div className="buyerInfoAddressDiv">
              <div className="addressAutocompleteDiv" ref={addressContainerRef}>
                <input
                  id="buyerInfoAddressInput" 
                  type="text"
                  placeholder=" Physical Address"
                  value={buyerAddress}
                  onChange={handleChange}
                  name="address"
                  required
                  autoComplete="off"
                />
                {isSuggestionsVisible && addressSuggestions.length > 0 && (
                  <ul className="buyerInfoPopupAddressSuggestionsList">
                    {addressSuggestions.map((suggestion) => (
                      <li
                        key={suggestion.id}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestionItem"
                      > 
                        {suggestion.place_name.replace(/,\s*South Africa$/, '')}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <button 
                type="button" 
                className="locateAddressBtn" 
                id="useCurrentAddressBtn" 
                onClick={handleUseCurrentAddress}
                disabled={isFetchingLocation}
                aria-label="Use Current Address"
              >
                {isFetchingLocation ? "Fetching Address..." : "Use Current Address"}
              </button>
            </div>

            <div className="buyerInfoCourierMethods">
              {uniqueCourierMethods.length > 0 ? (
                <div>
                  {uniqueCourierMethods.map((item, index) => {
                    const { courierMethod, subgroups } = item;
                    const relevantPudoSubgroups = ['Locker-to-Locker', 'Door-to-Locker'];
                    const pudoRelevantSubgroups = subgroups.filter(sub => relevantPudoSubgroups.includes(sub));

                    return (
                      <div key={index} className="shippingMethodContainer">
                        <strong>
                          {courierMethod}
                          {courierMethod === 'Pudo' && pudoRelevantSubgroups.length > 0 && (
                            <span> ({pudoRelevantSubgroups.join(', ')})</span>
                          )}
                        </strong>

                        {courierMethod !== 'Pudo' && subgroups.length > 0 && (
                          <div>
                            {subgroups.map((sub, subIndex) => (
                              <div key={subIndex}>{sub}</div>
                            ))}
                          </div>
                        )}

                        {courierMethod === 'Paxi' && (
                          <div className="conditionalDiv paxiDiv">
                            <button 
                              type="button" 
                              className="findStoreButton" 
                              onClick={() => { 
                                window.open('https://www.paxi.co.za/paxi-points', '_blank'); 
                                setShowPaxiInput(true); 
                              }}
                              aria-label="Find or Change Paxi Store"
                            >
                              {paxiStoreCode ? "Change Store" : "Find Store"}
                            </button>

                            {paxiStoreCode && !showPaxiInput ? (
                              <span className="courierLocationExistingValue">Store Code: {paxiStoreCode}</span>
                            ) : (
                              showPaxiInput && (
                                <>
                                  <label htmlFor="paxiStoreCode">Paxi Store Code:</label>
                                  <input 
                                    id="paxiStoreCodeInput" 
                                    type="text" 
                                    placeholder="P1234" 
                                    value={paxiStoreCode}
                                    onChange={handleChange} 
                                    name="paxiStoreCode"
                                    required 
                                    pattern="^P\d{4}$"
                                    title="Store code must start with 'P' followed by 4 digits."
                                  />
                                </>
                              )
                            )}
                          </div>
                        )}

                        {courierMethod === 'Pudo' && pudoRelevantSubgroups.length > 0 && (
                          <div className="conditionalDiv pudoDiv">
                            <label className="pudoLockerAddressHeader" htmlFor="pudoLockerAddress">Pudo Locker Address:</label>
                            <div className="pudoInputContainer">
                              {pudoLockerAddress && !showPudoInput ? (
                                <span className="courierLocationExistingValue">Locker Address: {pudoLockerAddress}</span>
                              ) : (
                                showPudoInput && (
                                  <>
                                    <input 
                                      id="pudoLockerAddressInput" 
                                      type="text" 
                                      placeholder="Enter Locker Location" 
                                      value={pudoLockerAddress}
                                      onChange={handleChange} 
                                      name="pudoLockerAddress"
                                      required 
                                    />
                                  </>
                                )
                              )}
                              <button 
                                type="button" 
                                className="findLockerButton" 
                                onClick={() => { 
                                  window.open('https://www.pudo.co.za/where-to-find-us.php', '_blank'); 
                                  setShowPudoInput(true); 
                                }}
                                aria-label="Find or Change Pudo Locker"
                              >
                                {pudoLockerAddress ? "Change Locker" : "Find Locker"}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>No shipping methods selected.</p>
              )}
            </div>

            <div className="shippingEstimatedTimes">
              {uniqueCourierMethods.length > 0 && uniqueCourierMethods.map((methodObj, index) => {
                const { courierMethod } = methodObj;
                const shippingMethod = shippingInformation.find(method => method.name === courierMethod);

                let estimatedTime = 'N/A';
                if (shippingMethod) {
                  if (shippingMethod.options && shippingMethod.options.length > 0) {
                    estimatedTime = shippingMethod.options[0].estimatedTime;
                  } else if (shippingMethod.subgroups && shippingMethod.subgroups.length > 0) {
                    const firstSubgroup = shippingMethod.subgroups[0];
                    if (firstSubgroup.options && firstSubgroup.options.length > 0) {
                      estimatedTime = firstSubgroup.options[0].estimatedTime;
                    }
                  }
                }

                return (
                  <div key={index} className="shippingEstimatedTime">
                    <strong>{courierMethod} Estimated Time:</strong> {estimatedTime}
                  </div>
                );
              })}
            </div>

            {canShowSubmit && (
              <button 
                id="buyerInfoSubmitBtn" 
                type="submit" 
                className="cartSubmitButton"
              >
                submit
              </button>
            )}

            {submitStatus && (
              <div className={`submitStatus ${submitStatus.success ? 'success' : 'error'}`}>
                {submitStatus.message}
              </div>
            )}

            {locationError && (
              <div className="submitStatus error">
                {locationError}
              </div>
            )}
          </form>
        </motion.div>
      </div>
    </>
  )
}

export default BuyerInfoPopup;
