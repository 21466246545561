const Fonts = [
  { value: 'Poppins', label: 'Poppins', fontFamily: 'Poppins' },
  { value: 'abigateDesgo.ttf', label: 'Abigate Desgo', fontFamily: 'abigateDesgo' },
  { value: 'abingtonBoldItalic.ttf', label: 'Abington', fontFamily: 'abingtonBoldItalic' },
  { value: 'aeternus_nano_thin_static.otf', label: 'Aeternus Nano', fontFamily: 'aeternus_nano_thin_static' },
  { value: 'ActuallyGrotesk-Regular.otf', label: 'Actually Grotesk', fontFamily: 'ActuallyGrotesk-Regular' },
  { value: 'Ageya.ttf', label: 'Ageya', fontFamily: 'Ageya' },
  { value: 'AgfiustorFree.ttf', label: 'Agfiustor', fontFamily: 'AgfiustorFree' },
  { value: 'Aion-Bold.otf', label: 'Aion-Bold', fontFamily: 'Aion-Bold' },
  { value: 'Aoudax-Display.otf', label: 'Aoudax', fontFamily: 'Aoudax-Display' },
  { value: 'ApriAsh_Extended.otf', label: 'ApriAsh Extended', fontFamily: 'ApriAsh_Extended' },
  { value: 'Avalancheno.ttf', label: 'Avalancheno', fontFamily: 'Avalancheno' },
  { value: 'Bagias.ttf', label: 'Bagias', fontFamily: 'Bagias' },
  { value: 'BarnebokBold.ttf', label: 'Barnebok', fontFamily: 'BarnebokBold' },
  { value: 'Baymanat.ttf', label: 'Baymanat', fontFamily: 'Baymanat' },
  { value: 'Behila.ttf', label: 'Behila', fontFamily: 'Behila' },
  { value: 'Berzulis-GAILA-Regular.ttf', label: 'Berzulis GAILA', fontFamily: 'Berzulis-GAILA-Regular' },
  { value: 'bilboinc-webfont.ttf', label: 'Bilboinc', fontFamily: 'bilboinc-webfont' },
  { value: 'BipHop.otf', label: 'BipHop', fontFamily: 'BipHop' },
  { value: 'Black_M.otf', label: 'Black M', fontFamily: 'Black_M' },
  { value: 'Blocus.otf', label: 'Blocus', fontFamily: 'Blocus' },
  { value: 'blup-Regular_final.ttf', label: 'Blup', fontFamily: 'blup-Regular_final' },
  { value: 'Boeticher-Roman.otf', label: 'Boeticher', fontFamily: 'Boeticher-Roman' },
  { value: 'Bogam.ttf', label: 'Bogam', fontFamily: 'Bogam' },
  { value: 'Bolgarus.otf', label: 'Bolgarus', fontFamily: 'Bolgarus' },
  { value: 'Bolinger.ttf', label: 'Bolinger', fontFamily: 'Bolinger' },
  { value: 'Boxer.otf', label: 'Boxer', fontFamily: 'Boxer' },
  { value: 'Broom.otf', label: 'Broom', fontFamily: 'Broom' },
  { value: 'celattinfont.ttf', label: 'Celattin', fontFamily: 'celattinfont' },
  { value: 'Chaumont.otf', label: 'Chaumont', fontFamily: 'Chaumont' },
  { value: 'Circle.ttf', label: 'Circle', fontFamily: 'Circle' },
  { value: 'CitationRegular.ttf', label: 'Citation', fontFamily: 'CitationRegular' },
  { value: 'Collision-Regular.otf', label: 'Collision', fontFamily: 'Collision-Regular' },
  { value: 'Compute.otf', label: 'Compute', fontFamily: 'Compute' },
  { value: 'Coqnegre.otf', label: 'Coqnegre', fontFamily: 'Coqnegre' },
  { value: 'CuteChars.otf', label: 'CuteChars', fontFamily: 'CuteChars' },
  { value: 'DAMN.ttf', label: 'DAMN', fontFamily: 'DAMN' },
  { value: 'Danzantetypeface-Regular.ttf', label: 'Danzante', fontFamily: 'Danzantetypeface-Regular' },
  { value: 'davidcarson-Regular.otf', label: 'David Carson', fontFamily: 'davidcarson-Regular' },
  { value: 'Derojela.ttf', label: 'Derojela', fontFamily: 'Derojela' },
  { value: 'Distrikt.otf', label: 'Distrikt', fontFamily: 'Distrikt' },
  { value: 'DonorType-Regular.otf', label: 'DonorType', fontFamily: 'DonorType-Regular' },
  { value: 'DRUZHOK.otf', label: 'DRUZHOK', fontFamily: 'DRUZHOK' },
  { value: 'DTRandomDisplay-Regular.otf', label: 'DT Random Display', fontFamily: 'DTRandomDisplay-Regular' },
  { value: 'Elektron.otf', label: 'Elektron', fontFamily: 'Elektron' },
  { value: 'EngageRegular.ttf', label: 'Engage', fontFamily: 'EngageRegular' },
  { value: 'eunoia.ttf', label: 'Eunoia', fontFamily: 'eunoia' },
  { value: 'Excelsiorama-Regular.ttf', label: 'Excelsiorama', fontFamily: 'Excelsiorama-Regular' },
  { value: 'Experitypo5-MOxv.ttf', label: 'Experitypo', fontFamily: 'Experitypo5-MOxv' },
  { value: 'fabuliste.otf', label: 'Fabuliste', fontFamily: 'fabuliste' },
  { value: 'Fantome-Regular.otf', label: 'Fantome', fontFamily: 'Fantome-Regular' },
  { value: 'Fashionable.ttf', label: 'Fashionable', fontFamily: 'Fashionable' },
  { value: 'Firenze.otf', label: 'Firenze', fontFamily: 'Firenze' },
  { value: 'Five.otf', label: 'Five', fontFamily: 'Five' },
  { value: 'FleuronMixed.otf', label: 'Fleuron-Mixed', fontFamily: 'FleuronMixed' },
  { value: 'FramesPartOne-Bold.otf', label: 'Frames', fontFamily: 'FramesPartOne-Bold' },
  { value: 'free_jazz.otf', label: 'Jazz', fontFamily: 'free_jazz' },
  { value: 'Galgo.ttf', label: 'Galgo', fontFamily: 'Galgo' },
  { value: 'GAWKER-Regular.otf', label: 'GAWKER', fontFamily: 'GAWKER-Regular' },
  { value: 'Geek.otf', label: 'Geek', fontFamily: 'Geek' },
  { value: 'GENTODREAM.ttf', label: 'GENTODREAM', fontFamily: 'GENTODREAM' },
  { value: 'Geodesic-v1.otf', label: 'Geodesic', fontFamily: 'Geodesic-v1' },
  { value: 'Giga.otf', label: 'Giga', fontFamily: 'Giga' },
  { value: 'Git-Sans.ttf', label: 'Git-Sans', fontFamily: 'Git-Sans' },
  { value: 'Goat.ttf', label: 'Goat', fontFamily: 'Goat' },
  { value: 'GoldenMetaforRegular.ttf', label: 'Golden Metafor', fontFamily: 'GoldenMetaforRegular' },
  { value: 'Gorki.ttf', label: 'Gorki', fontFamily: 'Gorki' },
  { value: 'Gourmandise.ttf', label: 'Gourmandise', fontFamily: 'Gourmandise' },
  { value: 'Grey_Charles.otf', label: 'Grey Charles', fontFamily: 'Grey_Charles' },
  { value: 'Grotesk.otf', label: 'Grotesk', fontFamily: 'Grotesk' },
  { value: 'GTEAPAC.ttf', label: 'GTEAPAC', fontFamily: 'GTEAPAC' },
  { value: 'Gulax-Regular.otf', label: 'Gulax', fontFamily: 'Gulax-Regular' },
  { value: 'H2ODisplay.otf', label: 'H2O', fontFamily: 'H2ODisplay' },
  { value: 'Hallucinda-Variable.ttf', label: 'Hallucinda', fontFamily: 'Hallucinda-Variable' },
  { value: 'HCGleam-Reg.otf', label: 'HCGleam', fontFamily: 'HCGleam-Reg' },
  { value: 'Hemingway-Regular.otf', label: 'Hemingway', fontFamily: 'Hemingway-Regular' },
  { value: 'Hemis.otf', label: 'Hemis', fontFamily: 'Hemis' },
  { value: 'Histeria.ttf', label: 'Histeria', fontFamily: 'Histeria' },
  { value: 'hngl.otf', label: 'Hngl', fontFamily: 'hngl' },
  { value: 'Ikapito.otf', label: 'Ikapito', fontFamily: 'Ikapito' },
  { value: 'Ink.otf', label: 'Ink', fontFamily: 'Ink' },
  { value: 'Inky.otf', label: 'Inky', fontFamily: 'Inky' },
  { value: 'Intervals_Variable.ttf', label: 'Intervals', fontFamily: 'Intervals_Variable' },
  { value: 'Jasper.otf', label: 'Jasper', fontFamily: 'Jasper' },
  { value: 'Jax.otf', label: 'Jax', fontFamily: 'Jax' },
  { value: 'Jester-00-FOOL.otf', label: 'Jester-FOOL', fontFamily: 'Jester-00-FOOL' },
  { value: 'Jester-08-STRENGTH.otf', label: 'Jester-STRENGTH', fontFamily: 'Jester-08-STRENGTH' },
  { value: 'Jimmy.otf', label: 'Jimmy', fontFamily: 'Jimmy' },
  { value: 'Jolene.otf', label: 'Jolene', fontFamily: 'Jolene' },
  { value: 'kaerukaeru-Regular.otf', label: 'Kaerukaeru', fontFamily: 'kaerukaeru-Regular' },
  { value: 'kalmunt.ttf', label: 'Kalmunt', fontFamily: 'kalmunt' },
  { value: 'kandinsky.ttf', label: 'Kandinsky', fontFamily: 'kandinsky' },
  { value: 'Kegisiko.ttf', label: 'Kegisiko', fontFamily: 'Kegisiko' },
  { value: 'KIK-Regular.ttf', label: 'KIK', fontFamily: 'KIK-Regular' },
  { value: 'KUPOLE-Regular.ttf', label: 'KUPOLE', fontFamily: 'KUPOLE-Regular' },
  { value: 'KyivTypeSans-Heavy.otf', label: 'Kyiv Heavy', fontFamily: 'KyivTypeSans-Heavy' },
  { value: 'KyivTypeSans-Thin.otf', label: 'Kyiv Thin', fontFamily: 'KyivTypeSans-Thin' },
  { value: 'KyivTypeTitling-VarGX.ttf', label: 'Kyiv Title', fontFamily: 'KyivTypeTitling-VarGX' },
  { value: 'KYLTYRE-TECHNICAL.otf', label: 'KYLTYRE-TECHNICAL', fontFamily: 'KYLTYRE-TECHNICAL' },
  { value: 'Lment2.otf', label: 'Lment2', fontFamily: 'Lment2' },
  { value: 'Lobular-Regular.otf', label: 'Lobular', fontFamily: 'Lobular-Regular' },
  { value: 'lorean.otf', label: 'Lorean', fontFamily: 'lorean' },
  { value: 'MangoGrotesque-Bold.ttf', label: 'Mango Grotesque Bold', fontFamily: 'MangoGrotesque-Bold' },
  { value: 'MangoGrotesque-Light.ttf', label: 'Mango Grotesque Light', fontFamily: 'MangoGrotesque-Light' },
  { value: 'MangoGrotesque-Regular.ttf', label: 'Mango Grotesque Regular', fontFamily: 'MangoGrotesque-Regular' },
  { value: 'Martin_Fek_Thirtysixdays.otf', label: 'Martin Fek - 36 days', fontFamily: 'Martin_Fek_Thirtysixdays' },
  { value: 'Mash-Ultrabold.otf', label: 'Mash Ultrabold', fontFamily: 'Mash-Ultrabold' },
  { value: 'Mash-VariableVF.ttf', label: 'Mash Regular', fontFamily: 'Mash-VariableVF' },
  { value: 'Megare.otf', label: 'Megare', fontFamily: 'Megare' },
  { value: 'MegastaSignateriaSignature.ttf', label: 'Megasta Signateria', fontFamily: 'MegastaSignateriaSignature' },
  { value: 'Metamorphosis.otf', label: 'Metamorphosis', fontFamily: 'Metamorphosis' },
  { value: 'Metropolis.otf', label: 'Metropolis', fontFamily: 'Metropolis' },
  { value: 'MGGeometricColor-NoColor.otf', label: 'MG-Geometric', fontFamily: 'MGGeometricColor-NoColor' },
  { value: 'MGZebra.otf', label: 'MG-Zebra', fontFamily: 'MGZebra' },
  { value: 'MinosBold.otf', label: 'Minos Bold', fontFamily: 'MinosBold' },
  { value: 'MinosRegular.otf', label: 'Minos Regular', fontFamily: 'MinosRegular' },
  { value: 'MoaiFreebie-Regular2400.otf', label: 'Moai', fontFamily: 'MoaiFreebie-Regular2400' },
  { value: 'MolluscaScript.ttf', label: 'Mollusca', fontFamily: 'MolluscaScript' },
  { value: 'MonkegRegular.ttf', label: 'Monkeg', fontFamily: 'MonkegRegular' },
  { value: 'Montchauve.otf', label: 'Montchauve', fontFamily: 'Montchauve' },
  { value: 'MudClub-FranklintheGoth.otf', label: 'Mud Club-Franklin the Goth', fontFamily: 'MudClub-FranklintheGoth' },
  { value: 'Munsteria.ttf', label: 'Munsteria', fontFamily: 'Munsteria' },
  { value: 'mx-salamander.ttf', label: 'MX-Salamander', fontFamily: 'mx-salamander' },
  { value: 'Neoptera-Regular.ttf', label: 'Neoptera', fontFamily: 'Neoptera-Regular' },
  { value: 'Normant.otf', label: 'Normant', fontFamily: 'Normant' },
  { value: 'nuitBlanche.otf', label: 'Nuit Blanche', fontFamily: 'nuitBlanche' },
  { value: 'NuMonoFree.otf', label: 'NuMono', fontFamily: 'NuMonoFree' },
  { value: 'NW.otf', label: 'NW', fontFamily: 'NW' },
  { value: 'Oceanus_Variable.ttf', label: 'Oceanus', fontFamily: 'Oceanus_Variable' },
  { value: 'outward-block.ttf', label: 'Outward-Block', fontFamily: 'outward-block' },
  { value: 'Pierrafeu.otf', label: 'Pierrafeu', fontFamily: 'Pierrafeu' },
  { value: 'Pilowlava-Regular.otf', label: 'Pilowlava', fontFamily: 'Pilowlava-Regular' },
  { value: 'PsychicFortunes.ttf', label: 'Psychic Fortunes Regular', fontFamily: 'PsychicFortunes' },
  { value: 'Qegor.ttf', label: 'Qegor', fontFamily: 'Qegor' },
  { value: 'Quarantine.otf', label: 'Quarantine', fontFamily: 'Quarantine' },
  { value: 'Radikal.otf', label: 'Radikal', fontFamily: 'Radikal' },
  { value: 'Ramka-Bold.otf', label: 'Ramka Bold', fontFamily: 'Ramka-Bold' },
  { value: 'Ramka.otf', label: 'Ramka Regular', fontFamily: 'Ramka' },
  { value: 'RemboyRegular.ttf', label: 'Remboy', fontFamily: 'RemboyRegular' },
  { value: 'ROLLERALIKA-DemiBold.ttf', label: 'ROLLERALIKA', fontFamily: 'ROLLERALIKA-DemiBold' },
  { value: 'Rotunda.otf', label: 'Rotunda', fontFamily: 'Rotunda' },
  { value: 'Rubik-SeaCamouflage-Regular.ttf', label: 'Rubik-SeaCamouflage', fontFamily: 'Rubik-SeaCamouflage-Regular' },
  { value: 'Semicir.otf', label: 'Semicir', fontFamily: 'Semicir' },
  { value: 'Slang.ttf', label: 'Slang', fontFamily: 'Slang' },
  { value: 'Soulcraft.ttf', label: 'Soulcraft', fontFamily: 'Soulcraft' },
  { value: 'SpaceCowgirl-Bold.otf', label: 'Space Cowgirl', fontFamily: 'SpaceCowgirl-Bold' },
  { value: 'SpicyCaps.otf', label: 'SpicyCaps', fontFamily: 'SpicyCaps' },
  { value: 'Spirt-O_font_beta.ttf', label: 'Spirt-O', fontFamily: 'Spirt-O_font_beta' },
  { value: 'StarBlackInline.ttf', label: 'Star Black Inline', fontFamily: 'StarBlackInline' },
  { value: 'StraratEleganteFont.ttf', label: 'Strarat Elegante', fontFamily: 'StraratEleganteFont' },
  { value: 'StreetSignSans.otf', label: 'Streetsign', fontFamily: 'StreetSignSans' },
  { value: 'Suspicion_Variable.ttf', label: 'Suspicion', fontFamily: 'Suspicion_Variable' },
  { value: 'terminal-grotesque.ttf', label: 'Terminal-Grotesque', fontFamily: 'terminal-grotesque' },
  { value: 'therow.otf', label: 'Therow', fontFamily: 'therow' },
  { value: 'Thunder-BoldHC.ttf', label: 'Thunder', fontFamily: 'Thunder-BoldHC' },
  { value: 'Turismo.otf', label: 'Turismo', fontFamily: 'Turismo' },
  { value: 'Typefesse_Claire-Obscure.otf', label: 'Typefesse-Claire', fontFamily: 'Typefesse_Claire-Obscure' },
  { value: 'Typefesse_Pleine.otf', label: 'Typefesse-Pleine', fontFamily: 'Typefesse_Pleine' },
  { value: 'ultraprint.otf', label: 'Ultraprint', fontFamily: 'ultraprint' },
  { value: 'vasilek.otf', label: 'Vasilek', fontFamily: 'vasilek' },
  { value: 'VTF_Mixo.otf', label: 'VTF Mixo', fontFamily: 'VTF_Mixo' },
  { value: 'wakile.ttf', label: 'Wakile', fontFamily: 'wakile' },
  { value: 'WARNING.otf', label: 'WARNING', fontFamily: 'WARNING' },
  { value: 'WataFreeFont.otf', label: 'WATA', fontFamily: 'WataFreeFont' },
  { value: 'WavefontBetaV001-Regular.otf', label: 'Wave', fontFamily: 'WavefontBetaV001-Regular' },
  { value: 'Yunke-Regular.otf', label: 'Yunke', fontFamily: 'Yunke-Regular' },
  { value: 'ZALTYS-Regular.ttf', label: 'ZALTYS', fontFamily: 'ZALTYS-Regular' },
  { value: 'Zimra-Regular.otf', label: 'Zimra', fontFamily: 'Zimra-Regular' },
  { value: 'Zorz.otf', label: 'Zorz', fontFamily: 'Zorz' },
  { value: 'ZtshagoExtrabold.otf', label: 'Ztshago', fontFamily: 'ZtshagoExtrabold' },
];

export default Fonts;
