// src/admin/components/Payments.jsx
import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import './Payments.css'; // Ensure you have appropriate styles
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [downloading, setDownloading] = useState(false); // Downloading state

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const paymentsCol = collection(db, 'payments');
        const snapshot = await getDocs(paymentsCol);
        const paymentsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPayments(paymentsData);
      } catch (error) {
        console.error('Error fetching payments:', error);
        setError('Failed to fetch payments. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  const downloadExcel = () => {
    if (payments.length === 0) {
      alert('No payments available to download.');
      return;
    }

    setDownloading(true);

    // Prepare data for Excel
    const data = payments.map(payment => ({
      'Payment ID': payment.id || 'N/A',
      'User ID': payment.userID || 'N/A',
      'Store ID': payment.storeID || 'N/A',
      'Store Name': payment.storeName || 'N/A',
      'Total Amount (ZAR)': typeof payment.totalAmount === 'number' ? payment.totalAmount.toFixed(2) : '0.00',
      'Payment Date': payment.paymentDate ? new Date(payment.paymentDate.seconds * 1000).toLocaleString() : 'N/A',
      'Shipping Status': payment.shippingStatus || 'N/A',
      'Product Sent': payment.productSent ? 'Yes' : 'No',
      'Product Received': payment.productReceived ? 'Yes' : 'No',
    }));

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Payments');
    // Generate buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // Create a blob from the buffer
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    // Save the file
    saveAs(dataBlob, 'payments.xlsx');

    setDownloading(false);
    alert('Payments have been successfully downloaded.');
  };

  return (
    <div className="payments-component">
      <h2>Payments</h2>
      <p className="payments-description">
        These are all the payments that have been processed by Taizte so far.
      </p>
      <button
        className="download-button"
        onClick={downloadExcel}
        disabled={downloading}
      >
        {downloading ? 'Downloading...' : 'Download as Excel'}
      </button>
      {loading ? (
        <p>Loading payments...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : payments.length === 0 ? (
        <p>No payments have been made yet.</p>
      ) : (
        <div className="payments-container">
          {/* Table for Desktop */}
          <table className="payments-table">
            <thead>
              <tr>
                <th>Payment ID</th>
                <th>User ID</th>
                <th>Store ID</th>
                <th>Store Name</th>
                <th>Total Amount (ZAR)</th>
                <th>Payment Date</th>
                <th>Shipping Status</th>
                <th>Product Sent</th>
                <th>Product Received</th>
              </tr>
            </thead>
            <tbody>
              {payments.map(payment => (
                <tr key={payment.id}>
                  <td>{payment.id || 'N/A'}</td>
                  <td>{payment.userID || 'N/A'}</td>
                  <td>{payment.storeID || 'N/A'}</td>
                  <td>{payment.storeName || 'N/A'}</td>
                  <td>
                    {typeof payment.totalAmount === 'number'
                      ? `R${payment.totalAmount.toFixed(2)}`
                      : 'R0.00'}
                  </td>
                  <td>
                    {payment.paymentDate
                      ? new Date(payment.paymentDate.seconds * 1000).toLocaleString()
                      : 'N/A'}
                  </td>
                  <td>{payment.shippingStatus || 'N/A'}</td>
                  <td>{payment.productSent ? 'Yes' : 'No'}</td>
                  <td>{payment.productReceived ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Card Layout for Mobile */}
          <div className="payments-cards">
            {payments.map(payment => (
              <div className="payment-card" key={payment.id}>
                <div className="payment-row">
                  <span className="payment-label">Payment ID:</span>
                  <span className="payment-value">{payment.id || 'N/A'}</span>
                </div>
                <div className="payment-row">
                  <span className="payment-label">User ID:</span>
                  <span className="payment-value">{payment.userID || 'N/A'}</span>
                </div>
                <div className="payment-row">
                  <span className="payment-label">Store ID:</span>
                  <span className="payment-value">{payment.storeID || 'N/A'}</span>
                </div>
                <div className="payment-row">
                  <span className="payment-label">Store Name:</span>
                  <span className="payment-value">{payment.storeName || 'N/A'}</span>
                </div>
                <div className="payment-row">
                  <span className="payment-label">Total Amount (ZAR):</span>
                  <span className="payment-value">
                    {typeof payment.totalAmount === 'number'
                      ? `R${payment.totalAmount.toFixed(2)}`
                      : 'R0.00'}
                  </span>
                </div>
                <div className="payment-row">
                  <span className="payment-label">Payment Date:</span>
                  <span className="payment-value">
                    {payment.paymentDate
                      ? new Date(payment.paymentDate.seconds * 1000).toLocaleString()
                      : 'N/A'}
                  </span>
                </div>
                <div className="payment-row">
                  <span className="payment-label">Shipping Status:</span>
                  <span className="payment-value">{payment.shippingStatus || 'N/A'}</span>
                </div>
                <div className="payment-row">
                  <span className="payment-label">Product Sent:</span>
                  <span className="payment-value">{payment.productSent ? 'Yes' : 'No'}</span>
                </div>
                <div className="payment-row">
                  <span className="payment-label">Product Received:</span>
                  <span className="payment-value">{payment.productReceived ? 'Yes' : 'No'}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Payments;
