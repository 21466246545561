// ColorExtractor.js
import ColorThief from 'colorthief';

/**
 * Convert an [R, G, B] array to an rgb(...) string.
 * @param {[number, number, number]} rgbArray
 * @returns {string} in the form 'rgb(r, g, b)'
 */
function rgbArrayToString([r, g, b]) {
  return `rgb(${r}, ${g}, ${b})`;
}

/**
 * Extract an array of dominant colors from an image URL using ColorThief.
 * Returns an array of strings in 'rgb(r, g, b)' format.
 *
 * @param {string} imageUrl - The URL of the image to process
 * @param {number} paletteSize - Number of colors to extract. Defaults to 5.
 * @returns {Promise<string[]>} - An array of color strings
 */
export async function extractColors(imageUrl, paletteSize = 5) {
  return new Promise((resolve, reject) => {
    const colorThief = new ColorThief();
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Important for cross-origin images
    img.src = imageUrl;

    img.onload = () => {
      try {
        const palette = colorThief.getPalette(img, paletteSize);
        const paletteAsStrings = palette.map(rgbArrayToString);
        resolve(paletteAsStrings);
      } catch (error) {
        reject(error);
      }
    };

    img.onerror = (err) => {
      reject(err);
    };
  });
}
