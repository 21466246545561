import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import returnArrowIcon from '../icons/returnArrowIcon.png';
import loadIcon from '../icons/scoutIcon.png';
import './ImageGallery.css';

const loadIconVariants = {
  initial: {
    y: -50,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5 }
  },
  exit: {
    y: -50,
    opacity: 0,
    transition: { duration: 0.5 }
  }
};

const mainImageVariants = {
  hidden: {
    y: 50,
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5 }
  }
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.3
    }
  },
  exit: { opacity: 0, transition: { duration: 0.5 } }
};

const thumbnailVariants = {
  hidden: { y: 50, scale: 0.8, opacity: 0 },
  visible: { 
    y: 0, 
    scale: 1, 
    opacity: 1,
    transition: { duration: 0.5 }
  }
};

const ImageGallery = ({ image, imageThumbnail, closeGallery }) => {
  const hasImageThumbnail = Array.isArray(imageThumbnail) && imageThumbnail.length > 0;
  const hasSecondaryImages = image.length > 1;
  const secondaryImages = image.slice(1);
  const secondaryThumbnails = hasImageThumbnail ? imageThumbnail.slice(1) : [];
  const allImages = [image[0], ...secondaryImages];
  const allThumbnails = hasImageThumbnail ? [imageThumbnail[0], ...secondaryThumbnails] : [];

  const [mainImage, setMainImage] = useState(image[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleThumbnailClick = (index) => {
    setIsLoading(true);
    setMainImage(allImages[index]);
    setCurrentIndex(index);
  };

  const swipeToImage = (direction) => {
    let newIndex = currentIndex + direction;
    if (newIndex < 0) newIndex = allImages.length - 1;
    if (newIndex >= allImages.length) newIndex = 0;
    setIsLoading(true);
    setMainImage(allImages[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handleDragEnd = (event, info) => {
    if (info.offset.x < -100) {
      swipeToImage(1);
    } else if (info.offset.x > 100) {
      swipeToImage(-1);
    }
  };

  return (
    <motion.div
      className="imageGalleryDiv"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="closeScoutGalleryBtn" onClick={closeGallery}>
        <img src={returnArrowIcon} alt="Close" className="imageGalleryReturnIcon" />
      </div>
      
      <motion.div className="imageGalleryImageContainer">
        {/* AnimatePresence for the load icon */}
        <AnimatePresence>
          {isLoading && (
            <motion.div
              className="imageGalleryLoadingDiv"
              key="loading-icon"
              variants={loadIconVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <img src={loadIcon} alt="Loading" className="scoutLoadingIcon" />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Main Image with upward animation on load */}
        <motion.img
          className="imageGalleryMainImage"
          src={mainImage}
          alt="Enlarged"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragEnd={handleDragEnd}
          onLoad={() => setIsLoading(false)}
          variants={mainImageVariants}
          initial="hidden"
          animate={isLoading ? 'hidden' : 'visible'}
        />
      </motion.div>
      
      {hasImageThumbnail && hasSecondaryImages && (
        <motion.div 
          className="imageGalleryThumbnailContainer"
          variants={thumbnailVariants}
        >
          {allThumbnails.map((thumbnail, index) => (
            <img
              key={index}
              src={thumbnail}
              alt={`Thumbnail ${index}`}
              className={`imageGalleryThumbnail ${mainImage === allImages[index] ? 'activeThumbnail' : ''}`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </motion.div>
      )}
    </motion.div>
  );
};

export default ImageGallery;
