// OfflineErrorContext.js
import React, { createContext, useState } from 'react';

// Create the context
export const OfflineErrorContext = createContext();

// Create a provider component
export const OfflineErrorProvider = ({ children }) => {
  const [offlineErrorMessage, setOfflineErrorMessage] = useState('');

  // Function to set an error message
  const setError = (message) => {
    setOfflineErrorMessage(message);
    // Optionally, clear the message after a certain time
    setTimeout(() => setOfflineErrorMessage(''), 5000); // Clears after 5 seconds
  };

  return (
    <OfflineErrorContext.Provider value={{ offlineErrorMessage, setError }}>
      {children}
    </OfflineErrorContext.Provider>
  );
};
