import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import {AuthContextProvider} from './context/AuthContext'
import { BrowserRouter } from 'react-router-dom';
import { OfflineErrorProvider } from './OfflineErrorContext'

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <BrowserRouter>
        <OfflineErrorProvider>
          <App/>
        </OfflineErrorProvider>
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
)