import React from 'react';
import blankImage from '../../images/blankImage.png';
import './Dashboard.css';
import PendingProduct from './PendingProduct';
import PendingProductBundle from './PendingProductBundle';

const StoreProductStatus = ({
  storeName,
  pendingProducts,
  sentProducts,
  showPendingProducts,
  showSentProducts,
  setShowPendingProducts,
  setShowSentProducts,
  handleProductClick,
  handleEditProduct,
  deleteProduct,
  longPressEvent,
  adminOrderData,
  setPendingProducts,
  setSentProducts,
  setAlertMessage,
  setAlertType
}) => {

  // Function to group products into bundles based on their bundledWith property
  const groupBundledProducts = (products) => {
    const visited = new Set();
    const bundles = [];
    const singles = [];

    products.forEach(product => {
      if (visited.has(product.productID)) return;

      // Check if product is part of a bundle
      const bundleArray = product.bundledWith && product.bundledWith.length > 0 ? product.bundledWith : [];
      
      // If there is a bundleWith property, we form a bundle group
      if (bundleArray.length > 0) {
        const bundleGroup = [product.productID, ...bundleArray];
        // Find all products in this bundle
        const bundledItems = products.filter(p => bundleGroup.includes(p.productID));
        // Mark them as visited
        bundledItems.forEach(bProd => visited.add(bProd.productID));
        bundles.push(bundledItems);
      } else {
        // This product is a single order
        visited.add(product.productID);
        singles.push(product);
      }
    });

    return { bundles, singles };
  };

  const { bundles: pendingBundles, singles: pendingSingles } = groupBundledProducts(showPendingProducts ? pendingProducts : []);
  const { bundles: sentBundles, singles: sentSingles } = groupBundledProducts(showSentProducts ? sentProducts : []);

  // Callback when a bundle is successfully sent
  const handleBundleSent = (sentBundleProducts) => {
    // Move these products from pendingProducts to sentProducts
    const sentProductIDs = sentBundleProducts.map(p => p.productID);

    setPendingProducts(prevPending => prevPending.filter(p => !sentProductIDs.includes(p.productID)));
    setSentProducts(prevSent => [...prevSent, ...sentBundleProducts]);
  };

  // Callback when a single product is successfully sent
  const handleSingleProductSent = (sentProduct) => {
    setPendingProducts(prevPending => prevPending.filter(p => p.productID !== sentProduct.productID));
    setSentProducts(prevSent => [...prevSent, sentProduct]);
  };

  return (
    <div className="storeProductStatus">
      <div className="storeProductStatusHeader">orders</div>
      <div className="productStatusOptionsButtonsDiv">
        <div
          className={`productsPendingBtn${showPendingProducts ? 'Active' : ''}`}
          onClick={() => {
            setShowPendingProducts(true);
            setShowSentProducts(false);
          }}
        >
          pending
        </div>
        <div
          className={`productsSentBtn${showSentProducts ? 'Active' : ''}`}
          onClick={() => {
            setShowSentProducts(true);
            setShowPendingProducts(false);
          }}
        >
          sent
        </div>
      </div>

      {/* Pending Section */}
      {showPendingProducts && pendingProducts.length === 0 && (
        <div className="dashboardNoProducts">No products have been bought yet.</div>
      )}

      {showPendingProducts && (
        <>
          {/* Render Bundled Pending Products */}
          {pendingBundles.map((bundleGroup, index) => (
            <PendingProductBundle
              key={`bundle-pending-${index}`}
              bundleProducts={bundleGroup}
              handleProductClick={handleProductClick}
              adminOrderData={adminOrderData}
              setAlertMessage={setAlertMessage}
              setAlertType={setAlertType}
              onBundleSent={handleBundleSent} 
            />
          ))}

          {/* Render Single Pending Products */}
          {pendingSingles.map((pendingProduct) => (
            <PendingProduct 
              key={pendingProduct.productID} 
              product={pendingProduct} 
              handleProductClick={handleProductClick}
              adminOrderData={adminOrderData}
              setAlertMessage={setAlertMessage}
              setAlertType={setAlertType}
              onProductSent={handleSingleProductSent} 
            />
          ))}
        </>
      )}

      {/* Sent Section */}
      {showSentProducts && sentProducts.length === 0 && (
        <div className="dashboardNoProducts">No products have been sent.</div>
      )}

      {showSentProducts && (
        <>
          {/* Render Bundled Sent Products */}
          {sentBundles.map((bundleGroup, index) => (
            <PendingProductBundle
              key={`bundle-sent-${index}`}
              bundleProducts={bundleGroup}
              handleProductClick={handleProductClick}
              adminOrderData={adminOrderData}
              setAlertMessage={setAlertMessage}
              setAlertType={setAlertType}
              isSent // indicate these are sent bundles
            />
          ))}

          {/* Render Single Sent Products using PendingProduct component */}
          {sentSingles.map((sentProduct) => (
            <PendingProduct 
              key={sentProduct.productID} 
              product={sentProduct} 
              handleProductClick={handleProductClick}
              adminOrderData={adminOrderData}
              setAlertMessage={setAlertMessage}
              setAlertType={setAlertType}
              isSent={true} // indicate these are sent products
            />
          ))}
        </>
      )}
    </div>
  );
}

export default StoreProductStatus;
