// FrameDynamics.js
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import './FrameDynamics.css'; // Ensure you have appropriate CSS for FrameDynamics
import { doc, updateDoc, getDoc } from '@firebase/firestore';
import { db } from '../../firebase';

const FrameDynamics = ({
  frameDynamicsRef,
  draggableFrameSelected,
  stretchFrame,
  setStretchFrame,
  selectedObjectID,
  setFrames,
  setStickers,
  setShowZIndexPopup,
  marginTopInput,
  handleMarginTopChange,
  marginBottomInput,
  handleMarginBottomChange,
  frames,
  stickers,
  storeID,
  frameSelected,
  stickerSelected,
  currentFrameStretch
}) => {
  
  const combinedItems = [...frames, ...stickers];
  const zIndexes = combinedItems.map(item => item.zIndex || 0);
  console.log(zIndexes)
  const maxZIndex = Math.max(...zIndexes);
  const minZIndex = Math.min(...zIndexes);

  const selectedItem = combinedItems.find(item =>
    (frameSelected ? item.frameID : item.stickerID) === selectedObjectID
  );

  const isAtFront = selectedItem && selectedItem.zIndex >= maxZIndex;
  const isAtBack = selectedItem && selectedItem.zIndex <= minZIndex;

  // Function to update the stretchFrame property
  const updateStretchFrame = useCallback(async (frameID, stretch) => {
    try {
      const frameRef = doc(db, 'frames', frameID);
      await updateDoc(frameRef, { stretchFrame: stretch });

      const storeRef = doc(db, 'stores', storeID);
      await updateDoc(storeRef, {
        frames: frames.map(frame => 
          frame.frameID === frameID ? { ...frame, stretchFrame: stretch } : frame
        )
      });

      console.log(`stretchFrame set to ${stretch} for frame ID: ${frameID}`);
    } catch (error) {
      console.error("Error updating stretchFrame: ", error);
    }
  }, [frames, storeID]);

  const handleStretch = async (stretch) => {
    setStretchFrame(stretch);
    await updateStretchFrame(selectedObjectID, stretch);
    setFrames(prevFrames =>
      prevFrames.map(frame =>
        frame.frameID === selectedObjectID
          ? { ...frame, stretchFrame: stretch }
          : frame
      )
    );
    setShowZIndexPopup(false);
  };

  // Handler for layer changes
  const handleLayerChange = useCallback(async (action) => {
    try {
      // Determine the item type
      const itemType = frameSelected ? 'frame' : 'sticker';
      const itemId = selectedObjectID;

      // Combine frames and stickers for zIndex management
      const combinedItems = [...frames, ...stickers];

      // Find the selected item
      const selectedItem = combinedItems.find(item =>
        (itemType === 'frame' ? item.frameID : item.stickerID) === itemId
      );

      if (!selectedItem) {
        console.error("Selected item not found");
        return;
      }

      // Initialize zIndex if undefined
      if (selectedItem.zIndex === undefined) {
        selectedItem.zIndex = 0;
        console.log(`zIndex initialized to 0 for ${itemType} with ID: ${itemId}`);
      }

      // Function to find the item with a specific zIndex
      const findItemByZIndex = (zIndex) => combinedItems.find(item => item.zIndex === zIndex);

      let updatedZIndex = selectedItem.zIndex;

      switch(action) {
        case "to the front":
          updatedZIndex = Math.max(...combinedItems.map(item => item.zIndex)) + 1;
          break;
          case "to the back":
            // Set selected item's zIndex to 0
            updatedZIndex = 0;
          
            // Increment zIndex of other items that have zIndex=0
            await incrementZIndices(itemId);
          
            // Update selected item's zIndex in local state
            if (itemType === 'frame') {
              setFrames(prevFrames => prevFrames.map(frame => 
                frame.frameID === itemId ? { ...frame, zIndex: 0 } : frame
              ));
            } else if (itemType === 'sticker') {
              setStickers(prevStickers => prevStickers.map(sticker => 
                sticker.stickerID === itemId ? { ...sticker, zIndex: 0 } : sticker
              ));
            }
          
            // Update Firebase for the selected item
            const selectedItemRefBack = doc(db, itemType === 'frame' ? 'frames' : 'stickers', itemId);
            await updateDoc(selectedItemRefBack, { zIndex: 0 });
          
            console.log(`Sent ${itemType} ID: ${itemId} to back with zIndex 0.`);
            setShowZIndexPopup(false); // Close the popup after updating
            return;
          
        case "forward":
          // Find the next higher zIndex
          const higherItems = combinedItems
            .filter(item => item.zIndex > selectedItem.zIndex)
            .sort((a, b) => a.zIndex - b.zIndex);
          if (higherItems.length > 0) {
            const nextHigherItem = higherItems[0];
            // Swap zIndices
            updatedZIndex = nextHigherItem.zIndex;
            const nextHigherItemId = itemType === 'frame' ? nextHigherItem.frameID : nextHigherItem.stickerID;

            // Update local state
            if (itemType === 'frame') {
              setFrames(prevFrames => prevFrames.map(frame => 
                frame.frameID === itemId ? { ...frame, zIndex: updatedZIndex } : 
                (frame.frameID === nextHigherItemId ? { ...frame, zIndex: selectedItem.zIndex } : frame)
              ));
            } else if (itemType === 'sticker') {
              setStickers(prevStickers => prevStickers.map(sticker => 
                sticker.stickerID === itemId ? { ...sticker, zIndex: updatedZIndex } : 
                (sticker.stickerID === nextHigherItemId ? { ...sticker, zIndex: selectedItem.zIndex } : sticker)
              ));
            }

            // Update Firebase for both items
            const storeRef = doc(db, 'stores', storeID);
            await updateDoc(storeRef, {
              frames: frames.map(frame => 
                frame.frameID === itemId ? { ...frame, zIndex: updatedZIndex } : 
                (frame.frameID === nextHigherItemId ? { ...frame, zIndex: selectedItem.zIndex } : frame)
              ),
              stickers: stickers.map(sticker => 
                sticker.stickerID === itemId ? { ...sticker, zIndex: updatedZIndex } : 
                (sticker.stickerID === nextHigherItemId ? { ...sticker, zIndex: selectedItem.zIndex } : sticker)
              )
            });

            // Update individual documents
            const selectedItemRef = doc(db, itemType === 'frame' ? 'frames' : 'stickers', itemId);
            const nextHigherItemRef = doc(db, itemType === 'frame' ? 'frames' : 'stickers', nextHigherItemId);
            await updateDoc(selectedItemRef, { zIndex: updatedZIndex });
            await updateDoc(nextHigherItemRef, { zIndex: selectedItem.zIndex });

            console.log(`Swapped zIndex of ${itemType} ID: ${itemId} with ${itemType} ID: ${nextHigherItemId}`);
            setShowZIndexPopup(false); // Close the popup after updating
            return;
          }
          console.log("No higher item to move forwards");
          return;
        case "backward":
          // Find the next lower zIndex
          const lowerItems = combinedItems
            .filter(item => item.zIndex < selectedItem.zIndex)
            .sort((a, b) => b.zIndex - a.zIndex);
          if (lowerItems.length > 0) {
            const nextLowerItem = lowerItems[0];
            // Swap zIndices
            updatedZIndex = nextLowerItem.zIndex;
            const nextLowerItemId = itemType === 'frame' ? nextLowerItem.frameID : nextLowerItem.stickerID;

            // Update local state
            if (itemType === 'frame') {
              setFrames(prevFrames => prevFrames.map(frame => 
                frame.frameID === itemId ? { ...frame, zIndex: updatedZIndex } : 
                (frame.frameID === nextLowerItemId ? { ...frame, zIndex: selectedItem.zIndex } : frame)
              ));
            } else if (itemType === 'sticker') {
              setStickers(prevStickers => prevStickers.map(sticker => 
                sticker.stickerID === itemId ? { ...sticker, zIndex: updatedZIndex } : 
                (sticker.stickerID === nextLowerItemId ? { ...sticker, zIndex: selectedItem.zIndex } : sticker)
              ));
            }

            // Update Firebase for both items
            const storeRefBackward = doc(db, 'stores', storeID);
            await updateDoc(storeRefBackward, {
              frames: frames.map(frame => 
                frame.frameID === itemId ? { ...frame, zIndex: updatedZIndex } : 
                (frame.frameID === nextLowerItemId ? { ...frame, zIndex: selectedItem.zIndex } : frame)
              ),
              stickers: stickers.map(sticker => 
                sticker.stickerID === itemId ? { ...sticker, zIndex: updatedZIndex } : 
                (sticker.stickerID === nextLowerItemId ? { ...sticker, zIndex: selectedItem.zIndex } : sticker)
              )
            });

            // Update individual documents
            const selectedItemRefBackward = doc(db, itemType === 'frame' ? 'frames' : 'stickers', itemId);
            const nextLowerItemRef = doc(db, itemType === 'frame' ? 'frames' : 'stickers', nextLowerItemId);
            await updateDoc(selectedItemRefBackward, { zIndex: updatedZIndex });
            await updateDoc(nextLowerItemRef, { zIndex: selectedItem.zIndex });

            console.log(`Swapped zIndex of ${itemType} ID: ${itemId} with ${itemType} ID: ${nextLowerItemId}`);
            setShowZIndexPopup(false); // Close the popup after updating
            return;
          }
          console.log("No lower item to move backwards");
          return;
        default:
          if (action === "to the front" || action === "to the back") {
            // Already handled above
            break;
          }
          console.log('Unknown action');
          return;
      }

      if (action === "to the front" || action === "to the back") {
        // Update local state
        if (itemType === 'frame') {
          setFrames(prevFrames => prevFrames.map(frame => 
            frame.frameID === itemId ? { ...frame, zIndex: updatedZIndex } : frame
          ));
        } else if (itemType === 'sticker') {
          setStickers(prevStickers => prevStickers.map(sticker => 
            sticker.stickerID === itemId ? { ...sticker, zIndex: updatedZIndex } : sticker
          ));
        }

        // Update Firebase
        const storeRef = doc(db, 'stores', storeID);
        const storeDoc = await getDoc(storeRef);
        if (storeDoc.exists()) {
          const storeData = storeDoc.data();
          let updatedFrames = storeData.frames || [];
          let updatedStickers = storeData.stickers || [];

          if (itemType === 'frame') {
            updatedFrames = updatedFrames.map(frame => 
              frame.frameID === itemId ? { ...frame, zIndex: updatedZIndex } : frame
            );
          } else if (itemType === 'sticker') {
            updatedStickers = updatedStickers.map(sticker => 
              sticker.stickerID === itemId ? { ...sticker, zIndex: updatedZIndex } : sticker
            );
          }

          await updateDoc(storeRef, {
            frames: updatedFrames,
            stickers: updatedStickers
          });
        }

        // Update individual frame/sticker document
        const itemRef = doc(db, itemType === 'frame' ? 'frames' : 'stickers', itemId);
        await updateDoc(itemRef, { zIndex: updatedZIndex });

        console.log(`Z-index updated to ${updatedZIndex} for ${itemType} ID: ${itemId}`);
        setShowZIndexPopup(false); // Close the popup after updating
      }

    } catch (error) {
      console.error("Error updating zIndex: ", error);
    }
  }, [frames, stickers, frameSelected, selectedObjectID, storeID, setFrames, setStickers]);
    
  // Add this helper function inside your FrameDynamics component
  const incrementZIndices = async (excludedItemId) => {
    // Find all items with zIndex 0 except the excluded item
    const itemsToIncrement = combinedItems.filter(
      item => (item.zIndex === 0) && 
              ((frameSelected && item.frameID !== excludedItemId) ||
              (!frameSelected && item.stickerID !== excludedItemId))
    );

    // Increment their zIndex by 1
    const updatedItems = itemsToIncrement.map(item => ({
      ...item,
      zIndex: item.zIndex + 1
    }));

    // Update local state
    if (frameSelected) {
      setFrames(prevFrames => prevFrames.map(frame => {
        const updatedItem = updatedItems.find(updated => updated.frameID === frame.frameID);
        return updatedItem ? updatedItem : frame;
      }));
    } else {
      setStickers(prevStickers => prevStickers.map(sticker => {
        const updatedItem = updatedItems.find(updated => updated.stickerID === sticker.stickerID);
        return updatedItem ? updatedItem : sticker;
      }));
    }

    // Update Firebase
    const storeRef = doc(db, 'stores', storeID);
    await updateDoc(storeRef, {
      frames: frames.map(frame => {
        const updatedItem = updatedItems.find(updated => updated.frameID === frame.frameID);
        return updatedItem ? { ...frame, zIndex: updatedItem.zIndex } : frame;
      }),
      stickers: stickers.map(sticker => {
        const updatedItem = updatedItems.find(updated => updated.stickerID === sticker.stickerID);
        return updatedItem ? { ...sticker, zIndex: updatedItem.zIndex } : sticker;
      })
    });

    // Update individual documents
    const updatePromises = updatedItems.map(item => {
      const itemRef = doc(db, frameSelected ? 'frames' : 'stickers', frameSelected ? item.frameID : item.stickerID);
      return updateDoc(itemRef, { zIndex: item.zIndex });
    });

    await Promise.all(updatePromises);

    console.log(`Incremented zIndex for ${updatedItems.length} items.`);
  };

  return (
    <div className="positionPopup" ref={frameDynamicsRef}>
      {(!draggableFrameSelected && !stickerSelected) && (
        <div className="stretchFrameDiv">
        <div className="stretchFrameHeading">stretch frame?</div>
        <div className="stretchFrameBtnDiv">
          <div
            className={`stretchFrameYes ${currentFrameStretch ? 'selected' : ''}`}
            onClick={() => handleStretch(true)}
          >
            yes
          </div>
          <div
            className={`stretchFrameNo ${!currentFrameStretch ? 'selected' : ''}`}
            onClick={() => handleStretch(false)}
          >
            no
          </div>
        </div>
      </div>
      
      )}

      <div className="marginDiv">
        <div className="marginHeading">margin</div>
        <div className="marginTopDiv">
          <div>above frame</div>
          <div className="marginTopInputSection">
            <input
              type="text"
              className="marginTopInput"
              value={marginTopInput}
              onChange={handleMarginTopChange}
              placeholder="0"
            />
            <div className="marginTopPixelText">px</div>
          </div>
        </div>
        <div className="marginBottomDiv">
          <div>below frame</div>
          <div className="marginBottomInputSection">
            <input
              type="text"
              className="marginBottomInput"
              value={marginBottomInput}
              onChange={handleMarginBottomChange}
              placeholder="0"
            />
            <div className="marginBottomPixelText">px</div>
          </div>
        </div>
      </div>

      <div className="layerDiv">
        <div className="layerHeading">layers</div>
        <div
          className={`moveForwards ${isAtFront ? 'disabled' : ''}`}
          id="positionOptionBtn"
          onClick={() => !isAtFront && handleLayerChange('forward')}
        >
          move forwards
        </div>
        <div
          className={`bringToFront ${isAtFront ? 'disabled' : ''}`}
          id="positionOptionBtn"
          onClick={() => !isAtFront && handleLayerChange('to the front')}
        >
          bring to front
        </div>
        <div
          className={`moveBackwards ${isAtBack ? 'disabled' : ''}`}
          id="positionOptionBtn"
          onClick={() => !isAtBack && handleLayerChange('backward')}
        >
          move backwards
        </div>
        <div
          className={`sendToBack ${isAtBack ? 'disabled' : ''}`}
          id="positionOptionBtn"
          onClick={() => !isAtBack && handleLayerChange('to the back')}
        >
          send to back
        </div>
      </div>

    </div>
  );
};

FrameDynamics.propTypes = {
  frameDynamicsRef: PropTypes.object.isRequired,
  draggableFrameSelected: PropTypes.bool.isRequired,
  stretchFrame: PropTypes.bool.isRequired,
  setStretchFrame: PropTypes.func.isRequired,
  selectedObjectID: PropTypes.string.isRequired,
  setFrames: PropTypes.func.isRequired,
  setStickers: PropTypes.func.isRequired,
  setShowZIndexPopup: PropTypes.func.isRequired,
  marginTopInput: PropTypes.string.isRequired,
  handleMarginTopChange: PropTypes.func.isRequired,
  marginBottomInput: PropTypes.string.isRequired,
  handleMarginBottomChange: PropTypes.func.isRequired,
  frames: PropTypes.array.isRequired,
  stickers: PropTypes.array.isRequired,
  storeID: PropTypes.string.isRequired,
  frameSelected: PropTypes.bool.isRequired,
  stickerSelected: PropTypes.bool.isRequired
};

export default FrameDynamics;
