// DeliveryNotifications.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import blankImage from '../images/blankImage.png';
import dropdownIcon from '../icons/popupIcon.png';
import './DeliveryNotifications.css'; // Ensure to create corresponding CSS
import { db } from "../firebase"; // Ensure correct path
import { 
  doc, 
  updateDoc, 
  getDocs, 
  collection, 
  setDoc, 
  serverTimestamp, 
  query, 
  where, 
  getDoc 
} from "@firebase/firestore";

const DeliveryNotifications = ({
  deliveryNotifications,
  deliveryNotificationProducts,
  convertToReadableDate,
  toggleDetails,
  expandedNotifications,
  handleProductClick,
  isBundle,
  userID,
  setAlertMessage,
  setAlertType
}) => {
  const [loadingOrder, setLoadingOrder] = useState(null); // To track loading state for each order
  const [errorOrder, setErrorOrder] = useState(null); // To track errors

  // Function to handle parcel tracking
  const handleTrackParcel = (courierMethod, waybillNumber) => {
    if (!waybillNumber) return;

    // 1) Copy the waybill number
    navigator.clipboard.writeText(waybillNumber);

    // 2) Alert the user
    setAlertMessage('Waybill number has been copied')
    setAlertType('success')

    // 3) Redirect after 2 seconds
    setTimeout(() => {
      let redirectURL;
      switch (courierMethod?.toLowerCase()) {
        case 'paxi':
          redirectURL = 'https://www.paxi.co.za/track-a-parcel';
          break;
        case 'aramex':
          redirectURL = 'https://www.aramex.com/us/en/track/shipments';
          break;
        case 'pudo':
          redirectURL = 'https://portal.thecourierguy.co.za/track';
          break;
        default:
          redirectURL = 'https://www.google.com'; // fallback
      }
      window.location.href = redirectURL;
    }, 2000);
  };

  // Handler for "Order Received" button
  const handleOrderReceived = async (notification) => {
    setLoadingOrder(notification.id);
    setErrorOrder(null);
    try {
      // 1. Update payments: set productReceived to true
      const orderID = notification.orderID; // Adjust based on your data structure
      if (!orderID) {
        throw new Error("Order ID not found in notification.");
      }

      const paymentsRef = collection(db, "payments");
      const paymentsQuerySnapshot = await getDocs(
        query(
          paymentsRef,
          where("orderID", "==", orderID),
          where("userID", "==", userID)
        )
      );

      if (paymentsQuerySnapshot.empty) {
        throw new Error("No payment records found for this order.");
      }

      const paymentUpdates = paymentsQuerySnapshot.docs.map(docSnapshot => {
        return updateDoc(docSnapshot.ref, { productReceived: true });
      });

      await Promise.all(paymentUpdates);

      // 2. Update products: set productReceived to true
      const productIDs = notification.productIDs || []; // Adjust based on your data structure

      if (productIDs.length === 0) {
        throw new Error("No product IDs found in notification.");
      }

      const productUpdates = productIDs.map(productID => {
        const productRef = doc(db, "products", productID);
        return updateDoc(productRef, { productReceived: true });
      });

      await Promise.all(productUpdates);

      // 3. Create notification for the seller
      const notificationPromises = productIDs.map(async (productID) => {
        const productRef = doc(db, "products", productID);
        const productSnap = await getDoc(productRef);
        if (!productSnap.exists()) return;

        const productData = productSnap.data();
        const storeID = productData.storeID;
        const sellerID = productData.ownerID; // Adjust based on your data structure

        if (!storeID || !sellerID) return;

        const sellerNotificationRef = doc(collection(db, 'notifications'));
        await setDoc(sellerNotificationRef, {
          notificationType: 'orderReceived',
          message: `Customer has received your product '${productData.productName}'.`,
          messageDate: new Date().toLocaleString(),
          messageDateNumeric: serverTimestamp(),
          messageID: sellerNotificationRef.id,
          readStatus: 'unread',
          storeName: productData.storeName,
          productName: productData.productName,
          storeID: storeID,
          userID: sellerID
        });
      });

      await Promise.all(notificationPromises);

      // 4. Update the notification document to set orderReceived: true
      const notificationRef = doc(db, "notifications", notification.id);
      await updateDoc(notificationRef, { orderReceived: true });

    } catch (error) {
      console.error("Error handling order received:", error);
      setErrorOrder(`Failed to mark order as received: ${error.message}`);
    } finally {
      setLoadingOrder(null);
    }
  };

  // Separate notifications into current and past
  const currentOrders = deliveryNotifications.filter(notification => !notification.orderReceived);
  const pastOrders = deliveryNotifications.filter(notification => notification.orderReceived);

  return (
    <div className="deliveryNotifications">
      {/* Current Orders Section */}
      <div className="deliveryNotificationsHeader">Current Orders</div>
      {currentOrders.length > 0 ? (
        currentOrders.map(notification => {
          const isBundle = notification.productIDs && notification.productIDs.length > 1;
          const products = deliveryNotificationProducts[notification.id] || [];

          // Retrieve shippingInfo from notification
          const shippingInfo = notification.shippingInfo || [];

          return (
            <div 
              key={notification.id} 
              className="notificationItem" 
              style={{backgroundColor: '#CBC07C', marginBottom: '10px' }}
            >
              <div className="notificationMessage">{notification.message}</div>
              <div className="notificationTime">{convertToReadableDate(notification.messageDateNumeric)}</div>

              <div className="waybillSection" style={{marginTop:'10px'}}>
                <strong>Waybill/Tracking Number:</strong> {notification.trackingNum || 'N/A'}

                {/* Track Parcel Buttons (one for each shipping info entry) */}
                {shippingInfo.map((info, idx) => {
                  // Only show a button if there's a courierMethod
                  if (info.courierMethod) {
                    return (
                      <button
                        key={idx}
                        className="deliveryTrackingBtn"
                        onClick={() => handleTrackParcel(info.courierMethod, notification.trackingNum)}
                      >
                        {`Track ${info.courierMethod} parcel`}
                      </button>
                    );
                  }
                  return null;
                })}
              </div>
              
              {notification.parcelImages && notification.parcelImages.length > 0 && (
                <div className="parcelImagesSection" style={{marginTop:'10px'}}>
                  <strong>Parcel Images:</strong>
                  <div className="parcelImages" style={{display:'flex', gap:'10px', marginTop:'5px'}}>
                    {notification.parcelImages.map((url, idx) => (
                      <img 
                        key={idx} 
                        src={url} 
                        alt={`Parcel Image ${idx+1}`} 
                        className="deliveryParcelImage" 
                      />
                    ))}
                  </div>
                </div>
              )}

              {/* Shipping Info from shippingInfo array */}
              {shippingInfo.length > 0 ? (
                <div className="deliveryNotificationsShippingInfo" style={{marginTop:'10px'}}>
                  <div className="shippingInfoContainerHeader">
                    <strong>Shipping Information:</strong>
                  </div>
                  {/* For bundles, show all shipping entries. For single product, just one entry */}
                  {shippingInfo.map((info, idx) => (
                    <div key={idx} style={{marginBottom:'10px'}}>
                      <div className="shippingInfoField"><strong>Courier Method:</strong> {info.courierMethod || 'N/A'}</div>
                      <div className="shippingInfoField"><strong>Shipping Price:</strong> {info.shippingPrice ? info.shippingPrice : 'R0'}</div>
                      <div className="shippingInfoField"><strong>Shipping Name:</strong> {info.shippingName || 'N/A'}</div>
                      <div className="shippingInfoField"><strong>Shipping Subgroup:</strong> {info.shippingSubgroup || 'N/A'}</div>
                      {info.paxiStoreCode && (
                        <div className="shippingInfoField"><strong>Paxi Store Code:</strong> {info.paxiStoreCode || 'N/A'}</div>
                      )}
                      {info.pudoLockerAddress && (
                        <div className="shippingInfoField"><strong>Pudo Locker Location:</strong> {info.pudoLockerAddress || 'N/A'}</div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="deliveryNotificationsShippingInfo" style={{marginTop:'10px'}}>
                  <strong>Shipping Information:</strong> Not Available
                </div>
              )}

              {/* Product or Bundle Info */}
              <div className="productInfoSection" style={{marginTop:'10px'}}>
                {/* Dropdown arrow to toggle product details */}
                <div 
                  style={{
                    display:'flex', 
                    alignItems:'center', 
                    justifyContent: 'space-between',
                    cursor:'pointer',
                    width: '100%'
                  }} 
                  onClick={() => toggleDetails(notification.id)}
                >
                  {isBundle ? (
                    <strong>Bundle of {products.length} Items</strong>
                  ) : (
                    <strong>Product: {notification.productName}</strong>
                  )}
                  <img 
                    src={dropdownIcon} 
                    alt="Toggle Details" 
                    style={{
                      width:'12px', 
                      height:'7.5px', 
                      marginLeft:'10px', 
                      transform: expandedNotifications[notification.id] ? 'rotate(0deg)' : 'rotate(180deg)',
                      transition: 'transform 0.3s'
                    }}
                  />
                </div>

                <AnimatePresence>
                  {expandedNotifications[notification.id] && (
                    <motion.div
                      initial={{height:0, opacity:0}}
                      animate={{height:'auto', opacity:1}}
                      exit={{height:0, opacity:0}}
                      transition={{duration:0.3}}
                      style={{marginTop:'10px'}}
                    >
                      <div className="productsDetailsGrid" style={{display:'flex', gap:'10px', flexWrap:'wrap'}}>
                        {products.length > 0 ? (
                          products.map((prod, idx) => (
                            <div 
                              key={idx} 
                              className="deliveryProductDetailItem" 
                              onClick={() => handleProductClick(prod)}
                            >
                              <img 
                                src={prod.imageThumbnails?.[0] || blankImage}
                                alt={prod.productName || 'Untitled'}
                                style={{width:'100px', height:'100px', objectFit:'cover', borderRadius:'5px'}}
                              />
                              <div className="bundledProductInfo" style={{marginTop:'5px', fontSize:'0.9em'}}>
                                <p className="storesProductName" style={{fontWeight:'bold'}}>{prod.productName}</p>
                                <p className="storesProductPrice">R{parseFloat(prod.listed_price || 0).toFixed(2)}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>No product information available.</div>
                        )}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Add the "Order Received" Button */}
              {!notification.productReceived && (
                <button
                  onClick={() => handleOrderReceived(notification)}
                  disabled={loadingOrder === notification.id}
                  className="orderReceivedBtn"
                >
                  {loadingOrder === notification.id ? 'Processing...' : "I've recived my order"}
                </button>
              )}

              {/* Display error message if any */}
              {errorOrder && (
                <div style={{color: 'red', marginTop: '5px'}}>
                  {errorOrder}
                </div>
              )}

            </div>
          )
        })
      ) : (
        <div style={{marginTop: '10px'}}>No current orders.</div>
      )}

      {/* Past Orders Section */}
      <div className="deliveryNotificationsHeader" style={{marginTop: '20px'}}>Past Orders</div>
      {pastOrders.length > 0 ? (
        pastOrders.map(notification => {
          const isBundle = notification.productIDs && notification.productIDs.length > 1;
          const products = deliveryNotificationProducts[notification.id] || [];

          // Retrieve shippingInfo from notification
          const shippingInfo = notification.shippingInfo || [];

          return (
            <div 
              key={notification.id} 
              className="notificationItem pastOrder" 
              style={{backgroundColor: '#E0E0E0', marginBottom: '10px', padding: '10px'}}
            >
              <div className="notificationMessage">{notification.message}</div>
              <div className="notificationTime">{convertToReadableDate(notification.messageDateNumeric)}</div>

              <div className="waybillSection" style={{marginTop:'10px'}}>
                <strong>Waybill/Tracking Number:</strong> {notification.trackingNum || 'N/A'}

                {/* Track Parcel Buttons (one for each shipping info entry) */}
                {shippingInfo.map((info, idx) => {
                  if (info.courierMethod) {
                    return (
                      <button
                        key={idx}
                        className="deliveryTrackingBtn"
                        onClick={() => handleTrackParcel(info.courierMethod, notification.trackingNum)}
                      >
                        {`Track ${info.courierMethod} parcel`}
                      </button>
                    );
                  }
                  return null;
                })}
              </div>
              
              {notification.parcelImages && notification.parcelImages.length > 0 && (
                <div className="parcelImagesSection" style={{marginTop:'10px'}}>
                  <strong>Parcel Images:</strong>
                  <div className="parcelImages" style={{display:'flex', gap:'10px', marginTop:'5px'}}>
                    {notification.parcelImages.map((url, idx) => (
                      <img 
                        key={idx} 
                        src={url} 
                        alt={`Parcel Image ${idx+1}`} 
                        className="deliveryParcelImage" 
                      />
                    ))}
                  </div>
                </div>
              )}

              {/* Shipping Info from shippingInfo array */}
              {shippingInfo.length > 0 ? (
                <div className="deliveryNotificationsShippingInfo" style={{marginTop:'10px'}}>
                  <div className="shippingInfoContainerHeader">
                    <strong>Shipping Information:</strong>
                  </div>
                  {/* For bundles, show all shipping entries. For single product, just one entry */}
                  {shippingInfo.map((info, idx) => (
                    <div key={idx} style={{marginBottom:'10px'}}>
                      <div className="shippingInfoField"><strong>Courier Method:</strong> {info.courierMethod || 'N/A'}</div>
                      <div className="shippingInfoField"><strong>Shipping Price:</strong> {info.shippingPrice ? info.shippingPrice : 'R0'}</div>
                      <div className="shippingInfoField"><strong>Shipping Name:</strong> {info.shippingName || 'N/A'}</div>
                      <div className="shippingInfoField"><strong>Shipping Subgroup:</strong> {info.shippingSubgroup || 'N/A'}</div>
                      {info.paxiStoreCode && (
                        <div className="shippingInfoField"><strong>Paxi Store Code:</strong> {info.paxiStoreCode || 'N/A'}</div>
                      )}
                      {info.pudoLockerAddress && (
                        <div className="shippingInfoField"><strong>Pudo Locker Location:</strong> {info.pudoLockerAddress || 'N/A'}</div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="deliveryNotificationsShippingInfo" style={{marginTop:'10px'}}>
                  <strong>Shipping Information:</strong> Not Available
                </div>
              )}

              {/* Product or Bundle Info */}
              <div className="productInfoSection" style={{marginTop:'10px'}}>
                {/* Dropdown arrow to toggle product details */}
                <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={() => toggleDetails(notification.id)}>
                  {isBundle ? (
                    <strong>Bundle of {products.length} Items</strong>
                  ) : (
                    <strong>Product: {notification.productName}</strong>
                  )}
                  <img 
                    src={dropdownIcon} 
                    alt="Toggle Details" 
                    style={{
                      width:'20px', height:'20px', marginLeft:'10px',
                      transform: expandedNotifications[notification.id] ? 'rotate(0deg)' : 'rotate(180deg)',
                      transition: 'transform 0.3s'
                    }}
                  />
                </div>

                <AnimatePresence>
                  {expandedNotifications[notification.id] && (
                    <motion.div
                      initial={{height:0, opacity:0}}
                      animate={{height:'auto', opacity:1}}
                      exit={{height:0, opacity:0}}
                      transition={{duration:0.3}}
                      style={{marginTop:'10px'}}
                    >
                      <div className="productsDetailsGrid" style={{display:'flex', gap:'10px', flexWrap:'wrap'}}>
                        {products.length > 0 ? (
                          products.map((prod, idx) => (
                            <div 
                              key={idx} 
                              className="deliveryProductDetailItem" 
                              onClick={() => handleProductClick(prod)}
                            >
                              <img 
                                src={prod.imageThumbnails?.[0] || blankImage}
                                alt={prod.productName || 'Untitled'}
                                style={{width:'100px', height:'100px', objectFit:'cover', borderRadius:'5px'}}
                              />
                              <div className="bundledProductInfo" style={{marginTop:'5px', fontSize:'0.9em'}}>
                                <p className="storesProductName" style={{fontWeight:'bold'}}>{prod.productName}</p>
                                <p className="storesProductPrice">R{parseFloat(prod.listed_price || 0).toFixed(2)}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>No product information available.</div>
                        )}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* No "Order Received" Button in Past Orders */}
            </div>
          )
        })
      ) : (
        <div 
          style={{marginTop: '10px'}}
          className='noPastOrdersDiv'
        >
          No past orders.
        </div>
      )}
    </div>
  );
};

export default DeliveryNotifications;
