// PendingProduct.js
import React, { useState, useEffect } from 'react';
import { doc, collection, runTransaction, query, where, getDocs, updateDoc } from '@firebase/firestore';
import { db } from '../../firebase';
import { motion, AnimatePresence } from 'framer-motion';
import blankImage from '../../images/blankImage.png';
import dropdownIcon from '../../icons/popupIcon.png';
import ParcelUploadComponent from './ParcelUploadComponent';
import './PendingProduct.css';

// Function to generate an orderID
function orderID() { 
  let title = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 7; i++) {
    title += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  title += new Date().getTime();
  return title;
}

const PendingProduct = ({
  product,
  handleProductClick,
  adminOrderData,
  setAlertMessage,
  setAlertType,
  isSent = false
}) => {
  // Merge buyerInfo and shipping info from adminOrderData
  const order = adminOrderData.find((order) =>
    order.products.some((p) => p.productID === product.productID)
  );

  let mergedProduct = product;
  if (order) {
    const orderProduct = order.products.find(p => p.productID === product.productID);
    mergedProduct = {
      ...product,
      buyerInfo: order.buyerInfo,
      selected_shippingName: orderProduct.selected_shippingName,
      selected_shippingPrice: orderProduct.selected_shippingPrice,
      selected_subgroup: orderProduct.selected_subgroup,
    };
  }

  const [isBuyerInfoVisible, setIsBuyerInfoVisible] = useState(false);
  const [trackingNum, setTrackingNum] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Track form validity
  const [isFormValid, setIsFormValid] = useState(false);

  // Cleanup object URLs on unmount
  useEffect(() => {
    return () => {
      uploadedImages.forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [uploadedImages]);

  // Update form validity
  useEffect(() => {
    if (isSent) {
      setIsFormValid(false);
    } else {
      const valid = trackingNum.trim() !== '' && uploadedImages.length > 0 && !isSubmitting;
      setIsFormValid(valid);
    }
  }, [trackingNum, uploadedImages, isSubmitting, isSent]);

  const toggleBuyerInfo = () => {
    setIsBuyerInfoVisible(!isBuyerInfoVisible);
  };

  const handleTrackingNumChange = (e) => {
    setTrackingNum(e.target.value);
  };

  /**
   * Handles the submission of shipping information.
   * Updates orderHistory, adminOrderHistory, products, soldProducts, and payments.
   */
  const handleSubmit = async () => {
    if (!trackingNum.trim()) {
      setAlertMessage('Please enter the waybill/tracking number.');
      setAlertType('error');
      return;
    }

    if (uploadedImages.length === 0) {
      setAlertMessage('Please upload at least one image of the parcel.');
      setAlertType('error');
      return;
    }

    setIsSubmitting(true);

    // Generate a new orderID for this transaction
    const newOrderID = orderID();

    try {
      await runTransaction(db, async (transaction) => {
        const buyerInfo = mergedProduct.buyerInfo;
        const storeID = mergedProduct.storeID || 'UnknownStoreID';
        const storeName = mergedProduct.store || 'Unknown Store';

        // Prepare updated product fields
        const updatedFields = {
          productSent: true,
          trackingNum: trackingNum,
          parcelImages: uploadedImages
        };

        // References
        const orderHistoryRef = doc(db, 'orderHistory', storeID);
        const adminOrderHistoryRef = doc(db, 'adminOrderHistory', storeID);
        const paymentsCollectionRef = collection(db, 'payments');

        // Perform all reads first
        const [orderHistoryDoc, adminOrderHistoryDoc] = await Promise.all([
          transaction.get(orderHistoryRef),
          transaction.get(adminOrderHistoryRef)
        ]);

        // Update orderHistory
        let updatedOrderHistory;
        if (orderHistoryDoc.exists()) {
          const currentData = orderHistoryDoc.data();
          updatedOrderHistory = (currentData.orderHistory || []).map(record => {
            // Only assign a new orderID if this record contains the relevant product
            const updatedProducts = record.products.map(p => {
              if (p.productID === mergedProduct.productID) {
                return {
                  ...p,
                  ...updatedFields
                };
              }
              return p;
            });

            // If the product in question belongs to this record, add an orderID if not present
            if (record.products.some(p => p.productID === mergedProduct.productID)) {
              return {
                ...record,
                orderID: record.orderID || newOrderID,
                products: updatedProducts
              };
            } else {
              return {
                ...record,
                products: updatedProducts
              };
            }
          });
          transaction.update(orderHistoryRef, { orderHistory: updatedOrderHistory });
        }

        // Update adminOrderHistory
        let updatedAdminOrderHistory;
        if (adminOrderHistoryDoc.exists()) {
          const currentAdminData = adminOrderHistoryDoc.data();
          updatedAdminOrderHistory = (currentAdminData.adminOrderHistory || []).map(record => {
            // Only assign a new orderID if this record contains the relevant product
            const updatedProducts = record.products.map(p => {
              if (p.productID === mergedProduct.productID) {
                return {
                  ...p,
                  ...updatedFields
                };
              }
              return p;
            });

            if (record.products.some(p => p.productID === mergedProduct.productID)) {
              return {
                ...record,
                orderID: record.orderID || newOrderID,
                products: updatedProducts
              };
            } else {
              return {
                ...record,
                products: updatedProducts
              };
            }
          });
          transaction.update(adminOrderHistoryRef, { adminOrderHistory: updatedAdminOrderHistory });
        }

        // **Update the Product Document**
        const productRef = doc(db, "products", mergedProduct.productID);
        transaction.update(productRef, {
          productSent: true,
          trackingNum: trackingNum
        });

        // **Update SoldProduct Document**
        const soldProductRef = doc(db, 'soldProducts', mergedProduct.productID);
        transaction.set(soldProductRef, {
          ...mergedProduct,
          ...updatedFields,
          sentDate: new Date()
        });

        // **Update Payments Collection**
        // Query payments where storeID and userID match
        const paymentsQuery = query(
          paymentsCollectionRef,
          where("storeID", "==", storeID),
          where("userID", "==", buyerInfo?.userID || 'UnknownUserID')
        );
        const paymentsSnapshot = await getDocs(paymentsQuery);

        paymentsSnapshot.forEach(paymentDoc => {
          const paymentRef = doc(db, "payments", paymentDoc.id);
          transaction.update(paymentRef, {
            productSent: true,      // Update productSent to true
            sentDate: new Date(),   // Add sentDate
            orderID: newOrderID     // Save the new orderID
          });
        });

        // **Update Notifications**
        const buyerNotificationRef = doc(collection(db, 'notifications'));
        transaction.set(buyerNotificationRef, {
          notificationType: 'delivery',
          message: `Your purchase of '${mergedProduct.productName}' has been shipped. Tracking Number: ${trackingNum}.`,
          messageDate: new Date().toLocaleString(),
          messageDateNumeric: new Date().getTime(),
          messageID: buyerNotificationRef.id,
          readStatus: 'unread',
          storeName: storeName,
          productName: mergedProduct.productName,
          storeID: storeID,
          userID: buyerInfo?.userID || 'UnknownUserID',
          trackingNum: trackingNum,
          parcelImages: uploadedImages,
          productID: mergedProduct.productID,
          shippingInfo: [{
            courierMethod: mergedProduct.courierMethod || 'N/A',
            shippingPrice: mergedProduct.selected_shippingPrice || 'R0',
            shippingName: mergedProduct.selected_shippingName || 'N/A',
            shippingSubgroup: mergedProduct.selected_subgroup || 'N/A'
          }],
          // Save the generated orderID to the notification as well
          orderID: newOrderID
        });
      });

      setAlertMessage('The shipping information has been sent to your customer.');
      setAlertType('success');
      setTrackingNum('');
      setUploadedImages([]);
      setIsBuyerInfoVisible(false);
    } catch (err) {
      console.error('Error sending shipping info:', err);
      setAlertMessage('Failed to send shipping information. Please try again later.');
      setAlertType('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Determine if buyerInfo exists
  const hasBuyerInfo = mergedProduct.buyerInfo && Object.keys(mergedProduct.buyerInfo).length > 0;

  // Determine if any shipping info is available (not 'N/A')
  const hasShippingInfo =
    (mergedProduct.courierMethod && mergedProduct.courierMethod !== 'N/A') ||
    (mergedProduct.selected_shippingPrice && mergedProduct.selected_shippingPrice !== 'N/A') ||
    (mergedProduct.selected_shippingName && mergedProduct.selected_shippingName !== 'N/A') ||
    (mergedProduct.selected_subgroup && mergedProduct.selected_subgroup !== 'N/A') ||
    (mergedProduct.courierMethod === 'Paxi' && mergedProduct.buyerInfo?.paxiStoreCode && mergedProduct.buyerInfo.paxiStoreCode !== 'N/A') ||
    (mergedProduct.courierMethod === 'Pudo' && mergedProduct.buyerInfo?.pudoLockerAddress && mergedProduct.buyerInfo.pudoLockerAddress !== 'N/A');

  // Get tracking images URLs for display if isSent
  const trackingImageURLs = isSent && mergedProduct.parcelImages
    ? mergedProduct.parcelImages.map((imageURL, index) => (
        <img
          key={index}
          src={imageURL}
          alt={`Tracking ${index + 1}`}
          className="trackingImage"
        />
      ))
    : [];

  return (
    <div className="storeOrdersProduct">
      <div className="storeProductSummaryDiv">
        <div className="storesProductsSection1">
          <img
            src={mergedProduct.imageThumbnails?.[0] || blankImage}
            className="storesProductImg"
            onClick={() => handleProductClick(mergedProduct)}
            alt={mergedProduct.productName || 'Untitled'}
          />
          <div className="storesProductInfo">
            <p className="storesProductName">{mergedProduct.productName}</p>
            <p className="storesProductPrice">R{parseFloat(mergedProduct.listed_price).toFixed(2)}</p>
          </div>
        </div>
        {/* Always show the dropdown icon */}
        <img
          src={dropdownIcon}
          alt="Toggle Buyer Info"
          className={`ordersDropdownIcon ${isBuyerInfoVisible ? 'rotate' : ''}`}
          onClick={toggleBuyerInfo}
          style={{ cursor: 'pointer', marginTop: '10px' }}
        />
      </div>
      <AnimatePresence>
        {isBuyerInfoVisible && (
          <motion.div
            className="productBuyerInfo"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {isSent ? (
              // Display mode for sent products
              <>
                {/* Buyer Info */}
                {hasBuyerInfo ? (
                  <div className="buyerInfoFields">
                    <div className="buyerInfoContainerHeader">Buyer Info:</div>
                    <div className="buyerInfoField">
                      <strong>First Name:</strong> {mergedProduct.buyerInfo.firstName}
                    </div>
                    <div className="buyerInfoField">
                      <strong>Surname:</strong> {mergedProduct.buyerInfo.surname}
                    </div>
                    <div className="buyerInfoField">
                      <strong>Cellphone:</strong> {mergedProduct.buyerInfo.cellphone}
                    </div>
                    <div className="buyerInfoField">
                      <strong>Email:</strong> {mergedProduct.buyerInfo.email}
                    </div>
                    <div className="buyerInfoField">
                      <strong>Address:</strong> {mergedProduct.buyerInfo.address}
                      {mergedProduct.courierMethod === 'Paxi' && mergedProduct.buyerInfo?.paxiStoreCode && mergedProduct.buyerInfo.paxiStoreCode !== 'N/A' && (
                        <span> (Use the Paxi Store Code)</span>
                      )}
                      {mergedProduct.courierMethod === 'Pudo' && mergedProduct.buyerInfo?.pudoLockerAddress && mergedProduct.buyerInfo.pudoLockerAddress !== 'N/A' && (
                        <span> (Use the Pudo Locker Location)</span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="noBuyerInfo">
                    We could not find any buyer info.
                  </div>
                )}

                {/* Shipping Info */}
                {hasShippingInfo ? (
                  <div className="shippingInfoContainer">
                    <div className="shippingInfoContainerHeader">Shipping Information:</div>
                    {mergedProduct.courierMethod === 'Paxi' && (
                      <div className="shippingInfoField">
                        <strong>Paxi Store Code:</strong> {mergedProduct.buyerInfo?.paxiStoreCode || 'N/A'}
                      </div>
                    )}

                    {mergedProduct.courierMethod === 'Pudo' && (
                      <div className="shippingInfoField">
                        <strong>Pudo Locker Location:</strong> {mergedProduct.buyerInfo?.pudoLockerAddress || 'N/A'}
                      </div>
                    )}

                    <div className="shippingInfoField">
                      <strong>Courier Method:</strong> {mergedProduct.courierMethod || 'N/A'}
                    </div>
                    <div className="shippingInfoField">
                      <strong>Shipping Price:</strong> {mergedProduct.selected_shippingPrice ? (mergedProduct.selected_shippingPrice) : 'R0'}
                    </div>
                    <div className="shippingInfoField">
                      <strong>Shipping Name:</strong> {mergedProduct.selected_shippingName || 'N/A'}
                    </div>
                    <div className="shippingInfoField">
                      <strong>Shipping Subgroup:</strong> {mergedProduct.selected_subgroup || 'N/A'}
                    </div>
                  </div>
                ) : (
                  <div className="noShippingInfo">
                    We could not find any shipping info.
                  </div>
                )}

                {/* Waybill/Tracking Number */}
                <div className="waybillContainer">
                  <strong>Waybill/Tracking Number:</strong> {mergedProduct.trackingNum || 'N/A'}
                </div>

                {/* Display Tracking Images */}
                {isSent && (
                  <div className="trackingImagesContainer">
                    <strong>Parcel Images:</strong>
                    <div className="trackingImages">
                      {trackingImageURLs.length > 0 ? trackingImageURLs : 'No tracking images available.'}
                    </div>
                  </div>
                )}
              </>
            ) : (
              // Form mode for pending products
              <>
                {/* Buyer Info */}
                {hasBuyerInfo ? (
                  <div className="buyerInfoFields">
                    <div className="buyerInfoContainerHeader">Buyer Info:</div>
                    <div className="buyerInfoField">
                      <strong>First Name:</strong> {mergedProduct.buyerInfo.firstName}
                    </div>
                    <div className="buyerInfoField">
                      <strong>Surname:</strong> {mergedProduct.buyerInfo.surname}
                    </div>
                    <div className="buyerInfoField">
                      <strong>Cellphone:</strong> {mergedProduct.buyerInfo.cellphone}
                    </div>
                    <div className="buyerInfoField">
                      <strong>Email:</strong> {mergedProduct.buyerInfo.email}
                    </div>
                    <div className="buyerInfoField">
                      <strong>Address:</strong> {mergedProduct.buyerInfo.address}
                      {mergedProduct.courierMethod === 'Paxi' && mergedProduct.buyerInfo?.paxiStoreCode && mergedProduct.buyerInfo.paxiStoreCode !== 'N/A' && (
                        <span> (Use the Paxi Store Code)</span>
                      )}
                      {mergedProduct.courierMethod === 'Pudo' && mergedProduct.buyerInfo?.pudoLockerAddress && mergedProduct.buyerInfo.pudoLockerAddress !== 'N/A' && (
                        <span> (Use the Pudo Locker Location)</span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="noBuyerInfo">
                    We could not find any buyer info.
                  </div>
                )}

                {/* Shipping Info */}
                {hasShippingInfo ? (
                  <div className="shippingInfoContainer">
                    <div className="shippingInfoContainerHeader">Shipping Information:</div>
                    {mergedProduct.courierMethod === 'Paxi' && (
                      <div className="shippingInfoField">
                        <strong>Paxi Store Code:</strong> {mergedProduct.buyerInfo?.paxiStoreCode || 'N/A'}
                      </div>
                    )}

                    {mergedProduct.courierMethod === 'Pudo' && (
                      <div className="shippingInfoField">
                        <strong>Pudo Locker Location:</strong> {mergedProduct.buyerInfo?.pudoLockerAddress || 'N/A'}
                      </div>
                    )}

                    <div className="shippingInfoField">
                      <strong>Courier Method:</strong> {mergedProduct.courierMethod || 'N/A'}
                    </div>
                    <div className="shippingInfoField">
                      <strong>Shipping Price:</strong> {mergedProduct.selected_shippingPrice ? (mergedProduct.selected_shippingPrice) : 'N/A'}
                    </div>
                    <div className="shippingInfoField">
                      <strong>Shipping Name:</strong> {mergedProduct.selected_shippingName || 'N/A'}
                    </div>
                  </div>
                ) : (
                  <div className="noShippingInfo">
                    We could not find any shipping info.
                  </div>
                )}

                {/* Tracking Number Input */}
                <div className="trackingNumContainer">
                  <input
                    type="text"
                    placeholder="Waybill/Tracking Number"
                    value={trackingNum}
                    onChange={handleTrackingNumChange}
                    className="trackingNumInput"
                  />
                </div>

                {/* Image Upload */}
                {!isSent && (
                  <div className="parcelImageUpload">
                    <ParcelUploadComponent 
                      setUploadedImages={setUploadedImages}
                      productID={mergedProduct.productID} 
                    />
                  </div>
                )}

                {/* Submit Button */}
                {!isSent && (
                  <div className="submitButtonContainer">
                    <button
                      className="sendInfoButton"
                      onClick={handleSubmit}
                      disabled={!isFormValid}
                    >
                      {isSubmitting ? 'Sending...' : 'Send Info to Customer'}
                    </button>
                  </div>
                )}
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PendingProduct;
